<script>
  import { Tabs, TabList, TabPanel, Tab } from "../Components/UI/Tabs/tabs";
  import {
    chainID,
    userConnected,
    userAddress,
    networkProvider,
    networkSigner,
    connectNode,
    connectWallet,
    userAmount,
    hexProof,
  } from "../Stores/Network";
  import { onDestroy, onMount } from "svelte";
  import { ethers } from "ethers";
  import { addr } from "../Stores/Address";
  import {
    abiClaim,
    abiIGO,
    abiPaymentToken,
    abiLinearVesting,
  } from "../Stores/ABI";
  import { notification } from "../Stores/Notification";
  import ClaimModal from "../Components/UI/Modals/ClaimModal.svelte";
  import ImageModal from "../Components/UI/Modals/ImageModal.svelte";

  // Get the ID of the selected IGO from the URL Params.
  export let params;

  // All IGOCard onChain & OffChain Data Store
  let staticData = {};
  let dynamicData = {};

  // Sub Data that Fetched from the API
  let socialsData = {};
  let overviewData = {};
  let vestingData = {};
  let contractData = {};

  // Fetch overviewDesc & Promote it before Display
  let overviewDesc = "";

  // Static Controls
  let roundTag = "";
  let statusTag = "";
  let progressText = "";
  let progress;

  // Modal Controls
  let modalOpen = false;
  let imageOpen = false;
  let modalType;

  // Fetch User Based Data
  let deservedAllocation;
  let deservedAllocationRaw;
  let paidAmount;
  let maxPublicBuy;
  let userBought;
  let userBoughtAlloc;
  let diffUserAlloc;
  let maxBuyPossible;
  let maxBuyPossibleAlloc;
  let paymentTokenBalance;
  let userApproved;
  let userAllowance;
  let igoEarned;
  let userClaimableTokens;

  // Fetch Non-User Based Data
  let claimedAmounts;
  let totalPaidForClaim;
  let totalDollarsToPay;

  // Actions
  let approveInProgress = false;
  let claimInProgress = false;

  // State
  let round;
  let duration;

  // Counter
  let days;
  let hours;
  let minutes;
  let seconds;

  // Vesting Schedule
  let totalClaimedAmounts;

  let creoProgress = 100;
  let selectedImage;

  let linearVestingContractAddr = "0x0ca4d43fa7a5032A0cEE1F9cBdF717DbB614df05";
  let vestingDuration;
  let percentageDeserved;
  let linearDeserved;
  let vestingStartDate;
  let vestingEndDate;
  let linearClaimedTokens;
  let linearClaimableTokens;

  window.scroll({
    top: 0,
    left: 0,
  });

  // Fetch onChain & offChain Data
  const fetchData = async () => {
    await fetch("https://apiv1.spintop.network/fetchIGOs")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed!");
        }
        return res.json();
      })
      .then((data) => {
        
        const selectedIGO = data.IGOs.filter(
          (x) => x.static.igoURL == params.selectedIGO
        );
        staticData = selectedIGO[0].static;
        dynamicData = selectedIGO[0].dynamic;
        setTimeStates(dynamicData, staticData);
        handleStaticData(staticData);
        document.documentElement.style.setProperty(
          "--igoBanner",
          `url(${staticData.igoBanner}`
        );
        document.documentElement.style.setProperty(
          "--igoBannerMobile",
          `url(${staticData.igoBannerMobile}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle Fetched Data on fetchOnChainData Function
  const handleStaticData = async (staticData) => {
    socialsData = staticData.igoSocials[0];
    overviewData = staticData.igoOverview[0];
    vestingData = staticData.igoVestingSchedule;
    overviewDesc = overviewData.overviewDesc.replaceAll("\n", "<br>");
    contractData =
      staticData.igoContractAddr.substring(0, 4) +
      "..." +
      staticData.igoContractAddr.substring(
        staticData.igoContractAddr.length - 4,
        staticData.igoContractAddr.length
      );
  };

  // fetchData Call per 0.5seconds
  const interval = setInterval(() => {
    fetchData();
  }, 500);
  onDestroy(() => {
    clearInterval(interval);
  });

  // Set Time & States
  async function setTimeStates(dynamicData, staticData) {
    if ($userConnected) {
      const linearVestingContract = new ethers.Contract(
        linearVestingContractAddr,
        abiLinearVesting,
        $networkProvider
      );
      vestingDuration = await linearVestingContract._duration();
      percentageDeserved = await linearVestingContract.percentageDeserved();
      linearDeserved = await linearVestingContract.deserved($userAmount);

      const vestingStarts = await linearVestingContract._startDate();
      vestingStartDate = new Date(parseInt(vestingStarts._hex, 16) * 1000);

      const vestingEnds = vestingStarts.add(vestingDuration);
      vestingEndDate = new Date(parseInt(vestingEnds._hex, 16) * 1000);
    }

    if (dynamicData.isIGOStarted === true) {
      $userConnected ? await fetchUserData(dynamicData, staticData) : "";
    }

    if (
      dynamicData.contributionStartDate !== 0 &&
      dynamicData.contributionEndDate > Date.now() / 1000
    ) {
      duration = dynamicData.contributionEndDate - Date.now() / 1000;
      round = "contribution";
      if (duration < 0) {
        duration = 0;
      }
    } else if (dynamicData.igoState === 0) {
      duration = dynamicData.allocationEndDate - Date.now() / 1000;
      round = "allocation";
    } else if (dynamicData.igoState === 1 && dynamicData.totalUnallocated > 0) {
      duration = dynamicData.publicEndDate - Date.now() / 1000;
      round = "first-come-first-serve";
    } else if (
      dynamicData.igoState === 2 ||
      (dynamicData.igoState === 1 && dynamicData.totalUnallocated === 0)
    ) {
      duration = 0;
      round = "vesting-progress";
      if (dynamicData.claimPercentage < 100 && staticData.igoTitle !== "Ookeenga") {
        duration = 0;
        round = "vesting-progress";
      } else if (staticData.igoTitle === "Creo Engine" || staticData.igoTitle === "Ookeenga") {
        duration = 0;
        round = "completed";
      } else if (
        staticData.igoVestingType === "linear" &&
        dynamicData.claimPercentage === 100 &&
        staticData.igoTitle !== "Trivians"
      ) {
        duration = 0;
        round = "vesting-progress";
      } else if (percentageDeserved / 100 === 100) {
        duration = 0;
        round = "completed";
      } else {
        duration = 0;
        round = "completed";
      }
    } else if (dynamicData.isIGOStarted === false) {
      duration = Math.floor(
        staticData.lastPreContributionTime - Date.now() / 1000
      );
      round = "pre-contribution";
      if (duration < 0) {
        duration = 0;
      }
    }
    days = Math.floor(duration / (60 * 60 * 24));
    hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
    minutes = Math.floor((duration % (60 * 60)) / 60);
    seconds = Math.floor(duration % 60);

    switch (round) {
      case "pre-contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Pre Contribution Round";
        progressText = "Total Raised";
        break;
      case "contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Contribution Round";
        progressText = "Total Raised";
        break;
      case "allocation":
        progress =
          (dynamicData.totalPaidForClaim / dynamicData.totalDollarsToPay) * 100;
        roundTag = "Public Sale";
        statusTag = "Allocation Round";
        progressText = "Total Allocated";
        break;
      case "first-come-first-serve":
        progress =
          (dynamicData.totalPaidForClaim / dynamicData.totalDollarsToPay) * 100;
        roundTag = "Public Sale";
        statusTag = "FCFS Round";
        progressText = "Total Allocated";
        break;
      case "vesting-progress":
        progress = 100;
        roundTag = "";
        statusTag = "Vesting in progress";
        progressText = "Total Raised";
        break;
      case "completed":
        progress = 100;
        roundTag = "";
        statusTag = "Completed";
        progressText = "Total Raised";
        break;
    }
  }

  onMount(async () => {
    await connectNode(); // Connect Node.
    await fetchData(); // fetch onChain & offChain Data from API.
  });

  // Fetch User Based Data
  const fetchUserData = async (dynamicData, staticData) => {
    if ($userConnected && $chainID === staticData.chainID) {
      const linearVestingContract = new ethers.Contract(
        linearVestingContractAddr,
        abiLinearVesting,
        $networkProvider
      );
      linearClaimedTokens = await linearVestingContract.claimedTokens(
        $userAddress
      );
      linearClaimableTokens = await linearVestingContract.deserved($userAmount);

      const igoContract = new ethers.Contract(
        dynamicData.igoAddr,
        abiIGO,
        $networkProvider
      );
      const claimContract = new ethers.Contract(
        dynamicData.claimAddr,
        abiClaim,
        $networkProvider
      );
      const paymentTokenContract = new ethers.Contract(
        dynamicData.paymentTokenAddr,
        abiPaymentToken,
        $networkProvider
      );

      deservedAllocationRaw = await claimContract.deservedAllocation(
        $userAddress
      );

      deservedAllocation = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.deservedAllocation($userAddress)
        )
      );

      paymentTokenBalance = await paymentTokenContract.balanceOf($userAddress);
      userAllowance = await paymentTokenContract.allowance(
        $userAddress,
        dynamicData.claimAddr
      );
      userApproved = ethers.utils.formatUnits(userAllowance) > 1000000000;

      totalDollarsToPay = await claimContract.totalDollars();
      totalPaidForClaim = await claimContract.totalPaid();

      maxPublicBuy = await claimContract.maxPublicBuy($userAddress);
      userBought = await claimContract.paidPublic($userAddress);

      let diffUser = maxPublicBuy.sub(userBought);
      let diff = totalDollarsToPay.sub(totalPaidForClaim);

      maxBuyPossible = diffUser.gt(diff) ? diff : diffUser;
      userBoughtAlloc = await claimContract.paidAmounts($userAddress);
      diffUserAlloc = deservedAllocationRaw.sub(userBoughtAlloc);
      maxBuyPossibleAlloc = diffUserAlloc;

      paidAmount = ethers.utils.formatUnits(
        await claimContract.paidAmounts($userAddress)
      );

      userClaimableTokens = parseFloat(
        ethers.utils.formatEther(
          await claimContract.claimableTokens($userAddress)
        )
      );

      const claimedTokens = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.claimedAmounts($userAddress)
        )
      ).toFixed(2);

      claimedAmounts = parseInt(claimedTokens / dynamicData.tokenPrice);

      const totalClaimedTokens = ethers.utils.formatUnits(
        await claimContract.claimedAmounts($userAddress)
      );
      totalClaimedAmounts = totalClaimedTokens / dynamicData.tokenPrice;

      igoEarned = ethers.utils.formatEther(
        await igoContract.earned($userAddress)
      );
    }
  };

  // Approve the Payment Token Contract
  const approve = async () => {
    if ($userConnected && $chainID === staticData.chainID) {
      const paymentTokenContract = new ethers.Contract(
        addr.igo.paymentToken,
        abiPaymentToken,
        $networkProvider
      );
      const claimWithSigner = paymentTokenContract.connect($networkSigner);
      approveInProgress = true;
      try {
        const approve = await claimWithSigner.approve(
          dynamicData.claimAddr,
          ethers.constants.MaxUint256
        );
        let receipt = await approve.wait();
        receipt.status === 1
          ? notification.set({
              visible: true,
              title: "Success: ",
              text: "Approve succesful.",
              icon: "fa-regular fa-circle-check fa-lg",
            })
          : notification.set({
              visible: true, // simple trigger
              title: "Failed: ",
              text: "Approve failed.",
              icon: "fa-regular fa-hexagon-exclamation fa-lg",
            });
        await fetchUserData();
      } catch (err) {
        if (err.code === 4001) {
          approveInProgress = false;
          notification.set({
            visible: true,
            title: "Cancelled: ",
            text: "Approve cancelled by wallet.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
        }
      }
      approveInProgress = false;
    } else {
      await connectWallet();
    }
  };

  // Claim Unlocked Tokens
  const claimTokens = async () => {
    const claimContract = new ethers.Contract(
      dynamicData.claimAddr,
      abiClaim,
      $networkProvider
    );
    const claimWithSigner = claimContract.connect($networkSigner);
    claimInProgress = true;
    try {
      const claimAll = await claimWithSigner.claimTokens();
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
      claimInProgress = false;
    }
  };

  // Lınear Claim Unlocked Tokens
  const claimLinearTokens = async () => {
    const linearVestingContract = new ethers.Contract(
      linearVestingContractAddr,
      abiLinearVesting,
      $networkProvider
    );
    const claimWithSigner = linearVestingContract.connect($networkSigner);
    claimInProgress = true;
    try {
      const claimAll = await claimWithSigner.claim($userAmount, $hexProof);
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
      claimInProgress = false;
    }
  };

  // Copy Token's Contract Address
  const handleCopy = async () => {
    await navigator.clipboard.writeText(staticData.igoContractAddr);
  };

  // Add Token to Metamask
  const addToMetamask = async () => {
    try {
      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: staticData.igoContractAddr,
            symbol: staticData.igoTokenSymbol,
            decimals: staticData.igoTokenDecimals,
            image: "",
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Manage Close Modal
  const closeModal = (type) => {
    modalType = type;
    modalOpen = false;
  };

  // Manage Open Modal
  const openModal = (type) => {
    modalType = type;
    modalOpen = true;
  };

  // Manage Close Modal
  const closeImageModal = (image) => {
    selectedImage = image;
    imageOpen = false;
  };

  // Manage Open Modal
  const openImageModal = (image) => {
    selectedImage = image;
    imageOpen = true;
  };

  // Overview Tab Video Play
  let videoStart = false;

  const playVideo = () => {
    videoStart = true;
  };
</script>

{#if modalOpen}
  {#if parseInt(maxBuyPossibleAlloc) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={dynamicData.claimAddr}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossibleAlloc) >= parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={dynamicData.claimAddr}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossible) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={dynamicData.claimAddr}
      allowance={maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {/if}
{/if}
{#if imageOpen}
  <ImageModal on:close={closeImageModal} imageURL={selectedImage} />
{/if}
<div class="flex-col main-container">
  <main>
    <div class="w-100">
      {#if round !== "vesting-progress"}
        {#if parseFloat(totalPaidForClaim) !== parseFloat(totalDollarsToPay)}
          <div
            class="count-card flex-col"
            style={seconds < 0 ? "display:none" : ""}
          >
            <div>
              {#if round === "pre-contribution"}
                Contribution round starts in
              {:else if round === "contribution"}
                Contribution round ends in
              {:else if round === "allocation"}
                Allocation round ends in
              {:else if round === "first-come-first-serve"}
                Public sale ends in
              {:else if round === "vesting-progress"}
                Vesting in progress
              {/if}
            </div>
            {#if days ? days : "" || hours ? hours : "" || minutes ? minutes : "" || seconds ? seconds : ""}
              <div class="flex-row times">
                {#if days > 0}
                  <div class="time">
                    {days}
                    <div>Days</div>
                  </div>
                {/if}
                <div class="time">
                  {hours}
                  <div>Hours</div>
                </div>
                <div class="time">
                  {minutes}
                  <div>Minutes</div>
                </div>
                {#if days === 0}
                  <div class="time">
                    {seconds}
                    <div>Seconds</div>
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        {/if}
      {:else if round === "vesting-progress" && staticData.igoVestingType === "linear" && $userConnected}
        <div class="count-card flex-col">
          <div
            class="times flex-col"
            style="padding: 22px 52px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
          >
            <div>
              <div>
                {percentageDeserved / 100}%
              </div>
              <div>
                <small>Vesting Progress</small>
              </div>
            </div>
          </div>
        </div>
      {:else if staticData.igoTitle === "Creo Engine" && $userConnected}
        <div class="count-card flex-col">
          <div
            class="times flex-col"
            style="padding: 22px 52px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
          >
            <div>
              <div>
                {creoProgress}%
              </div>
              <div>
                <small>Vesting Progress</small>
              </div>
            </div>
          </div>
        </div>
      {:else if round === "vesting-progress" && staticData.igoVestingType === "cliff" && staticData.igoTokenSymbol !== "OKG" && $userConnected}
        <div class="count-card flex-col">
          <div
            class="times flex-col"
            style="padding: 22px 52px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
          >
            <div>
              <div>
                {(dynamicData.claimPercentage / 100) *
                  staticData.igoVestingSchedule.length}
                / {staticData.igoVestingSchedule.length}
              </div>
              <div>
                <small>Vesting Progress</small>
              </div>
            </div>
          </div>
        </div>
      {:else if round === "vesting-progress" && staticData.igoVestingType === "cliff" && staticData.igoTokenSymbol === "OKG" && $userConnected}
        <div class="count-card flex-col">
          <div
            class="times flex-col"
            style="padding: 22px 52px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
          >
            <div>
              <div>100 / 100</div>
              <div>
                <small>Refunded</small>
              </div>
            </div>
          </div>
        </div>
      {/if}
      <div class="img-card" />
    </div>
  </main>
  <main class="details flex-col">
    <div>
      <div class="details-card">
        <div>
          <div
            class="flex-row"
            style="justify-content: space-between; align-items: center;"
          >
            <div class="flex-row">
              <div style="font-size: 32px">
                {staticData.igoTitle}
              </div>
              <div class="flex-row">
                <div
                  style="margin: 0 8px;align-self: center;padding: 6px 0 0; width: 24px; height: 24px"
                >
                  <img
                    style="width: 100%; height: 100%;"
                    src={staticData.igoTokenIcon}
                    alt="IGOs"
                  />
                </div>
                <div
                  style="font-size: 14px; color:#B8C5EC;align-self: center;margin-top: 8px;"
                >
                  {staticData.igoTokenSymbol}
                </div>
              </div>
            </div>
            <div style="margin: 10px 0;">
              {#if $userConnected}
                {#if round !== "contribution"}
                  <div
                    class="{round === 'pre-contribution'
                      ? 'contribution-button'
                      : 'allocation-button'} flex-row"
                  >
                    <p style="color: white;">
                      {#if round === "pre-contribution"}
                        Allocation N/A
                      {:else if round === "allocation"}
                        Your Allocation : {parseFloat(igoEarned).toLocaleString(
                          "en",
                          { maximumFractionDigits: 3 }
                        )
                          ? parseFloat(igoEarned).toLocaleString("en", {
                              maximumFractionDigits: 3,
                            })
                          : "-"}
                        / Paid : {parseFloat(paidAmount).toLocaleString("en")} BUSD
                      {:else if round === "first-come-first-serve"}
                        {#if parseFloat(totalDollarsToPay) === parseFloat(totalPaidForClaim)}
                          Total Paid : {parseFloat(paidAmount).toLocaleString(
                            "en"
                          )} BUSD
                        {:else}
                          Allocation : {parseFloat(igoEarned).toLocaleString(
                            "en",
                            { maximumFractionDigits: 3 }
                          )
                            ? parseFloat(igoEarned).toLocaleString("en", {
                                maximumFractionDigits: 3,
                              }) * parseFloat(dynamicData.multiplier)
                            : "-"}
                          / Paid : {parseFloat(paidAmount).toLocaleString("en")}
                          BUSD
                        {/if}
                      {:else if round === "vesting-progress" || round === "completed"}
                        {#if staticData.igoVestingType === "cliff"}
                          Claimable {staticData.igoTokenSymbol} :
                          {parseFloat(userClaimableTokens).toLocaleString("en")}
                          / Claimed : {parseFloat(
                            claimedAmounts
                          ).toLocaleString("en")}
                        {/if}
                        {#if staticData.igoVestingType === "linear" && staticData.igoTokenSymbol === "TRIVIA"}
                          Claimable :
                          {parseInt(linearClaimableTokens).toLocaleString(
                            "en",
                            { maximumFractionDigits: 2 }
                          )}
                          / Claimed : {parseInt(
                            linearClaimedTokens
                          ).toLocaleString("en", { maximumFractionDigits: 2 })}
                          {staticData.igoTokenSymbol}
                        {:else if staticData.igoTokenSymbol === "CREO"}
                          Vesting is managed on Unicrypt
                        {/if}
                      {/if}
                    </p>
                  </div>
                {/if}
              {/if}
              {#if $userConnected}
                {#if round === "contribution"}
                  <div class="flex-row">
                    <div
                      class="outer non-outer"
                      style="height: 44px;position: relative"
                    >
                      <div
                        class="inner"
                        style="position: absolute;background: rgba(99, 11, 241, 0.5); height: 44px; border-radius: 12px; width: {((dynamicData.contributionTime -
                          duration) /
                          dynamicData.contributionTime) *
                          100}%; max-width: 100%;text-align-last: center;"
                      />
                      <p
                        style="line-height:0;font-size: 14px;margin: 22px;position: relative;"
                      >
                        Your allocation : {parseFloat(
                          deservedAllocation
                        ).toLocaleString("en", { maximumFractionDigits: 2 })}
                        BUSD
                      </p>
                    </div>
                  </div>
                {/if}
              {/if}
            </div>
          </div>
          <div class="flex-row igo-detail">
            <div class="flex-row">
              <div
                class="status-bar"
                style={round === "pre-contribution"
                  ? "background: #1CDCC7; color:black"
                  : round === "contribution" ||
                    round === "allocation" ||
                    round === "first-come-first-serve"
                  ? "background: #630BF1; color:white;"
                  : round === "vesting-progress"
                  ? "background: rgba(241, 0, 136, 0.5); color:white;"
                  : round === "completed"
                  ? "background: #B8C5EC; color:black;"
                  : ""}
              >
                {statusTag}
              </div>
              {#if roundTag !== ""}
                <div class="round-bar">
                  {roundTag}
                </div>
              {/if}
            </div>
            <div
              class="flex-row socials"
              style={staticData.igoContractAddr === ""
                ? "margin: 0 260px 0 0;"
                : ""}
            >
              <a href={socialsData.igoTelegram} target="_blank">
                <img src="images/UI/igo-telegram.svg" alt="telegram-icon" />
              </a>
              <a href={socialsData.igoMedium} target="_blank">
                <img src="images/UI/igo-medium.svg" alt="medium-icon" />
              </a>
              <a href={socialsData.igoDiscord} target="_blank">
                <img src="images/UI/igo-discord.svg" alt="discord-icon" />
              </a>
              <a href={socialsData.igoTwitter} target="_blank">
                <img src="images/UI/igo-twitter.svg" alt="twitter-icon" />
              </a>
              <a href={socialsData.igoWebsite} target="_blank">
                <img src="images/UI/igo-website.svg" alt="website-icon" />
              </a>
            </div>
            {#if staticData.igoContractAddr !== ""}
              <div class="bsn-card">
                <div class="bsn-row">
                  <span class="bsn-button"
                    ><img
                      src="images/UI/binance-logo.svg"
                      alt="binance-logo"
                    /></span
                  >
                  <span>BSC</span><span class="wrap-bsn">{contractData}</span>
                  <button class="bsn-button" on:click={handleCopy}
                    ><img
                      src="images/UI/copy-icon.svg"
                      alt="copy-icon"
                    /></button
                  >
                  <button class="bsn-button" on:click={addToMetamask}
                    ><img
                      src="images/UI/metamask-logo.svg"
                      alt="metamask-logo"
                    /></button
                  >
                </div>
              </div>
            {/if}
          </div>
          <div
            class="flex-col"
            style="font-family: Nexa-Regular, serif; font-size: 14px;"
          >
            <div>
              {progressText}
            </div>
            <div class="outer">
              <div class="inner" style="width: {progress}%" />
            </div>
            <div class="flex-row" style="justify-content: space-between">
              <div>
                {parseFloat(progress).toFixed(2).toLocaleString("en")}%
              </div>
              <div>
                {parseInt(
                  (progress / 100) * dynamicData.totalDollarsPublic
                ).toLocaleString("en")}
                / {parseInt(dynamicData.totalDollarsPublic).toLocaleString(
                  "en"
                )} BUSD
              </div>
            </div>
          </div>
          <div
            class="flex-row stats-card"
            style="justify-content: center;margin: 8px 0;"
          >
            <div class="flex-row">
              <div class="stats">
                <p>{parseFloat(staticData.igoPrice).toLocaleString("en")}</p>
                <small>IGO Price</small>
              </div>
              <div class="stats">
                <p>
                  {parseInt(staticData.igoInitialMarketCap).toLocaleString(
                    "en"
                  )}
                </p>
                <small>Initial Market Cap</small>
              </div>
            </div>
            <div class="flex-row">
              <div class="stats">
                <p>{staticData.igoTotalTokenSupply}</p>
                <small>Total Supply</small>
              </div>
              <div class="stats">
                <p>
                  {parseInt(staticData.igoTotalUSDRaise).toLocaleString("en")}
                </p>
                <small>Total BUSD Raise</small>
              </div>
            </div>
            <div class="flex-row">
              <div class="stats-l">
                <p>{staticData.igoTotalTokenRaised}</p>
                <small>Total Token Raise</small>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex-row buttons" style="justify-content: center;">
            {#if round === "allocation" || round === "first-come-first-serve"}
              <div class="flex-row igo-buttons">
                {#if round === "allocation"}
                  <button
                    class="stake-button"
                    style={userApproved && parseInt(maxBuyPossibleAlloc) > 0
                      ? "opacity: 1"
                      : parseInt(maxBuyPossibleAlloc) === 0 && userApproved
                      ? "opacity: 0.4; cursor:default"
                      : ""}
                    on:click={userApproved && parseInt(maxBuyPossibleAlloc) > 0
                      ? openModal("payAllocation")
                      : parseInt(maxBuyPossibleAlloc) === 0
                      ? ""
                      : approve()}
                  >
                    <i
                      class={approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Pay for Your Allocation"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
                {#if round === "first-come-first-serve"}
                  <button
                    class="stake-button"
                    style={userApproved && parseInt(maxBuyPossible) > 0
                      ? "opacity: 1"
                      : parseInt(maxBuyPossible) === 0 && userApproved
                      ? "opacity: 0.4;cursor:default"
                      : ""}
                    on:click={userApproved && parseInt(maxBuyPossible)
                      ? openModal("payPublic")
                      : parseInt(maxBuyPossible) === 0
                      ? ""
                      : approve()}
                  >
                    <i
                      class={approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Pay for Your Allocation"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
              </div>
            {/if}
            {#if round === "pre-contribution" || round === "contribution"}
              <div class="flex-row igo-buttons">
                <a
                  href="https://staking.spintop.network/#/vault"
                  target="_blank"
                  class="stake-button"
                  >Go to Staking <i
                    class="fa-solid fa-arrow-up-right-from-square"
                  /></a
                >
              </div>
            {/if}
            {#if round === "vesting-progress" || round === "completed"}
              <div class="flex-row igo-buttons">
                {#if staticData.igoVestingType === "cliff"}
                  <button
                    class="stake-button"
                    style={claimedAmounts < userClaimableTokens
                      ? "opacity: 1"
                      : "opacity: 0.4;cursor:default"}
                    on:click={$userConnected
                      ? userApproved
                        ? parseFloat(userClaimableTokens) > 0
                          ? claimTokens()
                          : ""
                        : approve()
                      : connectWallet()}
                  >
                    {$userConnected
                      ? userApproved
                        ? "Claim All"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}

                {#if staticData.igoVestingType === "linear"}
                  <button
                    class="stake-button"
                    style={parseInt(
                      linearClaimableTokens - linearClaimedTokens
                    ) > 0
                      ? "opacity: 1"
                      : "opacity: 0.4;cursor:default"}
                    on:click={userApproved &&
                    claimedAmounts < userClaimableTokens
                      ? claimLinearTokens()
                      : ""}
                  >
                    {$userConnected
                      ? userApproved
                        ? "Claim All"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      </div>
      <!--Overview Card Begins-->
      <div class="overview-card">
        <Tabs>
          <div style="padding: 8px">
            <TabList>
              <Tab>Game Overview</Tab>
              <Tab>Vesting Schedule</Tab>
            </TabList>
          </div>
          <div class="igo-details">
            <!--Game Overview Begins-->
            <TabPanel>
              <h1 style="margin: 0">{staticData.igoTitle}</h1>
              <div class="flex-row contents">
                {#if overviewData.overviewVideo === ""}
                  <div class="overview-video">
                    <button
                      style="background-color: transparent; width: 100%"
                      on:click={openImageModal(overviewData.overviewZoom1)}
                    >
                      <img
                        style="border-radius: 12px"
                        src={overviewData.overviewImage1}
                        alt="overview"
                      />
                    </button>
                  </div>
                {/if}
                {#if overviewData.overviewVideo !== ""}
                  <div
                    class="overview-video"
                    style="background-image: url('{staticData.igoTokenSymbol ===
                    'TRIVIA'
                      ? '/images/IGOs/trivians/video_poster.png'
                      : staticData.igoTokenSymbol === 'OKG'
                      ? '/images/IGOs/ookeenga/video_poster.jpg'
                      : staticData.igoBannerCard}')"
                  >
                    {#if videoStart === true}
                      <iframe
                        title="video"
                        allow="autoplay; encrypted-media"
                        style="border-radius: 12px"
                        width="520"
                        height="304"
                        src={overviewData.overviewVideo}
                      />
                    {/if}
                    {#if videoStart === false}
                      <div
                        style="cursor:pointer;"
                        class="video-poster"
                        on:click={playVideo}
                      >
                        <i class="fa-solid fa-play" />
                      </div>
                    {/if}
                  </div>
                {/if}
                <div class="flex-col overview-imgs">
                  <div class="overview-img">
                    <button
                      style="background-color: transparent"
                      on:click={openImageModal(overviewData.overviewZoom2)}
                    >
                      <img src={overviewData.overviewImage2} alt="overview" />
                    </button>
                  </div>
                  <div class="overview-img">
                    <button
                      style="background-color: transparent"
                      on:click={openImageModal(overviewData.overviewZoom3)}
                    >
                      <img src={overviewData.overviewImage3} alt="overview" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <p style="font-family: Nexa-Regular,serif; color: #B8C5EC">
                  {@html overviewDesc}
                </p>
              </div>
              {#if overviewData.overviewButton !== 0}
                <div>
                  <button class="game-link"
                    >Read more on Gamepedia
                    <img
                      style="padding-left: 10px"
                      src="images/UI/gamepedia-link.svg"
                      alt="gamepedia"
                    />
                  </button>
                </div>
              {/if}
            </TabPanel>
            <!--Game Overview Ends-->

            <!--Vesting Schedule Begins-->
            {#if !$userConnected}
              <TabPanel>
                <p style="line-height: 1; text-align: center">
                  Please
                  <button
                    style="color:#1CDCC7; cursor: pointer;background:transparent; padding:0; font-size:16px;"
                    on:click={connectWallet}
                    >connect your wallet
                  </button>
                  to see vesting schedule.
                </p>
              </TabPanel>
            {/if}

            {#if staticData.igoTokenSymbol !== "CREO" || ((round === "vesting-progress" || round === "completed") && $userConnected)}
              <TabPanel>
                {#if !$userConnected}
                  <div
                    style="text-align: center;font-size: 16px;color: #B8C5EC;font-family: 'Nexa-Regular',serif;"
                  >
                    Please connect your wallet to see your vesting schedule
                  </div>
                {/if}
                {#if staticData.igoTokenSymbol === "OKG"}
                  <div class="p-refunded">
                    We've refunded the 2nd, 3rd, 4th, 5th & 6th vestings. If you
                    joined Ookeenga IGO, please check your wallet.
                  </div>
                {/if}
                {#if $userConnected && staticData.igoVestingType === "cliff"}
                  {#if vestingData.length !== 0}
                    <div
                      class="table-header flex-row"
                      style="flex-flow: wrap; justify-content: space-between"
                    >
                      {#if staticData.igoTokenSymbol}
                        <div class="table-w5 details-mobile">No.</div>
                        <div class="table-w10">Allocation</div>
                        <div class="table-w10 details-mobile">Percentage</div>
                        <div class="table-w10">Date</div>
                        <div class="table-w10-claim">Claimed</div>
                        <div class="table-w10">Action</div>
                      {/if}
                    </div>
                  {/if}

                  {#if !claimedAmounts ? claimedAmounts : "Loading"}
                    {#each vestingData as { vestingNo, percentage, date, claimed }, i}
                      <div
                        class="table-body"
                        style="flex-flow: wrap;justify-content: space-between; {i +
                          1 ===
                        vestingData.length
                          ? 'border-bottom-right-radius: 12px; border-bottom-left-radius: 12px;'
                          : ''}"
                      >
                        <div class="table-w5 details-mobile">{vestingNo}</div>

                        <div class="table-w10">
                          {#if staticData.igoTokenSymbol === "OKG"}
                            {paidAmount / dynamicData.tokenPrice
                              ? parseFloat(
                                  ((paidAmount / dynamicData.tokenPrice) *
                                    percentage) /
                                    100
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "-"}
                          {:else}
                            {paidAmount / dynamicData.tokenPrice
                              ? parseInt(
                                  paidAmount / dynamicData.tokenPrice / 8
                                )
                              : "-"}
                          {/if}
                        </div>
                        <div class="table-w10 details-mobile">
                          {percentage + "%"}
                        </div>
                        <div class="table-w10">{date}</div>
                        <div class="table-w10-claim">
                          <i
                            class={parseInt(claimedAmounts) >=
                            parseInt(
                              paidAmount / dynamicData.tokenPrice / 8
                            ).toFixed(2) *
                              (i + 1)
                              ? "fa-regular fa-check"
                              : "fa-regular fa-x"}
                            style={dynamicData.claimPercentage === percentage
                              ? "color: #48b527"
                              : ""}
                          />
                        </div>
                        {#if parseFloat(totalClaimedAmounts).toFixed(2) >= parseFloat(((paidAmount / dynamicData.tokenPrice) * percentage) / 100).toFixed(2) * (i + 1)}
                          <div class="table-w10">
                            <button class="claimed">Claimed</button>
                          </div>
                        {:else if userClaimableTokens > parseInt(paidAmount / dynamicData.tokenPrice / 8).toFixed(2) * (i + 1)}
                          <div class="table-w10">
                            <button class="claim" on:click={claimTokens}
                              >Claim Token
                            </button>
                          </div>
                        {:else}
                          <div class="table-w10">
                            <button class="none">N/A</button>
                          </div>
                        {/if}
                      </div>
                    {/each}
                  {:else}
                    {#each vestingData as { vestingNo, percentage, date, claimed }, i}
                      <div
                        class="table-body"
                        style="flex-flow: wrap;
                                     justify-content: space-between;
                                        {i + 1 === vestingData.length
                          ? 'border-bottom-right-radius: 12px; border-bottom-left-radius: 12px;'
                          : ''}"
                      >
                        <div class="table-w5 details-mobile">{vestingNo}</div>
                        <div class="table-w10">
                          {#if staticData.igoTokenSymbol === "OKG"}
                            {paidAmount / dynamicData.tokenPrice
                              ? parseFloat(
                                  ((paidAmount / dynamicData.tokenPrice) *
                                    percentage) /
                                    100
                                )
                              : "-"}
                          {:else}
                            {paidAmount / dynamicData.tokenPrice
                              ? parseInt(
                                  paidAmount / dynamicData.tokenPrice / 8
                                )
                              : "-"}
                          {/if}
                        </div>
                        <div class="table-w10 details-mobile">
                          {percentage + "%"}
                        </div>
                        <div class="table-w10">{date}</div>
                        <div class="table-w10-claim">-</div>
                        <div class="table-w10">-</div>
                      </div>
                    {/each}
                  {/if}
                {/if}
                {#if $userConnected && staticData.igoVestingType === "linear" && linearDeserved === 0}
                  <div style="text-align: center">
                    Vesting has not started yet. It will be announced on our
                    social platforms.
                  </div>
                {/if}
                {#if $userConnected && staticData.igoVestingType === "linear" && linearDeserved > 0 && staticData.igoTokenSymbol === "TRIVIA"}
                  <div class="flex-row" style="justify-content:center;">
                    {staticData.igoVestingInfo}
                  </div>
                  <div class="flex-row linear-vesting">
                    <div class="vesting-box">
                      <div class="flex-col vesting-box-in">
                        <span
                          >{parseInt(linearClaimedTokens).toLocaleString("en", {
                            maximumFractionDigits: 2,
                          })}
                          {staticData.igoTokenSymbol}</span
                        >
                        <p>Total Claimed</p>
                      </div>
                    </div>
                    <div class="vesting-box">
                      <div class="flex-col vesting-box-in-x">
                        <span
                          >{parseInt(
                            linearClaimableTokens - linearClaimedTokens
                          ).toLocaleString("en", { maximumFractionDigits: 2 })}
                          {staticData.igoTokenSymbol}</span
                        >
                        <p style="line-height: 0">Ready to Claim</p>
                        <button
                          on:click={claimLinearTokens}
                          style={parseInt(
                            linearClaimableTokens - linearClaimedTokens
                          ) > 0
                            ? "opacity: 1"
                            : "opacity: 0.4;cursor:default;background:#630BF1"}
                        >
                          <i
                            style="margin-right: 6px"
                            class="fa-light fa-wallet"
                          />
                          Claim
                        </button>
                      </div>
                    </div>
                    <div class="vesting-box">
                      <div class="flex-col vesting-box-in">
                        <span
                          >{parseFloat($userAmount).toLocaleString("en", {
                            maximumSignificantDigits: 3,
                          })}
                          {staticData.igoTokenSymbol}</span
                        >
                        <p>Total Allocation</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div
                      class="outer non-outer"
                      style="height: 44px;position: relative; margin-top:32px;"
                    >
                      <div
                        class="inner"
                        style="position: absolute;background: linear-gradient(270deg, #F10088 0%, #630BF1 100%); height: 44px; border-radius: 12px; width: {parseInt(
                          percentageDeserved._hex,
                          16
                        ) / 100}%; max-width: 100%;text-align-last: center;"
                      />
                      <p
                        style="line-height:0;font-size: 14px;margin: 22px;position: relative;"
                      >
                        {parseInt(percentageDeserved._hex, 16) / 100}%
                      </p>
                    </div>
                  </div>
                  <div class="flex-row vesting-dates">
                    <div>
                      <i
                        class="fa-solid fa-play"
                        style="color:#F2309E; margin-right: 6px; font-size: 16px"
                      /><small>Started 07.07.2022</small>
                    </div>
                    <div>
                      <i
                        class="fa-solid fa-flag-checkered"
                        style="margin-right:6px; font-size: 16px; color:#1CDCC7 "
                      /><small>Ends 20/10/2022</small>
                    </div>
                  </div>
                {/if}
              </TabPanel>
            {/if}
            <!--Vesting Schedule Ends-->

            <!--Unicrypt Vesting-->
            {#if staticData.igoTokenSymbol === "CREO"}
              <TabPanel>
                {#if !$userConnected}
                  <div
                    style="text-align: center;font-size: 16px;color: #B8C5EC;font-family: 'Nexa-Regular',serif;"
                  >
                    Please connect your wallet to see your vesting schedule
                  </div>
                {/if}
                {#if $userConnected}
                  <div class="flex-col">
                    <div style="text-align: center">
                      You can claim your tokens after the TGE event on May 4,
                      2022. 10% of tokens will be distributed at TGE and the
                      rest will be vested linearly in 6 months via this <a
                        style="color:#1CDCC7;"
                        href="https://app.unicrypt.network/services/lock-tokens"
                        target="_blank">vesting website.</a
                      >
                    </div>

                    <a
                      href="https://app.unicrypt.network/services/lock-tokens"
                      class="claim-button"
                      style="margin: 16px 0 8px;align-self: center;background: #630BF1;padding: 8px 24px;border-radius: 12px;"
                    >
                      Redirect to Unicrypt
                    </a>
                  </div>
                {/if}
              </TabPanel>
            {/if}
            <!--Unicrypt Vesting-->
          </div>
        </Tabs>
      </div>
      <!--Overview Card Ends-->
    </div>
  </main>
</div>

<style>
  .main-container {
    width: 100%;
    color: white;
  }
  .w-100 {
    width: 100%;
  }
  .details {
    width: 856px;
    align-self: center;
    margin: -148px 0 24px;
  }

  .details-card {
    background: rgba(33, 13, 66, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 16px 24px 8px;
  }

  .igo-detail {
    align-items: center;
    place-content: space-between;
  }

  .count-card {
    width: fit-content;
    float: right;
    background: #210d42;
    color: white;
    padding: 8px;
    margin: 16px;
    border-radius: 12px;
    text-align: center;
  }

  .img-card {
    border-radius: 12px;
    place-content: center;
    text-align: center;
    height: 360px;
    background-size: cover;
    background-image: var(--igoBanner);
    margin: 40px 0;
  }

  .count-card .times {
    align-self: center;
    width: 100%;
  }

  .count-card .time {
    width: 100%;
    font-size: 20px;
    padding: 8px;
    background: rgba(99, 11, 241, 0.5);
    border-radius: 12px;
    margin: 4px;
  }

  .time div {
    font-size: 12px;
  }

  .linear-vesting {
    text-align: center;
    justify-content: space-between;
    gap: 24px;
    margin-top: 16px;
  }

  .vesting-box {
    background: rgba(99, 11, 241, 0.5);
    min-inline-size: max-content;
    border-radius: 12px;
    align-self: center;
    padding-block-end: 16px;
    height: 132px;
    width: 100%;
    font-size: 12px;
    color: #b8c5ec;
  }

  .vesting-box-in {
    margin: 44px;
  }

  .vesting-box button {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    background: #630bf1;
    color: white;
    border-radius: 12px;
  }

  .vesting-box span {
    font-size: 16px;
    color: white;
  }

  .vesting-dates {
    justify-content: space-between;
    margin: 8px 2px 12px;
  }

  .vesting-box-in-x {
    margin: 30px;
  }

  .overview-card {
    width: 100%;
    background: #210d42;
    border-radius: 12px;
    margin: 32px 0;
  }

  .p-refunded {
    font-size: 16px;
    color: rgb(184, 197, 236);
    font-family: Nexa-Regular;
    margin: 0 0 16px;
  }

  .igo-details {
    padding: 16px;
  }

  .contribution-button {
    background: rgba(184, 197, 236, 0.35);
    color: #630bf1;
    border-radius: 12px;
    padding: 0 12px 0 16px;
    align-items: center;
  }

  .allocation-button {
    background: rgba(184, 197, 236, 0.35);
    border-radius: 12px;
    padding: 0 12px 0 16px;
    align-items: center;
  }

  .allocation-button p {
    color: white !important;
  }

  .inner,
  .outer {
    border-radius: 12px;
    height: 8px;
  }

  .outer {
    width: 100%;
    background: rgba(184, 197, 236, 0.2);
    border-radius: 12px;
    font-size: 14px;
    margin: 8px 0;
  }

  .inner {
    width: 0%;
    background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
  }

  .status-bar {
    background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
    border-radius: 16px;
    color: white;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 8px 0 0;
    padding: 4px 8px;
  }

  .round-bar {
    background: #630bf14d;
    border-radius: 16px;
    color: #b8c5ec;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 4px 0 0;
    padding: 4px 8px;
  }

  .socials {
    justify-content: space-between;
    width: 28%;
    margin: 0 12px;
    padding: 12px 12px 12px 0;
  }

  .socials a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #630bf1;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .socials a:hover {
    background: #630bf1;
  }

  .bsn-card {
    width: 33%;
    padding: 2px;
    align-self: center;
    background: rgb(12, 2, 31);
    border-radius: 8px;
    height: fit-content;
  }

  .bsn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    place-items: center;
  }

  .bsn-row .bsn-button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .bsn-row .wrap-bsn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
    color: #1cdcc7;
  }

  .stats {
    width: 148px;
    height: 84px;
    background: rgb(33, 13, 66);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 8px;
  }

  .stats-l {
    width: 148px;
    height: 84px;
    background: rgb(33, 13, 66);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 8px;
  }

  .stats p {
    margin: 4px;
    font-size: 20px;
  }

  .stats small {
    min-width: max-content;
    font-size: 12px;
    color: #b8c5ec;
  }

  .stats-l p {
    margin: 4px;
    font-size: 20px;
  }

  .stats-l small {
    min-width: max-content;
    font-size: 12px;
    color: #b8c5ec;
  }

  .igo-buttons {
    justify-content: center;
    width: 50%;
  }

  .igo-buttons .stake-button {
    background: #630bf1;
    color: white;
    align-self: center;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 16px;
    padding: 12px;
    margin: 12px;
    width: 100%;
    text-align: center;
  }

  .stake-button:hover {
    background: #6329f1;
  }

  .overview-video {
    margin: 10px;
    height: 304px;
    width: 520px;
    border-radius: 12px;
    background-size: contain;
  }

  .video-poster {
    width: 520px;
    height: 304px;
    text-align: center;
    font-size: xxx-large;
    margin: 115px 0 0;
  }

  .video-poster:hover {
    color: #630bf1;
  }

  .overview-imgs {
    border-radius: 12px;
  }

  .overview-img {
    width: 256px;
    height: 144px;
    margin: 7px 10px;
    border-radius: 12px;
  }

  .overview-img img {
    border-radius: 12px;
    width: 100%;
  }

  .game-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #630bf1;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    color: white;
  }

  .table-header {
    background-color: #0c021f;
    color: #b8c5ec;
    padding: 8px 16px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    width: auto;
    place-content: space-between;
  }

  .table-body {
    background-color: #16092b;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    width: auto;
    place-content: space-between;
    color: white;
  }

  .table-w5 {
    margin: 16px;
    width: 5%;
    text-align: center;
    align-self: center;
  }

  .table-w10 {
    margin: 16px;
    width: 14%;
    text-align: center;
    align-self: center;
  }

  .table-w10-claim {
    margin: 16px;
    width: 14%;
    text-align: center;
    align-self: center;
  }

  .claim {
    background: #630bf1;
    color: white;
    border-radius: 12px;
    font-size: 12px;
    width: 100%;
    padding: 4px;
  }

  .claimed {
    background: #630bf1;
    color: white;
    border-radius: 12px;
    font-size: 12px;
    width: 100%;
    padding: 4px;

    opacity: 0.4;
    cursor: unset;
  }

  .none {
    background: transparent;
    color: white;
    border-radius: 12px;
    border: 1px solid #630bf1;
    font-size: 12px;
    width: 100%;
    padding: 4px;
    cursor: unset;
  }

  @media only screen and (max-width: 860px) {
    .main-container {
      width: 100%;
    }

    .details {
      width: 100%;
    }

    .socials {
      width: 32%;
      margin: 0 36px;
    }

    .stats-card {
      margin: 12px 0;
    }

    .stats-l {
      width: 120px;
    }
  }

  @media only screen and (max-width: 480px) {
    .main-container {
      width: 100%;
    }

    .details {
      width: 100%;
    }

    .img-card {
      background-image: var(--igoBannerMobile);
    }

    .details-mobile {
      display: none;
    }

    .table-header {
      padding: 8px;
      font-size: 12px;
    }

    .table-body {
      font-size: 12px;
    }

    .table-w10 {
      margin: 5px;
      text-align: center;
      width: 20%;
    }

    .table-w10-claim {
      text-align: right;
    }

    .details-card {
      padding: 12px;
      margin: 104px 0 0;
    }

    .igo-detail {
      flex-direction: column !important;
      margin: 16px 0;
      align-items: baseline;
    }

    .socials {
      justify-content: space-between;
      width: 80%;
      margin: auto;
      padding: 24px 12px 12px;
    }

    .socials a:hover {
      background: #630bf1;
    }

    .count-card {
      text-align: center;
      margin: 16px 17%;
    }

    .allocation-button {
      display: none;
    }

    .contribution-button {
      display: none;
    }

    .buttons {
      flex-direction: column;
    }

    .igo-buttons {
      width: 100%;
    }

    .igo-buttons .stake-button {
      width: 100%;
    }

    .stats-card {
      flex-direction: column !important;
      margin: 24px 0 12px;
    }

    .stats {
      width: 100%;
    }

    .stats-l {
      width: 100%;
    }

    .contents {
      flex-direction: column !important;
    }

    .bsn-card {
      width: 80%;
    }

    .igo-details {
      padding: 16px 12px;
    }

    .linear-vesting {
      flex-direction: column !important;
    }

    .vesting-box-in {
      margin: 44px;
    }

    .vesting-box-in-x {
      margin: 32px;
    }

    .vesting-dates {
      margin: 16px 2px 0;
    }

    .overview-video {
      width: 100%;
      margin: 10px 0;
      height: auto;
    }

    .video-poster {
      width: 100%;
      height: 190px;
      margin: 120px 0 0;
    }

    .overview-video img {
      width: inherit;
    }

    .overview-imgs {
      flex-direction: unset;
      margin: 10px 0;
      gap: 16px;
    }

    .overview-img {
      width: 50%;
      margin: 0;
      height: auto;
    }

    .non-outer {
      display: none;
    }

    iframe {
      max-width: 100%;
    }
  }
</style>
