import { writable } from "svelte/store";
import { ethers, providers } from "ethers";
import { notification } from "./Notification";
import Web3Modal from "web3modal";
export let spinAmount = writable("");
export let userConnected = writable(false);
export let userAddress = writable("");
export let networkProvider = writable("");
export let networkInstance = writable("");
export let networkSigner = writable("");
export let chainID = writable("");
export let nodeProvider = writable("");
export let connecting = writable(false);
export let userKYCPassed = writable(false);
export let spinPrice = writable("");
export let hexProof = writable([]);
export let userAmount = writable("");

let connectionCheck = false;
let ethAddr;

const WalletConnectProvider = window.WalletConnectProvider.default;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
        137: "https://polygon-mainnet.g.alchemy.com/v2/QoFLEPjPSkKnNCL9ah_uIDZm8No8fcS9",
      },
      qrcodeModalOptions: {
        mobileLinks: ["rainbow", "metamask", "argent", "trust"],
      },
      network: "binance",
    },
  },
};

const web3Modal = new Web3Modal({
  providerOptions: providerOptions,
  cacheProvider: true,
  theme: {
    background: "var(--background-level-3)",
    main: "var(--light-white)",
    secondary: "var(--light-primary)",
    hover: "var(--dark-primary)",
  },
});

export const connectWallet = async () => {
  connecting.set(true);
  try {
    connectionCheck = web3Modal.cachedProvider === "injected"; // Do not notify connection each time reloading the page.

    const instance = await web3Modal.connect();
    const provider = new providers.Web3Provider(instance, "any");
    const signer = provider.getSigner();

    networkInstance.set(instance);
    networkProvider.set(provider);
    networkSigner.set(signer);
    userAddress.set(await signer.getAddress());

    ethAddr = await signer.getAddress();

    chainID.set(await signer.getChainId());
    userConnected.set(true);
    connecting.set(false);

    if (connectionCheck === false) {
      notification.set({
        visible: true, // lame trigger
        title: "Connected: ",
        text: "Wallet connection successful by user.",
        icon: "fa-regular fa-hexagon-exclamation fa-lg",
      });
    }
  } catch (err) {
    connecting.set(false);
    notification.set({
      visible: true, // lame trigger
      title: "Cancelled: ",
      text: "Wallet connection cancelled by user.",
      icon: "fa-regular fa-hexagon-exclamation fa-lg",
    });
  }
};

const connectIfCached = async () => {
  if (web3Modal.cachedProvider) {
    await connectWallet();
  }
};
connectIfCached();

export const disconnectCached = async () => {
  web3Modal.clearCachedProvider();
  networkInstance.set("");
  networkProvider.set("");
  networkSigner.set("");
  userAddress.set("");
  chainID.set("");
  userConnected.set(false);
  window.location.reload();
};

setInterval(() => {
  if (window.ethereum) {
    const anyProvider = new ethers.providers.Web3Provider(
      window.ethereum,
      "any"
    );
    const anySigner = anyProvider.getSigner();
    anyProvider.on("network", async (newNetwork, oldNetwork) => {
      if (oldNetwork) {
        // await connectWallet();
        await connectNode(56);
        notification.set({
          visible: true, // lame trigger
          title: "Success: ",
          text: "Network switch successful.",
          icon: "fa-regular fa-circle-check fa-lg",
        });
        chainID.set(await anySigner.getChainId());
      }
    });
  }
}, 5000);

export const connectNode = async (chainID) => {
  let provider;
  if (chainID === 56) {
    provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed.binance.org/"
    );
  } else if (chainID === 137) {
    provider = new ethers.providers.JsonRpcProvider(
      "https://rpc-mainnet.matic.network"
    );
  }
  nodeProvider.set(provider);
};

export const postIPP = async (paidAmounts,claimedTokens) => {
  await fetch("https://spindex.network/storeIPP/", {
    method: "POST",
    mode: "cors",
    body: `ethAddress=${ethAddr}&paidAmounts=${paidAmounts}&claimedTokens=${claimedTokens}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => response.text())
};
