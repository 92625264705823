<div class="tab-list">
    <slot></slot>
</div>

<style>
    .tab-list {
        background-color: #16092B;
        border-radius: 12px;
        width: auto;
        display: flex;
        place-content: space-around;
    }
</style>