<script>
  import { slide } from "svelte/transition";

  export let entry;
  export let isOpen = false;
  const toggle = () => (isOpen = !isOpen);
</script>

<div class="accordion">
  <button on:click={toggle} aria-expanded={isOpen}>
    {@html entry[0].replaceAll("\n", "<br>")}
    <svg
      width="20"
      height="20"
      color="white"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M9 5l7 7-7 7" />
    </svg>
  </button>
  {#if isOpen}
    <p transition:slide={{ duration: 300 }}>
      {#each entry[1] as item}
        <div class="content">{@html item.replaceAll("\n", "<br>")}</div>
      {/each}
    </p>
  {/if}
</div>

<style>
  button {
    border: none;
    background: none;
    display: block;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }

  svg {
    transition: transform 0.2s ease-in;
  }

  [aria-expanded="true"] svg {
    transform: rotate(0.25turn);
  }

  .accordion {
    padding: 8px 12px 8px 12px;
  }

  .accordion button {
    padding: 10px;
    width: 100%;
    float: left;
    line-height: 24px;
    color: #b8c5ec;
    text-align: left;
  }

  .accordion p {
    margin: 0;
  }

  .accordion button svg {
    float: right;
  }

  .content {
    font-size: 14px;
    font-family: Nexa-Regular, serif;
    line-height: 20px;
    padding: 8px;
    text-align: left;
    color: rgba(184, 197, 236, 0.65);
  }

  @media only screen and (max-width: 480px) {
    .content {
      font-size: 14px;
    }

    .accordion button {
      font-size: 16px;
      line-height: 32px;
      padding: 6px 8px;
    }

    .accordion button svg {
      margin-top: 8px;
      display: none;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
</style>
