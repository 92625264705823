<script>
  import { onDestroy } from "svelte";

  export let igo;

  // Static Controls
  let roundTag = "";
  let statusTag = "";

  // State
  let round;
  let duration;

  // Check Route not to display the cards. If it is viewAll route, then display all the cards.
  let style;

  // limit The nth child for #each to render
  if (window.location.href.includes("viewAll")) {
    style = document.createElement("style");
    style.innerHTML = `main:nth-child(0) {
        display: none;}`;
    document.head.appendChild(style);
  } else {
    style = document.createElement("style");
    style.innerHTML = `main:nth-child(n + 4) {
        display: none;}`;
    document.head.appendChild(style);
  }

  // fetchData Call per 0.5 seconds
  const interval = setInterval(() => {
    setTimeStates();
  }, 500);
  onDestroy(() => {
    clearInterval(interval);
  });

  // Set Time & States
  const setTimeStates = async () => {
    if (
      igo.dynamic.contributionStartDate !== 0 &&
      igo.dynamic.contributionEndDate > Date.now() / 1000
    ) {
      duration = igo.dynamic.contributionEndDate - Date.now() / 1000;
      round = "contribution";
      if (duration < 0) {
        duration = 0;
      }
    } else if (igo.dynamic.igoState === 0) {
      duration = igo.dynamic.allocationEndDate - Date.now() / 1000;
      round = "allocation";
    } else if (igo.dynamic.igoState === 1 && igo.dynamic.totalUnallocated > 0) {
      duration = igo.dynamic.publicEndDate - Date.now() / 1000;
      round = "first-come-first-serve";
    } else if (
      igo.dynamic.igoState === 2 ||
      (igo.dynamic.igoState === 1 && igo.dynamic.totalUnallocated === 0)
    ) {
      if (igo.dynamic.claimPercentage < 100) {
        duration = 0;
        round = "vesting-progress";
      } else {
        duration = 0;
        round = "completed";
      }
    } else if (igo.dynamic.isIGOStarted === false) {
      duration = Math.floor(
        igo.static.lastPreContributionTime - Date.now() / 1000
      );
      round = "pre-contribution";
      if (duration < 0) {
        duration = 0;
      }
    }

    if(igo.static.igoTitle === "Ookeenga"){
      duration = 0;
      round = "completed";
    }

    switch (round) {
      case "pre-contribution":
        roundTag = "Public Sale";
        statusTag = "Pre Contribution Round";
        break;
      case "contribution":
        roundTag = "Public Sale";
        statusTag = "Contribution Round";
        break;
      case "allocation":
        roundTag = "Public Sale";
        statusTag = "Allocation Round";
        break;
      case "first-come-first-serve":
        roundTag = "Public Sale";
        statusTag = "FCFS Round";
        break;
      case "vesting-progress":
        roundTag = "Public Sale";
        statusTag = "Vesting in progress";
        break;
      case "completed":
        roundTag = "";
        statusTag = "Completed";
        break;
    }
  };
</script>

{#if igo.dynamic.round === "vesting-progress" && igo.dynamic.claimPercentage === 100 || igo.static.igoTitle === "Ookeenga"}
    <div class="igo-outer">
      <div class="igo-card">
        <img
          style="border-radius: 12px 12px 0 0;"
          src={igo.static.igoBannerCard}
          alt="IGOs"
        />
        <div class="igo-data">
          <p class="h24">{igo.static.igoTitle}</p>
          <div class="card-flex-row">
            <div>
              <img style="width:24px;height:24px;" src={igo.static.igoTokenIcon} alt="Token" />
            </div>
            <div style="margin: 7px 0 0 2px">
              <p style="color:#B8C5EC">{igo.static.igoTokenSymbol}</p>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-row">
              <span
                class="status-bar"
                style={round === "pre-contribution"
                  ? "background: #1CDCC7; color:black"
                  : round === "contribution" ||
                    round === "allocation" ||
                    round === "first-come-first-serve"
                  ? "background: #630BF1; color:white;"
                  : round === "vesting-progress"
                  ? "background: rgba(241, 0, 136, 0.5); color:white;"
                  : round === "completed"
                  ? "background: #B8C5EC; color:black;"
                  : ""}>{statusTag}</span
              >
              <span
                class="round-bar"
                style={roundTag !== "" ? "display: flex" : "display: none"}
                >{roundTag}</span
              >
            </div>
          </div>
          <div class="flex-row table-flex">
            <table>
              <tr>
                <th><small>Total USD Raise</small></th>
                <th><small>Total Token Raise</small></th>
                <th><small>IGO Price</small></th>
              </tr>
              <tr>
                <th
                  >{parseInt(igo.static.igoTotalUSDRaise).toLocaleString()} BUSD</th
                >
                <!--                            <th>{parseInt(igo.static.igoTotalTokenRaise).toLocaleString()}</th>-->
                <th>{igo.static.igoTotalTokenRaised}</th>
                <th>{parseFloat(igo.static.igoPrice).toLocaleString()}</th>
              </tr>
            </table>
          </div>
          <div class="buttons">
            <a class="view-details-button" href="/#/{igo.static.igoURL}/"
              ><p style="line-height: 1;">View Details</p></a
            >
          </div>
        </div>
      </div>
    </div>
{/if}

<style>
  .igo-outer{
   max-width: calc(33% - 15px);
     width: 100%;
   }
 .igo-card {
   max-width: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   background-color: var(--background-level-3);
   border-radius: 12px;
 }

 .card-count {
   position: sticky;
   place-self: center;
   margin: -14px 49px;
   background: #210d42;
   border-radius: 8px;
   font-size: 14px;
 }

 .card-count .count-title {
   padding: 2px 8px;
   align-self: center;
 }

 .card-count .count-time {
   padding: 2px;
   margin: 4px;
   background: rgba(99, 11, 241, 0.5);
   border-radius: 4px;
 }

 .count-time span {
   font-size: 12px;
 }

 .igo-data {
   padding: 24px;
   color: white;
   background: rgba(33, 13, 66, 0.75);
   border-radius: 0 0 12px 12px;
 }

 .igo-data .h24 {
   display: inline;
   margin-block-start: auto;
 }

 .igo-data .buttons {
   margin: 20px 0 4px;
   text-align: center;
   width: 100%;
   display: flex;
   justify-content: space-between;
 }

 .buttons .claim-button {
   width: 100%;
   height: 48px;
   font-size: 16px;
   margin-right: 6px;
   border-radius: 12px;
   background: #630bf1;
   color: white;
 }

 .claim-button:hover {
   background-color: #6329f1;
 }

 .buttons .view-details-button {
   width: 100%;
   height: 48px;
   font-size: 16px;
   border-radius: 12px;
   background: transparent;
   border: solid 1px #630bf1;
   color: white;
   align-self: center;
 }

 .buttons .view-details-button:hover {
   background-color: #630bf1;
 }

 .igo-data .view-button button {
   width: 100%;
   height: 48px;
   border-radius: 12px;
   background: transparent;
   color: white;
   border: 1px solid #630bf1;
 }

 .igo-data .card-flex-row p {
   padding: 0 0 4px 4px;
 }

 .table-flex {
   justify-content: center;
   flex-direction: column-reverse;
   padding-top: 14px;
   text-align: left;
 }

 .table-flex small {
   color: #b8c5ec;
   font-size: 12px;
 }

 .igo-data .card-flex-row {
   display: flex;
   align-items: center;
   line-height: 0.4;
 }

 .status-bar {
   background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
   border-radius: 16px;
   color: white;
   font-size: 12px;
   height: fit-content;
   align-self: center;
   margin: 0 8px 0 0;
   padding: 4px 8px;
 }

 .round-bar {
   background: #630bf14d;
   border-radius: 16px;
   color: #b8c5ec;
   font-size: 12px;
   height: fit-content;
   align-self: center;
   margin: 0 4px 0 0;
   padding: 4px 8px;
 }

 .outer {
   width: 100%;
   background: rgba(99, 11, 241, 0.5);
   border-radius: 12px;
   font-size: 14px;
   margin: 16px 0 0;
   height: 32px;
 }

 .inner {
   width: 0;
   height: 32px;
   background: rgba(99, 11, 241, 0.5);
   border-radius: 12px;
 }

 .inner p {
   line-height: 0;
   position: absolute;
   margin: 16px 0 0 36px;
 }

 .flex-row p {
   justify-content: space-between;
 }

 .flex-row table {
   justify-content: space-between;
   width: 100%;
 }

 @media only screen and (max-width: 860px) {
   .igo-card {
     height: 100%;
   }

   .igo-card img {
     width: 100%;
   }
 }

 @media only screen and (max-width: 480px) {
   .igo-card {
     width: 100%;
     margin: 0;
   }
   .igo-outer{
     max-width: calc(100%);
     width: 100%;
     margin-top: 1.5rem;
   }
   .igo-card img {
     width: 100%;
   }
 }
</style>
