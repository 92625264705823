<script>
  import { onDestroy, onMount } from "svelte";
  import {
    chainID,
    userAddress,
    userConnected,
    userKYCPassed,
  } from "../../Stores/Network";
  import { notification } from "../../Stores/Notification";

  let isResultOk = false;

  const init = {
    headers: {
      Authorization: "BLOCKPASS_KEY",
    },
  };

  onMount(async () => {
    await fetchUserData();
  });

  const interval = setInterval(() => {
    fetchUserData();
  }, 10000);
  onDestroy(() => {
    clearInterval(interval);
  });

  const fetchUserData = async () => {
    if ($userConnected && $chainID === 56) {
      await requestApproval();
      // blockpassLoad();
    }
  };

  const requestApproval = async () => {
    let result = await fetch(
      `https://kyc.blockpass.org/kyc/1.0/connect/spinstarter_c6185/refId/${$userAddress}`,
      init
    );
    if (result.ok) {
      result = await result.json();
      result = result.status;
      if (result === "success") {
        userKYCPassed.set(true);
      }
    }
    isResultOk = true;
  };

  // blockpass

  const blockpassLoad = () => {
    const blockpass = new window.BlockpassKYCConnect("spinstarter_c6185", {
      env: "prod",
      refId: $userAddress,
      local_user_id: $userAddress,
      mainColor: "purple",
    });
    blockpass.startKYCConnect();
    blockpass.on("KYCConnectSuccess", () => {
      notification.set({
        visible: true, // simple trigger
        title: "Data sent: ",
        text: "KYC data succesfully sent.",
        icon: "fa-regular fa-circle-check fa-lg",
      });
    });
    blockpass.on("KYCConnectClose", () => {
      notification.set({
        visible: true, // simple trigger
        title: "Connection Closed: ",
        text: "KYC process finished.",
        icon: "fa-regular fa-circle-check fa-lg",
      });
    });
    blockpass.on("KYCConnectCancel", () => {
      notification.set({
        visible: true, // simple trigger
        title: "KYC Cancelled: ",
        text: "KYC cancelled by user.",
        icon: "fa-regular fa-hexagon-exclamation fa-lg",
      });
    });
  };
</script>

{#if !$userKYCPassed && isResultOk}
  <main>
    <div class="flex-row kyc-card">
      <div class="flex-row items">
        {#if !$userKYCPassed}
          <div>
            <div style="font-size: 20px; font-family: Nexa-Bold, serif">
              KYC Verification is required
            </div>
            <div style="font-size: 14px; font-family: Nexa-Regular,serif">
              In order for you to join IGOs, you must verify your account
            </div>
          </div>
        {/if}
        {#if $userKYCPassed}
          <div>
            <div style="font-size: 20px; font-family: Nexa-Bold, serif">
              KYC is verified
            </div>
            <div style="font-size: 14px; font-family: Nexa-Regular,serif">
              You are eligible to join IGOs
            </div>
          </div>
        {/if}
        <div>
          {#if !$userKYCPassed}
            <a id="blockpass-kyc-connect">
              <button class="kyc-button"> Verify </button>
            </a>
          {/if}
          {#if $userKYCPassed}
            <a href="/">
              <button class="kyc-button-disabled"> Verified </button>
            </a>
          {/if}
        </div>
      </div>
    </div>
  </main>
{/if}

<style>
  main {
    margin-top: auto;
  }

  .kyc-card {
    width: 100%;
    height: 80px;
    background: rgb(22, 9, 43);
    color: white;
    justify-content: space-evenly;
    place-items: center;
  }

  .kyc-card .items {
    width: 856px;
    justify-content: space-between;
  }

  .kyc-button {
    background-color: #630bf1;
    border-radius: 12px;
    color: white;
    height: 48px;
    width: 140px;
    font-size: 16px;
  }

  .kyc-button-disabled {
    background-color: #630bf1;
    border-radius: 12px;
    color: white;
    height: 48px;
    width: 140px;
    font-size: 16px;
    cursor: unset;
    opacity: 0.4;
  }

  .kyc-button:hover {
    background-color: #6329f1;
  }

  @media only screen and (max-width: 480px) {
    .kyc-card {
      height: 160px;
      flex-direction: column;
      justify-content: center;
    }

    .kyc-card .items {
      width: unset;
      justify-content: unset;
      flex-direction: column;
      text-align: center;
    }

    .kyc-button {
      margin: 12px 0;
      width: 80%;
    }
  }
</style>
