export const addr = {
  token: {
    spin: "0x6AA217312960A21aDbde1478DC8cBCf828110A67",
    aot: "0x9589014F7a8547B89A6331eEEe32b7fBd5852af9",
    sfund: "0x477bC8d23c634C154061869478bce96BE6045D12",
    dnxc: "0x3c1748d647e6a56b37b66fcd2b5626d0461d3aa0",
    king: "0x0cCD575bf9378c06f6DCa82f8122F570769F00C2",
    dmlg: "0x1c796C140de269E255372ea687EF7644BAb87935",
  },
  lp: {
    spinBnb: "0x89c68051543Fa135B31c2CE7BD8Cdf392345FF01",
    bnbBusd: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
  },
  igo: {
    vault: "0x03447d28FC19cD3f3cB449AfFE6B3725b3BCdA77",
    // vault: "0xAdea083E4c3dD1f1EDF3206479378D8894B1ca66",
    paymentToken: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    // paymentToken: "0xd79f68fe4683aeF1fa5242d435EbfB3C1063d4A9",
  },
};
