<script>
  import { onMount, onDestroy } from "svelte";
  import { ethers } from "ethers";
  import { addr } from "../Stores/Address";
  import {
    abiIGO,
    abiClaim,
    abiPaymentToken,
    abiLinearVesting,
  } from "../Stores/ABI";
  import {
    networkProvider,
    userAddress,
    userConnected,
    connectNode,
    connectWallet,
    networkSigner,
    chainID,
    hexProof,
    userAmount,
  } from "../Stores/Network";
  import { notification } from "../Stores/Notification";
  import ClaimModal from "../Components/UI/Modals/ClaimModal.svelte";
  import MyIGOs from "../Components/IGOCards/MyIGOs.svelte";
  import UpcomingIGOs from "../Components/IGOCards/UpcomingIGOs.svelte";
  import VestingIGOs from "../Components/IGOCards/VestingIGOs.svelte";
  import CompletedIGOs from "../Components/IGOCards/CompletedIGOs.svelte";
  import Carousel from "../Components/UI/Carousel.svelte";

  window.scroll({
    top: 0,
    left: 0,
  });

  // Length of IGOs Types
  let upcomingIGOsLength;
  let vestingProgressLength;
  let completedIGOsLength;

  // Active IGOCard onChain & OffChain Data Store
  let activeIGOs = [];
  let activeStaticData = {};
  let activeDynamicData = {};

  // Static Controls
  let roundTag = "";
  let statusTag = "";
  let progressText = "";
  let progress;

  // Modal Controls
  let modalOpen = false;
  let modalType;

  // Fetch User Based Data
  let deservedAllocation;
  let deservedPublicAllocation;
  let deservedAllocationRaw;
  let paidAmount;
  let paidPublic;
  let maxPublicBuy;
  let userBought;
  let userBoughtAlloc;
  let diffUserAlloc;
  let maxBuyPossible;
  let maxBuyPossibleAlloc;
  let paymentTokenBalance;
  let userApproved;
  let userAllowance;
  let igoEarned;
  let userClaimableTokens;

  // Fetch Non-User Based Data
  let claimedAmounts;
  let totalPaidForClaim;
  let totalDollarsToPay;

  // Action Statement
  let approveInProgress = false;
  let claimInProgress = false;

  // State
  let round;
  let duration;

  // Counter
  let days;
  let hours;
  let minutes;
  let seconds;

  let linearVestingContractAddr = "0x0ca4d43fa7a5032A0cEE1F9cBdF717DbB614df05";
  let percentageDeserved;
  let linearClaimedTokens;
  let linearClaimableTokens;
  let claimContractAddress;

  let showBanner = false;

  onMount(async () => {
    await connectNode(); // Connect Node.
    await fetchData(); // fetch onChain Data from API.
  });

  // Fetch onChain & offChain Data
  const fetchData = async () => {
    await fetch("https://apiv1.spintop.network/fetchIGOs")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed!");
        }
        
        return res.json();
      })
      .then((data) => {
        console.log(data);
        
        upcomingIGOsLength = data.IGOs.filter(
          (x) => x.dynamic.round === "pre-contribution"
        ).length;
        // vestingProgressLength = data.IGOs.filter(
        //   (x) => x.dynamic.round === "vesting-progress"
        // ).length;
        vestingProgressLength = 0;
        completedIGOsLength = data.IGOs.filter(
          (x) =>
            x.dynamic.round === "vesting-progress" &&
            x.dynamic.claimPercentage === 100 &&
            x.static.igoTitle !== "Creo Engine" &&
            x.static.igoVestingType !== "linear"
        ).length;

        activeIGOs = data.IGOs.filter((x) => x.static.isIGOActive === 1);

        if (activeIGOs.length > 0) {
          activeStaticData = activeIGOs[0].static;
          activeDynamicData = activeIGOs[0].dynamic;
          claimContractAddress = activeIGOs[0].dynamic.claimAddr;

          document.documentElement.style.setProperty(
            "--igoBanner",
            `url(${activeStaticData.igoBanner}`
          );
          document.documentElement.style.setProperty(
            "--igoBannerMobile",
            `url(${activeStaticData.igoBannerMobile}`
          );

          setTimeStates(activeDynamicData, activeStaticData);
        } else if (activeIGOs.length === 0) {
          showBanner = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // fetchData Call per 0.5seconds
  const interval = setInterval(() => {
    fetchData();
  }, 500);
  onDestroy(() => {
    clearInterval(interval);
  });

  // Set Time & States
  const setTimeStates = async (activeDynamicData, activeStaticData) => {
    if ($userConnected) {
      const linearVestingContract = new ethers.Contract(
        linearVestingContractAddr,
        abiLinearVesting,
        $networkProvider
      );
      percentageDeserved = await linearVestingContract.percentageDeserved();
    }

    if (activeDynamicData.isIGOStarted === true) {
      $userConnected
        ? await fetchUserData(activeDynamicData, activeStaticData)
        : "";
    }

    if (
      activeDynamicData.contributionStartDate !== 0 &&
      activeDynamicData.contributionEndDate > Date.now() / 1000
    ) {
      duration = activeDynamicData.contributionEndDate - Date.now() / 1000;
      round = "contribution";
      if (duration < 0) {
        duration = 0;
      }
    } else if (activeDynamicData.igoState === 0) {
      duration = activeDynamicData.allocationEndDate - Date.now() / 1000;
      round = "allocation";
    } else if (
      activeDynamicData.igoState === 1 &&
      activeDynamicData.totalUnallocated > 0
    ) {
      duration = activeDynamicData.publicEndDate - Date.now() / 1000;
      round = "first-come-first-serve";
    } else if (
      activeDynamicData.igoState === 2 ||
      (activeDynamicData.igoState === 1 &&
        activeDynamicData.totalUnallocated === 0)
    ) {
      duration = 0;
      round = "vesting-progress";
    } else if (activeDynamicData.isIGOStarted === false) {
      duration = Math.floor(
        activeStaticData.lastPreContributionTime - Date.now() / 1000
      );
      round = "pre-contribution";
      if (duration < 0) {
        duration = 0;
      }
    }

    days = Math.floor(duration / (60 * 60 * 24));
    hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
    minutes = Math.floor((duration % (60 * 60)) / 60);
    seconds = Math.floor(duration % 60);

    switch (round) {
      case "pre-contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Pre Contribution Round";
        progressText = "Total Raised";
        break;
      case "contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Contribution Round";
        progressText = "Total Raised";
        break;
      case "allocation":
        progress =
          (activeDynamicData.totalPaidForClaim /
            activeDynamicData.totalDollarsToPay) *
          100;
        roundTag = "Public Sale";
        statusTag = "Allocation Round";
        progressText = "Total Allocated";
        break;
      case "first-come-first-serve":
        progress =
          (activeDynamicData.totalPaidForClaim /
            activeDynamicData.totalDollarsToPay) *
          100;
        roundTag = "Public Sale";
        statusTag = "FCFS Round";
        progressText = "Total Allocated";
        break;
      case "vesting-progress":
        progress = 100;
        roundTag = "";
        statusTag = "Vesting in progress";
        progressText = "Total Raised";
        break;
      case "completed":
        progress = 100;
        roundTag = "";
        statusTag = "Completed";
        progressText = "Total Raised";
        break;
    }
  };

  // Manage Close Modal
  const closeModal = (type) => {
    modalType = type;
    modalOpen = false;
  };

  // Manage Open Modal
  const openModal = (type) => {
    modalType = type;
    modalOpen = true;
  };

  // Fetch User Based Data
  const fetchUserData = async (dynamicData, staticData) => {
    const linearVestingContract = new ethers.Contract(
      linearVestingContractAddr,
      abiLinearVesting,
      $networkProvider
    );
    linearClaimedTokens = await linearVestingContract.claimedTokens(
      $userAddress
    );
    linearClaimableTokens = await linearVestingContract.deserved($userAmount);

    if ($userConnected && $chainID === staticData.chainID) {
      const igoContract = new ethers.Contract(
        dynamicData.igoAddr,
        abiIGO,
        $networkProvider
      );
      const claimContract = new ethers.Contract(
        dynamicData.claimAddr,
        abiClaim,
        $networkProvider
      );
      const paymentTokenContract = new ethers.Contract(
        dynamicData.paymentTokenAddr,
        abiPaymentToken,
        $networkProvider
      );

      deservedAllocationRaw = await claimContract.deservedAllocation(
        $userAddress
      );

      deservedAllocation = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.deservedAllocation($userAddress)
        )
      );
      deservedPublicAllocation =
        deservedAllocation + deservedAllocation * dynamicData.multiplier;

      paymentTokenBalance = await paymentTokenContract.balanceOf($userAddress);
      userAllowance = await paymentTokenContract.allowance(
        $userAddress,
        dynamicData.claimAddr
      );
      userApproved = ethers.utils.formatUnits(userAllowance) > 1000000000;

      totalDollarsToPay = await claimContract.totalDollars();
      totalPaidForClaim = await claimContract.totalPaid();

      maxPublicBuy = await claimContract.maxPublicBuy($userAddress);
      userBought = await claimContract.paidPublic($userAddress);

      let diffUser = maxPublicBuy.sub(userBought);
      let diff = totalDollarsToPay.sub(totalPaidForClaim);
      maxBuyPossible = diffUser.gt(diff) ? diff : diffUser;
      userBoughtAlloc = await claimContract.paidAmounts($userAddress);
      diffUserAlloc = deservedAllocationRaw.sub(userBoughtAlloc);
      maxBuyPossibleAlloc = diffUserAlloc;

      paidAmount = ethers.utils.formatUnits(
        await claimContract.paidAmounts($userAddress)
      );
      paidPublic = ethers.utils.formatUnits(
        await claimContract.paidPublic($userAddress)
      );

      userClaimableTokens = parseFloat(
        ethers.utils.formatEther(
          await claimContract.claimableTokens($userAddress)
        )
      );

      const claimedTokens = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.claimedAmounts($userAddress)
        )
      ).toFixed(2);

      claimedAmounts = parseInt(claimedTokens / dynamicData.tokenPrice);

      igoEarned = ethers.utils.formatEther(
        await igoContract.earned($userAddress)
      );
    }
  };

  // Approve the Payment Token Contract
  const approve = async () => {
    if ($userConnected && $chainID === activeStaticData.chainID) {
      const paymentTokenContract = new ethers.Contract(
        addr.igo.paymentToken,
        abiPaymentToken,
        $networkProvider
      );
      const claimWithSigner = paymentTokenContract.connect($networkSigner);
      approveInProgress = true;
      try {
        const approve = await claimWithSigner.approve(
          activeDynamicData.claimAddr,
          ethers.constants.MaxUint256
        );
        let receipt = await approve.wait();
        receipt.status === 1
          ? notification.set({
              visible: true,
              title: "Success: ",
              text: "Approve succesful.",
              icon: "fa-regular fa-circle-check fa-lg",
            })
          : notification.set({
              visible: true, // simple trigger
              title: "Failed: ",
              text: "Approve failed.",
              icon: "fa-regular fa-hexagon-exclamation fa-lg",
            });
        await fetchUserData();
      } catch (err) {
        if (err.code === 4001) {
          approveInProgress = false;
          notification.set({
            visible: true,
            title: "Cancelled: ",
            text: "Approve cancelled by wallet.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
        }
      }
      approveInProgress = false;
    } else {
      await connectWallet();
    }
  };

  // Claim Unlocked Tokens
  const claimTokens = async () => {
    const claimContract = new ethers.Contract(
      activeDynamicData.claimAddr,
      abiClaim,
      $networkProvider
    );
    const claimWithSigner = claimContract.connect($networkSigner);
    claimInProgress = true;
    try {
        const claimAll = await claimWithSigner.claimTokens();
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
      claimInProgress = false;
    }
  };

  // Linear Claim Unlocked Tokens
  const claimLinearTokens = async () => {
    const linearVestingContract = new ethers.Contract(
      linearVestingContractAddr,
      abiLinearVesting,
      $networkProvider
    );
    const claimWithSigner = linearVestingContract.connect($networkSigner);
    claimInProgress = true;
    try {
      const claimAll = await claimWithSigner.claim($userAmount, $hexProof);
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
      claimInProgress = false;
    }
  };
</script>

{#if modalOpen}
  {#if parseInt(maxBuyPossibleAlloc) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={claimContractAddress}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossibleAlloc) >= parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={claimContractAddress}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossible) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={claimContractAddress}
      allowance={maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {/if}
{/if}

<div class="flex-col main-container">
  <Carousel />

  <!--Header Begins-->
  {#if activeIGOs.length > 0}
    <main>
      <div>
        <header style="margin: 22px 0 ">Active IGOs</header>
        <!--Banner & Timer Begins-->
        <div>
          {#if round !== "vesting-progress"}
            <div
              class="count-card flex-col"
              style={seconds < 0 ? "display:none" : ""}
            >
              <div>
                {#if round === "pre-contribution"}
                  Contribution round starts in
                {:else if round === "contribution"}
                  Contribution round ends in
                {:else if round === "allocation"}
                  Allocation round ends in
                {:else if round === "first-come-first-serve"}
                  Public sale ends in
                {:else if round === "vesting-progress"}
                  Vesting in progress
                {/if}
              </div>
              {#if days ? days : "" || hours ? hours : "" || minutes ? minutes : "" || seconds ? seconds : ""}
                <div class="flex-row times">
                  {#if days > 0}
                    <div class="time">
                      {days}
                      <div>Days</div>
                    </div>
                  {/if}
                  <div class="time">
                    {hours}
                    <div>Hours</div>
                  </div>
                  <div class="time">
                    {minutes}
                    <div>Minutes</div>
                  </div>
                  {#if days === 0}
                    <div class="time">
                      {seconds}
                      <div>Seconds</div>
                    </div>
                  {/if}
                </div>
              {/if}
            </div>
          {:else if round === "vesting-progress" && activeStaticData.igoVestingType === "cliff" && $userConnected}
            <div class="count-card flex-col">
              <div
                class="times flex-col"
                style="padding: 20px 92px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
              >
                <div>
                  <div>
                    {activeDynamicData.claimPercentage} / 100
                  </div>
                  <div>
                    <small>Vesting Progress</small>
                  </div>
                </div>
              </div>
            </div>
          {:else if round === "vesting-progress" && activeStaticData.igoVestingType === "linear" && $userConnected}
            <div class="count-card flex-col">
              <div
                class="times flex-col"
                style="padding: 20px 92px; background: rgba(99, 11, 241, 0.5); border-radius: 12px;"
              >
                <div>
                  <div>
                    {percentageDeserved / 100}%
                  </div>
                  <div>
                    <small>Vesting Progress</small>
                  </div>
                </div>
              </div>
            </div>
          {/if}

          <div class="img-card" />
        </div>
        <!--Banner & Timer Ends-->
      </div>
    </main>
  {/if}
  <!--Header Ends-->

  <!--IGO Details Begins-->
  <main class="details">
    <div>
      <div class="detail-card">
        {#each activeIGOs.slice(0, 1) as active}
          {#if active.static.isIGOActive === 1}
            <div class="flex-col">
              <div class="flex-col">
                <div>
                  <div class="flex-row details-card-h">
                    <div class="flex-row">
                      <span>
                        {active.static.igoTitle}
                      </span>
                      <div class="flex-row" style="align-self: center;">
                        <div class="details-card-img">
                          <img
                            style="width: 24px; height: 24px"
                            src={active.static.igoTokenIcon}
                            alt="token"
                          />
                        </div>
                        <div style="color: #B8C5EC">
                          {active.static.igoTokenSymbol}
                        </div>
                      </div>
                    </div>
                    {#if $userConnected}
                      {#if round !== "contribution"}
                        <div
                          class="{round === 'pre-contribution'
                            ? 'contribution-button'
                            : 'allocation-button'} flex-row"
                        >
                          <p style="color: white;">
                            {#if round === "pre-contribution"}
                              Allocation N/A
                            {:else if round === "allocation"}
                              Your Allocation : {parseFloat(
                                igoEarned
                              ).toLocaleString("en", {
                                maximumFractionDigits: 3,
                              })
                                ? parseFloat(igoEarned).toLocaleString("en", {
                                    maximumFractionDigits: 3,
                                  })
                                : "-"}
                              / Paid : {parseFloat(paidAmount).toLocaleString(
                                "en"
                              )} BUSD
                            {:else if round === "first-come-first-serve"}
                              {#if parseFloat(totalDollarsToPay) === parseFloat(totalPaidForClaim)}
                                Total Paid : {parseFloat(
                                  paidAmount
                                ).toLocaleString("en")} BUSD
                              {:else}
                                Allocation : {parseFloat(
                                  igoEarned
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 3,
                                })
                                  ? parseFloat(igoEarned).toLocaleString("en", {
                                      maximumFractionDigits: 3,
                                    }) * parseFloat(active.dynamic.multiplier)
                                  : "-"}
                                / Paid : {parseFloat(paidAmount).toLocaleString(
                                  "en"
                                )} BUSD
                              {/if}
                            {:else if round === "vesting-progress"}
                              {#if active.static.igoVestingType === "cliff"}
                                Claimable {active.static.igoTokenSymbol} :
                                {parseFloat(userClaimableTokens).toLocaleString(
                                  "en"
                                )}
                                / Claimed : {parseFloat(
                                  claimedAmounts
                                ).toLocaleString("en")}
                              {/if}
                              {#if active.static.igoVestingType === "linear"}
                                Claimable :
                                {parseInt(linearClaimableTokens).toLocaleString(
                                  "en",
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                                / Claimed : {parseInt(
                                  linearClaimedTokens
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                })}
                                {active.static.igoTokenSymbol}
                              {/if}
                            {/if}
                          </p>
                        </div>
                      {/if}
                    {/if}
                    {#if $userConnected}
                      {#if round === "contribution"}
                        <div class="flex-row">
                          <div
                            class="outer non-outer"
                            style="height: 44px;position: relative"
                          >
                            <div
                              class="inner"
                              style="position: absolute;background: rgba(99, 11, 241, 0.5); height: 44px; border-radius: 12px; width: {((active
                                .dynamic.contributionTime -
                                duration) /
                                active.dynamic.contributionTime) *
                                100}%; max-width: 100%;text-align-last: center;"
                            />
                            <p
                              style="line-height:0;font-size: 14px;margin: 22px;position: relative;"
                            >
                              Your allocation : {parseFloat(
                                deservedAllocation
                              ).toLocaleString("en", {
                                maximumFractionDigits: 2,
                              })}
                              BUSD
                            </p>
                          </div>
                        </div>
                      {/if}
                    {/if}
                  </div>
                </div>
                <div>
                  <div class="flex-row">
                    <div
                      class="flex-row info-card"
                      style={$userConnected && round === "vesting-progress"
                        ? "width: 100%"
                        : "width: 80%"}
                    >
                      <div
                        class="flex-row"
                        style="align-self: start; margin: 16px 0;"
                      >
                        <div
                          class="status-bar"
                          style={round === "pre-contribution"
                            ? "background: #1CDCC7; color:black"
                            : round === "contribution" ||
                              round === "allocation" ||
                              round === "first-come-first-serve"
                            ? "background: #630BF1; color:white;"
                            : round === "vesting-progress"
                            ? "background: rgba(241, 0, 136, 0.5); color:white;"
                            : ""}
                        >
                          {statusTag}
                        </div>
                        {#if roundTag !== ""}
                          <div class="round-bar">
                            {roundTag}
                          </div>
                        {/if}
                      </div>
                      <div class="mobile-w" style="justify-self: center">
                        {#if $userConnected}
                          <div
                            class="{round === 'contribution' ||
                            round === 'pre-contribution'
                              ? 'mobile-contribution'
                              : 'mobile-allocation'}  flex-row"
                          >
                            <p>
                              {#if round === "pre-contribution"}
                                Allocation N/A
                              {:else if round === "contribution"}
                                Your allocation : {parseFloat(
                                  deservedAllocation
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 2,
                                })}
                                BUSD
                              {:else if round === "allocation"}
                                Allocation : {parseFloat(
                                  igoEarned
                                ).toLocaleString("en", {
                                  maximumFractionDigits: 3,
                                })
                                  ? parseFloat(igoEarned).toLocaleString("en", {
                                      maximumFractionDigits: 3,
                                    })
                                  : "-"}
                                / Paid : {parseFloat(paidAmount).toLocaleString(
                                  "en"
                                )} BUSD
                              {:else if round === "first-come-first-serve"}
                                {#if active.dynamic.totalPaidForClaim === active.dynamic.totalDollarsToPay}
                                  Total Paid : {parseFloat(
                                    paidAmount
                                  ).toLocaleString("en")}
                                  BUSD
                                {:else}Allocation : {parseFloat(
                                    igoEarned
                                  ).toLocaleString("en", {
                                    maximumFractionDigits: 3,
                                  })
                                    ? parseFloat(igoEarned).toLocaleString(
                                        "en",
                                        { maximumFractionDigits: 3 }
                                      ) * parseFloat(active.dynamic.multiplier)
                                    : "-"}
                                  / Paid : {parseFloat(
                                    paidPublic
                                  ).toLocaleString("en")}
                                  BUSD
                                {/if}
                              {:else if round === "vesting-progress"}
                                {#if active.static.igoVestingType === "cliff"}
                                  Claimable : {active.static.igoTokenSymbol} :
                                  {parseFloat(
                                    userClaimableTokens
                                  ).toLocaleString("en")}
                                  / Claimed : {parseFloat(
                                    claimedAmounts
                                  ).toLocaleString("en")}
                                {/if}
                                {#if active.static.igoVestingType === "linear"}
                                  Claimable :
                                  {parseInt(
                                    linearClaimableTokens
                                  ).toLocaleString("en", {
                                    maximumFractionDigits: 2,
                                  })}
                                  / Claimed : {parseInt(
                                    linearClaimedTokens
                                  ).toLocaleString("en", {
                                    maximumFractionDigits: 2,
                                  })}
                                {/if}
                              {/if}
                            </p>
                          </div>
                        {/if}
                      </div>

                      <div class="flex-row" style="flex-wrap:wrap; gap:2px;">
                        <div class="flex-col">
                          <p class="info">Total BUSD Raise</p>
                          <span
                            >{parseInt(
                              active.static.igoTotalUSDRaise
                            ).toLocaleString("en")}
                            BUSD
                          </span>
                        </div>
                        <div class="flex-col">
                          <p class="info">Total Token Raise</p>
                          <span>{active.static.igoTotalTokenRaised}</span>
                        </div>
                        <div class="flex-col">
                          <p class="info">IGO Price</p>
                          <span
                            >{parseFloat(active.static.igoPrice).toLocaleString(
                              "en"
                            )}</span
                          >
                        </div>
                        {#if $userConnected && round === "vesting-progress"}
                          <div class="flex-col">
                            <p class="info">Your Total Allocation</p>
                            <span
                              >${parseFloat(
                                deservedPublicAllocation
                              ).toLocaleString("en", {
                                maximumFractionDigits: 2,
                              })}</span
                            >
                          </div>
                          <div class="flex-col">
                            <p class="info">Total Paid</p>
                            <span
                              >${parseFloat(paidAmount).toLocaleString("en", {
                                maximumFractionDigits: 2,
                              })}</span
                            >
                          </div>
                        {/if}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {progressText}
              </div>
              <div class="outer">
                <div
                  class="inner"
                  style="width: {progress}%; max-width: 100%;"
                />
              </div>
              <div class="flex-row" style="justify-content: space-between">
                <div>
                  {parseFloat(progress).toFixed(2).toLocaleString("en")}%
                </div>
                <div>
                  {parseInt(
                    (progress / 100) * active.dynamic.totalDollarsPublic
                  ).toLocaleString("en")}
                  / {parseInt(active.dynamic.totalDollarsPublic).toLocaleString(
                    "en"
                  )} BUSD
                </div>
              </div>

              <div class="flex-row igo-buttons">
                {#if round === "pre-contribution" || round === "contribution"}
                  {#if !$userConnected}
                    <button class="stake-button" on:click={connectWallet}>
                      Connect Wallet
                    </button>
                  {:else}
                    <a
                      href="https://staking.spintop.network/#/vault"
                      target="_blank"
                      class="stake-button"
                      >Go to Staking <i
                        class="fa-solid fa-arrow-up-right-from-square"
                      /></a
                    >
                  {/if}
                {/if}
                {#if round === "allocation"}
                  <button
                    class="stake-button"
                    style={$userConnected
                      ? userApproved
                        ? parseInt(maxBuyPossibleAlloc) > 0
                          ? ""
                          : "opacity: 0.4;cursor:default"
                        : ""
                      : ""}
                    on:click={$userConnected
                      ? userApproved
                        ? parseInt(maxBuyPossibleAlloc) > 0
                          ? openModal("payAllocation")
                          : ""
                        : approve()
                      : connectWallet()}
                  >
                    <i
                      class={approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Pay for Your Allocation"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
                {#if round === "first-come-first-serve"}
                  <button
                    class="stake-button"
                    style={$userConnected
                      ? userApproved
                        ? parseInt(maxBuyPossible) > 0
                          ? ""
                          : "opacity: 0.4;cursor:default"
                        : ""
                      : ""}
                    on:click={$userConnected
                      ? userApproved
                        ? parseInt(maxBuyPossible) > 0
                          ? openModal("payPublic")
                          : ""
                        : approve()
                      : connectWallet()}
                  >
                    <i
                      class={approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Pay for Your Allocation"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
                {#if round === "vesting-progress" && active.static.igoVestingType === "cliff"}
                  <button
                    class="stake-button"
                    on:click={$userConnected
                      ? userApproved
                        ? parseFloat(userClaimableTokens) > 0
                          ? claimTokens()
                          : ""
                        : approve()
                      : connectWallet()}
                    style={$userConnected
                      ? userApproved
                        ? parseInt(
                            userClaimableTokens - claimedAmounts
                          ) > 0
                          ? ""
                          : "opacity: 0.4;cursor:default"
                        : ""
                      : ""}
                  >
                    <i
                      class={claimInProgress || approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Claim All"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
                {#if round === "vesting-progress" && active.static.igoVestingType === "linear"}
                  <button
                    class="stake-button"
                    on:click={$userConnected
                      ? userApproved
                        ? parseInt(
                            linearClaimableTokens - linearClaimedTokens
                          ) > 0
                          ? claimLinearTokens()
                          : ""
                        : approve()
                      : connectWallet()}
                    style={$userConnected
                      ? userApproved
                        ? parseInt(
                            linearClaimableTokens - linearClaimedTokens
                          ) > 0
                          ? ""
                          : "opacity: 0.4;cursor:default"
                        : ""
                      : ""}
                  >
                    <i
                      class={claimInProgress || approveInProgress
                        ? "fa fa-circle-o-notch fa-spin"
                        : ""}
                    />
                    {$userConnected
                      ? userApproved
                        ? "Claim All"
                        : "Enable contract"
                      : "Connect Wallet"}
                  </button>
                {/if}
                <a class="view-button" href="/#/{active.static.igoURL}/"
                  >View Details
                </a>
              </div>
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </main>
  <!--IGO Details Ends-->

  <div style="margin-top: 210px" />
  <!--My IGO's Begins-->
  {#if $userConnected}
    <div class="flex-row igos-card">
      <div class="h24">My IGO's</div>
      <a href="/#/view-all/my-igos/" class="flex-row view">
        View All
        <i class="fa-solid fa-arrow-right" style="margin:5px 0 0 8px;" />
      </a>
    </div>
    <div class="igo-cards flex-row">
      <MyIGOs />
    </div>
  {/if}
  <!--My IGO's Ends-->

  <!--Upcoming IGO’s Begins-->
  {#if upcomingIGOsLength > 0}
    <div class="flex-row igos-card">
      <div class="h24">Upcoming IGO’s</div>
      <a href="/#/view-all/upcoming-igos/" class="flex-row view">
        View All
        <i class="fa-solid fa-arrow-right" style="margin:5px 0 0 8px;" />
      </a>
    </div>
    <div class="igo-cards flex-row">
      <UpcomingIGOs />
    </div>
  {/if}
  <!--Upcoming IGO’s Ends-->

  <!--IGO’s on Vesting Begins-->
  {#if vestingProgressLength > 0}
    <div class="flex-row igos-card">
      <div class="h24">Vesting in Progress</div>
      <a href="/#/view-all/vesting-progress-igos/" class="flex-row view">
        View All
        <i class="fa-solid fa-arrow-right" style="margin:5px 0 0 8px;" />
      </a>
    </div>
    <div class="igo-cards flex-row">
      <VestingIGOs />
    </div>
  {/if}
  <!--IGO’s on Vesting Ends-->

  <!--Completed IGO’s Begins-->
  {#if completedIGOsLength > 0}
    <div class="flex-row igos-card">
      <div class="h24">Completed IGO’s</div>
      <a href="/#/view-all/completed-igos/" class="flex-row view">
        View All
        <i class="fa-solid fa-arrow-right" style="margin:5px 0 0 8px;" />
      </a>
    </div>
    <div class="igo-cards flex-row">
      <CompletedIGOs />
    </div>
  {/if}
  <!--Completed IGO’s Ends-->

  <!--FAQ Begins-->
  <a
    class="faq-card"
    href="/#/frequently-asked-questions"
  >
    <div>
      <div class="faq-h">FAQs</div>
      <div class="faq-i">
        All the community’s main questions are answered here.
      </div>
      <br />
      <div class="faq-b">
        <a href="/#/frequently-asked-questions"
          >See more</a
        >
      </div>
    </div>
  </a>
  <!--FAQ Ends-->
</div>

<style>
  .main-container {
    width: 100%;
    max-width: 1296px;
    margin: 72px 0;
    color: white;
  }
  @media(min-width:1200px) and (max-width:1400px){
    .main-container{
      width: 1150px;
      max-width: 1150px;
    }
  }
  header {
    font-size: 24px;
  }

  .img-card {
    border-radius: 12px;
    place-content: center;
    text-align: center;
    height: 360px;
    background-image: var(--igoBanner);
    background-size: cover;
    margin-top: 22px;
  }

  .count-card {
    width: fit-content;
    float: right;
    background: #210d42;
    color: white;
    padding: 8px;
    margin: 16px;
    border-radius: 8px;
    text-align: center;
  }

  .count-card .times {
    align-self: center;
    width: 100%;
  }

  .count-card .time {
    width: 100%;
    font-size: 20px;
    padding: 8px;
    background: rgba(99, 11, 241, 0.5);
    border-radius: 12px;
    margin: 4px;
  }

  .time div {
    font-size: 12px;
  }

  .details {
    width: 856px;
    align-self: center;
    margin: -108px 0;
  }

  .detail-card {
    align-self: center;
    background: rgba(33, 13, 66, 0.75);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 24px;
  }

  .details-card-h {
    justify-content: space-between;
    align-items: center;
  }

  .details-card-h span {
    font-size: 32px;
  }

  .details-card-h .details-card-img {
    width: 24px;
    height: 24px;
    margin: 0 8px;
  }

  .info-card {
    justify-content: space-between;
    margin: 8px 0 16px;
  }

  .info-card .status-bar {
    background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
    border-radius: 16px;
    color: white;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 8px 0 0;
    padding: 4px 8px;
  }

  .info-card .round-bar {
    background: #630bf14d;
    border-radius: 16px;
    color: #b8c5ec;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 4px 0 0;
    padding: 4px 8px;
  }

  .info-card .info {
    font-size: 12px;
    line-height: 1.25;
    color: #b8c5ec;
  }

  .contribution-button {
    background: rgba(184, 197, 236, 0.35);
    border-radius: 12px;
    padding: 0 20px 0 24px;
  }

  .allocation-button {
    background: rgba(184, 197, 236, 0.35);
    border-radius: 12px;
    padding: 0 12px 0 16px;
    align-items: center;
  }

  .allocation-button p {
    line-height: 22px;
    margin-right: 8px;
    color: white;
  }

  .contribution-button p {
    line-height: 22px;
    margin-right: 8px;
    color: #630bf1;
  }

  .info-card .flex-row .flex-col {
    margin: 8px 32px 0 0;
  }

  .inner,
  .outer {
    border-radius: 12px;
    height: 8px;
  }

  .outer {
    width: 100%;
    background: rgba(184, 197, 236, 0.2);
    border-radius: 12px;
    font-size: 14px;
    margin: 8px 0;
  }

  .inner {
    width: 0%;
    background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
  }

  .igo-buttons {
    justify-content: center;
  }

  .igo-buttons .stake-button {
    background: #630bf1;
    color: white;
    align-self: center;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 16px;
    padding: 12px;
    margin: 12px 12px 0;
    width: 33%;
    text-align: center;
    border: 1px solid #630bf1;
  }

  .stake-button:hover {
    background-color: #6329f1;
  }

  .igo-buttons .view-button {
    background-color: transparent;
    align-self: center;
    border: 1px solid #630bf1;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 16px;
    padding: 12px;
    margin: 12px 12px 0;
    width: 33%;
    text-align: center;
  }

  .view-button:hover {
    background-color: #630bf1;
  }

  .mobile-contribution {
    display: none;
  }

  .mobile-allocation {
    display: none;
  }

  .mobile-allocation {
    display: none;
  }

  .igo-cards {
    column-gap: 24px;
  }

  .igos-card {
    margin: 38px 0 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .view {
    color: #f10088;
  }

  .view:hover {
    color: #f2309e;
  }

  .faq-card {
    padding: 30px 0;
    margin: 32px 0 0;
    border-radius: 12px;
    background-image: url(/images/UI/faq-banner.jpg);
    background-size: cover;
  }

  .faq-i {
    color: white;
    position: relative;
    text-align: center;
    font-size: 20px;
  }

  .faq-b {
    text-align: center;
    margin: 12px 12px 24px;
  }

  .faq-b a {
    background: rgb(241, 0, 136);
    color: white;
    padding: 12px 60px;
    border-radius: 12px;
    font-size: 16px;
  }

  .faq-b a:hover {
    background-color: #f2309e;
  }

  .faq-h {
    color: white;
    position: relative;
    font-size: 104px;
    font-style: italic;
    letter-spacing: -1px;
    line-height: 1;
    text-align: center;
  }

  @media only screen and (max-width: 860px) {
    .main-container {
      width: 100%;
    }

    .details {
      width: 100%;
    }

    .faq-h {
      text-align: center;
      margin: auto;
    }
  }

  @media only screen and (max-width: 480px) {
    .mobile-w {
      width: 100%;
    }

    .main-container {
      width: 100%;
      margin: 42px 0 20px;
    }

    .count-card {
      text-align: center;
      margin: 20px;
    }

    .img-card {
      background-image: var(--igoBannerMobile);
    }

    .igos-card {
      margin: 20px 0 0;
    }

    .details {
      width: 100%;
    }

    .detail-card {
      padding: 12px;
      border-radius: 0 0 12px 12px;
    }

    .info-card {
      display: grid;
      width: 100% !important;
    }

    .info-card .flex-row .flex-col {
      margin: 16px 32px 0 0;
      font-size: 14px;
    }

    .faq-card {
      background-image: url(/images/UI/faq-mobil.jpg);
      background-size: cover;
      padding: 0;
      border-radius: 12px;
    }

    .faq-i {
      text-align: center;
    }

    .faq-h {
      text-align: center;
      font-size: 56px;
      margin: 32px 0 8px;
    }

    .igo-buttons {
      margin: 16px 0 0;
      align-items: center;
      flex-direction: column;
    }

    .allocation-button {
      display: none;
    }

    .contribution-button {
      display: none;
    }

    .mobile-allocation {
      display: flex;
      background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
      padding: 0 20px 0 24px;
      font-size: 14px;
      justify-content: center;
      border-radius: 12px;
    }

    .mobile-contribution {
      display: flex;
      background: #b8c5ec;
      padding: 0 20px 0 24px;
      align-items: center;
      font-size: 16px;
      justify-content: center;
      border-radius: 12px;
      margin: 20px 0 0;
    }

    .mobile-allocation p {
      line-height: 22px;
      margin-right: 8px;
      color: white;
    }

    .mobile-contribution p {
      line-height: 22px;
      margin-right: 8px;
      color: #630bf1;
    }

    .stake-button {
      width: 100% !important;
    }

    .view-button {
      width: 100% !important;
    }

    .faq-card {
      background-image: url(/images/UI/faq-mobil.jpg);
      background-size: cover;
      padding: 0;
    }

    .faq-i {
      text-align: center;
      padding: 0 12px;
    }

    .faq-h {
      text-align: center;
      font-size: 56px;
    }

    .non-outer {
      display: none;
    }
  }
</style>
