<script>
  import { onDestroy, onMount } from "svelte";
  import Router, { location, link } from "svelte-spa-router";
  import Navbar from "./Components/Navigation/Navbar/Navbar.svelte";
  import Footer from "./Components/Navigation/Footer/Footer.svelte";
  import Partnership from "./Pages/Partnership.svelte";
  import Back from "./Components/Navigation/Navbar/Back.svelte";
  import Launchpad from "./Pages/Launchpad.svelte";
  import KYC from "./Components/Widgets/KYC.svelte";
  import Details from "./Pages/Details.svelte";
  import Notification from "./Components/Notification/Notification.svelte";
  import FAQ from "./Pages/FAQ.svelte";
  import ViewAll from "./Pages/ViewAll.svelte";
  import HexProof from "./Pages/HexProof.svelte";
  import { beforeUpdate, afterUpdate } from "svelte";
  const routes = {
    "/": Launchpad,
    "/partnership": Partnership,
    "/frequently-asked-questions": FAQ,
    "/:selectedIGO": Details,
    "/view-all/:IGOType": ViewAll,
  };

  onMount(() => {
    screen.orientation.lock("portrait");
    let DEBUG = true;
    if (!DEBUG) {
      console.log = function () {};
    }
  });

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("js", new Date());

  gtag("config", "G-0F0P8XWKM7");
</script>

<Navbar />
{#if $location !== "/"}
  <Back } />
{/if}
<main>
  <div class="orientation">
    <img src="/svg/logo.svg" />
    <h3>Please use your device on portrait mode for best experience.</h3>
  </div>
  <div class="container">
    <div class="w-100">
      <Router {routes} />
      <Notification />
    </div>
  </div>
</main>
<KYC />
<Footer />
<HexProof />

<style>
  .w-100 {
    width: 100%;
    display: contents;
  }
  main {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    font-family: "Nexa-Bold", serif;
  }

  .orientation {
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--background-level-1);
    display: none;
  }

  @media (min-width: 950px) {
    .orientation {
      display: none !important;
    }
  }

  .orientation h3 {
    color: white;
    margin-top: 30px;
  }

  @media (orientation: landscape) {
    body {
      overflow: hidden;
    }
    .orientation {
      display: flex;
    }
  }

  .container {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    gap: 24px;
    box-sizing: border-box;
  }

  @media only screen and (max-width: 860px) {
    .container {
      padding: 24px;
      gap: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    .container {
      padding: 12px;
      flex-direction: column;
    }
  }
</style>
