<script>
  import { notification } from "../../Stores/Notification";
  import { slide } from "svelte/transition";

  $: visible = $notification.visible;
  $: visible
    ? setTimeout(() => {
        visible = false;
      }, 3000)
    : "";
</script>

{#if visible}
  <main transition:slide={{ duration: 200 }} class="flex-row">
    <i class={$notification.icon} />
    <div class="h14">{$notification.title}</div>
    <div class="p14">{$notification.text}</div>
  </main>
{/if}

<style>
  main {
    position: fixed;
    bottom: 60px;
    left: 50%;
    background-color: var(--primary-primary);
    padding: 16px;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    transform: translateX(-50%);
    z-index: 81;
  }

  .h14 {
    color: var(--light-white);
    text-align: center;
  }

  .p14 {
    color: var(--light-primary);
    text-align: center;
  }

  i {
    color: var(--light-white);
  }

  @media only screen and (max-width: 520px) {
    main {
      flex-direction: column;
      gap: 12px;
    }
  }
</style>
