<script>
  import Divider from "./Divider.svelte";

  export let height = "44px";
  export let icon = "";
  export let align = "right";
  export let color = "var(--secondary-primary)";
  export let width = "100%";
  export let opacity = "1";
  export let border = "none";
  export let textcolor = "var(--light-white)";
</script>

<button
  class="h16"
  style="--height:{height};
                           --color:{color};
                           --width:{width};
                           --opacity:{opacity};
                           --border:{border};
                           --textcolor:{textcolor}"
>
  {#if align === "right"}
    <div class="h16">
      <slot />
    </div>
    <Divider width="8px" />
    <i class={icon} />
  {:else}
    <i class={icon} />
    <Divider width="8px" />
    <div class="h16">
      <slot />
    </div>
  {/if}
</button>

<style>
  button {
    background-color: var(--color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    border-radius: 12px;
    height: var(--height);
    width: var(--width);
    opacity: var(--opacity);
    border: var(--border);
  }

  i,
  .h16 {
    color: var(--textcolor);
  }

  button:hover {
    background-color: #f2309e;
  }
</style>
