<script>
  import {
    userConnected,
    userAddress,
    connectWallet,
    disconnectCached,
    spinAmount,
    networkProvider,
  } from "../../../Stores/Network";
  import { fly } from "svelte/transition";
  import { spinABI } from "../../../Stores/ABI";

  import { ethers } from "ethers";

  let open = false;
  let spinAddr = "0x6AA217312960A21aDbde1478DC8cBCf828110A67";
  let spinPrice = 0;

  const toggleMenu = () => {
    open = !open;
    if (open) {
      console.log("qwe");
      document.querySelector("body").classList.add("open-menu");
    } else {
      document.querySelector("body").classList.remove("open-menu");
    }
  };

  const getSpinAmount = async () => {
    const spinContract = new ethers.Contract(
      spinAddr,
      spinABI,
      $networkProvider
    );
    const SPINs = parseFloat(
      ethers.utils.formatEther(await spinContract.balanceOf($userAddress))
    ).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    spinAmount.set(SPINs);
  };

  const fetchData = async () => {
    await fetch("https://api.spintop.network/getData")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed!");
        }
        return res.json();
      })
      .then((data) => {
        const price = data.spinPrice;
        spinPrice = parseFloat(price).toLocaleString("en", {
          maximumFractionDigits: 4,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setInterval(async () => {
    await getSpinAmount();
    await fetchData();
  }, 2500);
</script>

<header>
  <div
    class="mobile-menu d-flex justify-content-between d-md-none align-items-center"
  >
    <div class="d-flex align-items-center">
      <span class="svg-icon icons-menu" on:click={toggleMenu} />
      <a class="logo" href="/"><img src="/svg/logo.svg" /></a>
    </div>
    <div class="d-flex blurry align-items-center">
      {#if $userConnected}
        <div class="wlt">
          <span class="svg-icon icons-wallet" />{$userAddress.substring(0, 6) +
            "..." +
            $userAddress.substring($userAddress.length - 4)}
        </div>
        <span class="log"
          ><span
            class="svg-icon icons-login"
            on:click={disconnectCached}
          /></span
        >
      {:else}
        <a
          href="https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67"
          target="_blank"
          class="bspin">Buy SPIN</a
        >
        <a on:click={connectWallet}>Connect</a>
      {/if}
    </div>
  </div>
  {#if open}
    <div
      class="mobile-menu-open d-flex flex-column align-items-center justify-content-start"
      transition:fly={{ y: 200, duration: 200 }}
    >
      <div class="close-icon" on:click={toggleMenu}>
        <span class="svg-icon icons-close-circle" />
      </div>
      <ul>
        <li>
          <a href="https://spintop.network/">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 48 48"
              overflow="visible"
              xml:space="preserve"
            >
              <g>
                <g id="Layer_x0020_1">
                  <g id="_1745604314000">
                    <path
                      fill-rule="evenodd"
                      fill="#FFFFFF"
                      d="M11.68,28.87c2.01,5.74,7.48,9.85,13.91,9.85c2.63,0,5.12-0.69,7.27-1.91
                    c-2.58-0.37-5.41-1.03-8.39-2C19.66,33.26,15.22,31.15,11.68,28.87z"
                    />
                    <path
                      fill-rule="evenodd"
                      fill="#FFFFFF"
                      d="M40.32,24.01c0,0.44-0.02,0.87-0.06,1.31v0.01l0,0l-0.01,0.11l-0.01,0.04v0.02v0.07
                    l-0.01,0.05v0.01l-0.01,0.12v0.01L40.2,25.8v0.07l-0.01,0.02v0.04l-0.01,0.06v0.06l0,0l-0.01,0.01l-0.01,0.11v0.02l-0.01,0.05
                    l-0.01,0.08v0.01v0.02l-0.01,0.06v0.01l-0.02,0.11l-0.01,0.06l-0.01,0.06l-0.01,0.06v0.05v0.01l-0.01,0.06l-0.01,0.01l-0.01,0.11
                    l-0.01,0.01c-0.02,0.13-0.05,0.27-0.08,0.41l-0.01,0.06l-0.01,0.02l-0.01,0.04l-0.01,0.06c-0.13,0.52-0.28,1.04-0.47,1.55l0,0
                    c-0.45,1.22-1.05,2.36-1.8,3.4l-0.13,0.01c-3.83,0-7.95-0.96-11.58-2.13c-4.7-1.52-9.61-3.78-13.59-6.73
                    c-0.4-0.3-0.9-0.69-1.39-1.11c0.15-1.51,0.53-2.97,1.1-4.3l0,0l0.01-0.01c0.08-0.2,0.17-0.4,0.26-0.59l0.01-0.01v-0.01
                    c0.05-0.09,0.09-0.19,0.14-0.28v-0.01l0.01-0.01c0.05-0.08,0.09-0.18,0.14-0.27l0.02-0.02c0.04-0.08,0.08-0.17,0.13-0.25
                    l0.04-0.06c0.05-0.08,0.09-0.17,0.14-0.24l0.02-0.05c0.05-0.08,0.09-0.17,0.14-0.25l0.04-0.04c0.05-0.07,0.09-0.15,0.14-0.24
                    l0.04-0.05c0.05-0.07,0.09-0.14,0.15-0.21l0.04-0.07c0.05-0.07,0.11-0.13,0.15-0.2l0.04-0.06c0.06-0.07,0.11-0.14,0.17-0.21
                    l0.05-0.06c2.68-3.51,6.92-5.77,11.69-5.77C33.72,9.27,40.32,15.87,40.32,24.01z M45.64,10.68c-0.61,0-1.11-0.5-1.11-1.11
                    c0,0.61-0.5,1.11-1.11,1.11c0.61,0,1.11,0.5,1.11,1.11C44.53,11.18,45.02,10.68,45.64,10.68z M39.53,10.33
                    c-0.61,0-1.1-0.5-1.1-1.11c0,0.61-0.5,1.11-1.11,1.11c0.61,0,1.11,0.5,1.11,1.1C38.43,10.83,38.91,10.33,39.53,10.33z
                    M44.96,15.08c-0.83,0-1.5-0.67-1.5-1.5c0,0.83-0.67,1.5-1.5,1.5c0.83,0,1.5,0.66,1.5,1.49C43.46,15.74,44.14,15.08,44.96,15.08z
                    "
                    />
                    <path
                      fill-rule="evenodd"
                      fill="#1CDCC7"
                      d="M13.94,14.99c-6.32-0.53-10.57,0.93-10.57,4.19c0,4.86,9.45,11.85,21.1,15.63
                    c11.66,3.78,21.11,2.92,21.11-1.94c0-2.24-2.01-4.94-5.32-7.56c-0.12,1.31-0.41,2.59-0.85,3.78c0.9,0.83,1.83,1.78,2.35,2.63
                    c0.18,0.3,0.2,0.63,0.08,0.96c-0.13,0.32-0.38,0.54-0.72,0.64c-1.21,0.34-3,0.44-3.64,0.44c-3.92,0.01-8.25-0.98-11.97-2.19
                    c-4.8-1.56-9.88-3.9-13.94-6.91c-0.91-0.67-3.4-2.73-4.4-4.35C7,20.02,6.97,19.69,7.1,19.36c0.13-0.32,0.38-0.54,0.71-0.64
                    c1.22-0.33,3.01-0.44,3.64-0.44c0.19,0,0.37,0,0.56,0.01C12.51,17.11,13.16,16,13.94,14.99z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            Network</a
          >
        </li>
        <li>
          <a href="https://spintop.network/gamepedia">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 48 48"
              overflow="visible"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    fill="none"
                    d="M43.41,7.68l-18.9,1.5l1.3,2.21l2.38-1.38c1.25-0.72,2.86-0.31,3.59,0.95l7.91,13.62
                    c0.72,1.25,0.3,2.86-0.95,3.59l-13.62,7.91c-2.7,1.57-1.1,3.33-1.82,2.09l-0.99-1.71l-2.31,1.34c-1.91,1.11-1.67,2.31-1.62,2.49
                    c0-0.01-0.01-0.03-0.02-0.06l0.19,2.34L46,40.38L43.41,7.68z M41.85,30.91l-15.89,9.22c-0.63,0.36-1.43,0.15-1.79-0.48
                    c-0.36-0.63-0.15-1.43,0.48-1.79l15.89-9.22c0.63-0.36,1.43-0.15,1.79,0.48C42.69,29.74,42.47,30.54,41.85,30.91z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M25.12,36.07l13.62-7.91c1.25-0.72,1.68-2.34,0.95-3.59l-7.91-13.62c-0.73-1.26-2.34-1.68-3.59-0.95
                    l-2.38,1.38l1.33,2.26l3.51-2.04l3.95,6.81l-3.83-0.05l-0.42,0.76l4.2,7.17c0.72,1.25,0.3,2.86-0.95,3.59l-11.3,6.56l0.99,1.71
                    C24.02,39.4,22.42,37.64,25.12,36.07z"
                  />
                  <polygon
                    class="filler"
                    fill="#1CDCC7"
                    points="34.6,18.43 30.65,11.62 27.14,13.65 30.35,19.13 30.77,18.37 		"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M40.53,28.64l-15.89,9.22c-0.63,0.36-0.84,1.17-0.48,1.79c0.36,0.63,1.17,0.84,1.79,0.48l15.89-9.22
                    c0.63-0.36,0.84-1.17,0.48-1.79C41.96,28.49,41.16,28.27,40.53,28.64z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M5.21,16.02c-1.26,0.73-1.68,2.33-0.95,3.59l9.67,16.65c0.72,1.25-0.88-0.52,1.82-2.09l13.62-7.91
                    c1.25-0.72,1.68-2.34,0.95-3.59L22.42,9.07c-0.73-1.26-2.34-1.68-3.59-0.95L5.21,16.02z"
                  />
                  <polygon
                    class="filler"
                    fill="#1CDCC7"
                    points="15.61,13.02 21.28,9.73 25.24,16.54 21.41,16.48 19.56,19.83 		"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M15.28,35.97c-0.63,0.36-0.84,1.17-0.48,1.79s1.17,0.84,1.79,0.48l15.89-9.22
                    c0.63-0.36,0.84-1.17,0.48-1.79c-0.36-0.63-1.17-0.84-1.79-0.48L15.28,35.97z"
                  />
                </g>
              </g>
            </svg>
            Gamepedia</a
          >
        </li>
        <li>
          <a href="https://staking.spintop.network/">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 48 48"
              overflow="visible"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M39.44,35.93c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07S15.2,42,23.82,42
                C32.43,42,39.44,39.28,39.44,35.93z"
                    />
                    <rect
                      x="8.2"
                      y="32.56"
                      fill="#1CDCC7"
                      width="31.22"
                      height="3.37"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M39.44,32.9c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C32.43,38.97,39.44,36.24,39.44,32.9z"
                    />
                  </g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M26.41,31.11l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C31.86,32.19,29.63,31.55,26.41,31.11z M24.08,31.53l1.85-2.27
                c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.46,31.61,24.22,31.57,24.08,31.53z"
                    />
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M16.55,32.65c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62C24.02,34.61,23,34.4,23,34.4
                c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                C29.84,33.57,23.99,33.95,16.55,32.65z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M41.94,29.86c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C34.93,35.93,41.94,33.21,41.94,29.86z"
                    />
                    <rect
                      class="filler"
                      x="10.69"
                      y="26.49"
                      fill="#1CDCC7"
                      width="31.22"
                      height="3.37"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M41.94,26.83c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C34.93,32.9,41.94,30.17,41.94,26.83z"
                    />
                  </g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M28.91,25.04l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C34.36,26.12,32.13,25.48,28.91,25.04z M26.58,25.46l1.85-2.27
                c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C26.96,25.55,26.72,25.5,26.58,25.46z"
                    />
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M19.05,26.58c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                C32.33,27.5,26.48,27.88,19.05,26.58z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M39.66,24.32c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C32.65,30.39,39.66,27.66,39.66,24.32z"
                    />
                    <rect
                      x="8.42"
                      y="20.95"
                      fill="#1CDCC7"
                      width="31.22"
                      height="3.37"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M39.66,21.28c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C32.65,27.35,39.66,24.63,39.66,21.28z"
                    />
                  </g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M26.63,19.49l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C32.08,20.57,29.85,19.94,26.63,19.49z M24.3,19.92l1.85-2.27
                c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.68,20,24.44,19.96,24.3,19.92z"
                    />
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M16.77,21.04c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                C30.05,21.95,24.21,22.34,16.77,21.04z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M39.75,18.1c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C32.74,24.17,39.75,21.45,39.75,18.1z"
                    />
                    <rect
                      class="filler"
                      x="8.51"
                      y="14.73"
                      fill="#1CDCC7"
                      width="31.22"
                      height="3.37"
                    />
                    <path
                      fill="#FFFFFF"
                      d="M39.75,15.07c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                C32.74,21.14,39.75,18.41,39.75,15.07z"
                    />
                  </g>
                  <g>
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M26.72,13.28l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C32.17,14.36,29.94,13.72,26.72,13.28z M24.4,13.7l1.85-2.27
                c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.77,13.79,24.53,13.74,24.4,13.7z"
                    />
                    <path
                      class="filler"
                      fill="#1CDCC7"
                      d="M16.86,14.82c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                C30.15,15.74,24.3,16.12,16.86,14.82z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            Staking</a
          >
        </li>
        <li>
          <a href="/">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 48 48"
              overflow="visible"
              xml:space="preserve"
            >
              <path
                id="_x33__2_"
                fill="#FFFFFF"
                d="M31.86,34.68l-2.14-1.01c-0.44,0.73-0.93,1.56-1.5,2.49c-0.92-1.01-1.16-2.61-1.06-4.3
              l4.33-9.22c3.68-7.83-0.45-12.92-0.45-12.92l-0.36-0.17c0,0-6.55,0.07-10.23,7.9l-4.56,9.72c-1.12,0.94-2.34,1.58-3.54,1.53
              c0.35-1.03,0.69-1.94,0.97-2.75l-2.14-1.01c-1.36,2.1-2.72,4.77-3.49,7.23c0.14,0.06,2.35,1.1,2.35,1.1s1.24-1.79,1.35-2.37
              c1.2,0.39,4.05,1.24,5.61,1.78l-0.87,1.84l4.98,2.34l0.88-1.87c1.37,0.82,4.04,2.6,5.16,3.32c-0.38,0.45-0.97,2.55-0.97,2.55
              s2.22,1.04,2.35,1.1C29.92,39.81,31.11,37.06,31.86,34.68z M24.47,23.22c-1.86-0.88-2.67-3.1-1.79-4.97
              c0.88-1.87,3.1-2.67,4.97-1.79c1.87,0.88,2.67,3.1,1.8,4.97C28.56,23.3,26.34,24.1,24.47,23.22z"
              />
              <path
                class="filler"
                fill="#1CDCC7"
                d="M14.11,21.43c-0.62,0.43-1.44,1.35-2.29,2.56l1.86,0.87C14.13,23.45,14.34,22.38,14.11,21.43z"
              />
              <path
                class="filler"
                fill="#1CDCC7"
                d="M32.69,30.16c-0.88,0.43-1.57,1.28-2.36,2.52l1.86,0.87C32.57,32.12,32.75,30.91,32.69,30.16z"
              />
              <path
                class="filler"
                fill="#1CDCC7"
                d="M27.65,16.46c-1.87-0.88-4.1-0.07-4.97,1.79c-0.88,1.87-0.07,4.1,1.79,4.97c1.87,0.88,4.1,0.07,4.97-1.79
              C30.32,19.57,29.51,17.33,27.65,16.46z"
              />
              <rect
                x="15.47"
                y="37.67"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.4748 37.95)"
                fill="#FFFFFF"
                width="4.34"
                height="1.16"
              />
              <rect
                x="17.95"
                y="37.92"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -23.6132 39.8207)"
                fill="#FFFFFF"
                width="3.18"
                height="1.16"
              />
              <rect
                x="14.64"
                y="36.37"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.1091 35.9231)"
                fill="#FFFFFF"
                width="3.18"
                height="1.16"
              />
              <rect
                class="filler"
                x="15.47"
                y="37.67"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.4748 37.95)"
                fill="#1CDCC7"
                width="4.34"
                height="1.16"
              />
              <rect
                class="filler"
                x="17.95"
                y="37.92"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -23.6132 39.8207)"
                fill="#1CDCC7"
                width="3.18"
                height="1.16"
              />
              <rect
                class="filler"
                x="14.64"
                y="36.37"
                transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.1091 35.9231)"
                fill="#1CDCC7"
                width="3.18"
                height="1.16"
              />
            </svg>
            Launchpad</a
          >
        </li>
      
      </ul>
      <div class="sub-outer">
        <div
          class="d-flex justify-content-between sub-bottom align-items-center"
        >
          <a class="logo" href="/"><img src="/svg/logo.svg" /></a>
          <div>
            <h5>Join Our Community</h5>
            <p class="font-tertiary-primary">We will keep you posted!</p>
          </div>
        </div>
        <div class="d-flex socials justify-content-between sub-bottom">
          <a href="https://t.me/SpintopNetwork" target="_blank"
            ><span class="svg-icon icons-telegram" /></a
          >
          <a href="https://spintopnetwork.medium.com/" target="_blank"
            ><span class="svg-icon icons-medium" /></a
          >
          <a
            href="https://discord.com/invite/E7Mt6m7hw5?utm_source=Discord&utm_medium=Join&utm_campaign=Spintop"
            target="_blank"><span class="svg-icon icons-discord" /></a
          >
          <a href="https://twitter.com/SpintopNetwork" target="_blank"
            ><span class="svg-icon icons-twitter" /></a
          >
          <a href="https://www.youtube.com/c/SpintopNetwork" target="_blank"
            ><span class="svg-icon icons-youtube" /></a
          >
        </div>
      </div>
    </div>
  {/if}
  <!-- <div class="sub-flex d-flex d-md-none align-items-center">
    <a class="active" href="https://launchpad.spintop.network/"
            ><span class="svg-icon icons-tractor" /> Home</a
          >
          <a href="https://launchpad.spintop.network/#/partnership"
            ><span class="svg-icon icons-water-ladder" /> Apply For IGO</a
          >
          <a href="https://staking.spintop.network/#/vault"
            ><span class="svg-icon icons-rocket" /> Vault</a
          >
  </div> -->
  <div class="w-100 d-none d-md-flex justify-content-center ">
    <div class="container fixed-connect d-flex justify-content-end">
      <div class="d-flex blurry align-items-center">
        {#if $userConnected}
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67&chainId=56"
            target="_blank"
            class="bspin">Buy SPIN</a
          >
          <div class="d-flex spn">
            <img src="/svg/logo.svg" />
            <p>{spinPrice === spinPrice ? spinPrice : "-"}</p>
          </div>
          <div class="wlt">
            <span class="svg-icon icons-wallet" />{$userAddress.substring(
              0,
              6
            ) +
              "..." +
              $userAddress.substring($userAddress.length - 4)}
          </div>
          <span class="log"
            ><span
              class="svg-icon icons-login"
              on:click={disconnectCached}
            /></span
          >
        {:else}
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67&chainId=56"
            target="_blank"
            class="bspin">Buy SPIN</a
          >
          <a on:click={connectWallet}>Connect Your Wallet</a>
        {/if}
      </div>
    </div>
  </div>
  <div class="sidebar d-none d-md-block">
    <a class="logo" href="/"><img src="/svg/logo-text.svg" /></a>
    <ul>
      <li>
        <a href="https://spintop.network/">
          <svg
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            overflow="visible"
            xml:space="preserve"
          >
            <g>
              <g id="Layer_x0020_1">
                <g id="_1745604314000">
                  <path
                    fill-rule="evenodd"
                    fill="#FFFFFF"
                    d="M11.68,28.87c2.01,5.74,7.48,9.85,13.91,9.85c2.63,0,5.12-0.69,7.27-1.91
                    c-2.58-0.37-5.41-1.03-8.39-2C19.66,33.26,15.22,31.15,11.68,28.87z"
                  />
                  <path
                    fill-rule="evenodd"
                    fill="#FFFFFF"
                    d="M40.32,24.01c0,0.44-0.02,0.87-0.06,1.31v0.01l0,0l-0.01,0.11l-0.01,0.04v0.02v0.07
                    l-0.01,0.05v0.01l-0.01,0.12v0.01L40.2,25.8v0.07l-0.01,0.02v0.04l-0.01,0.06v0.06l0,0l-0.01,0.01l-0.01,0.11v0.02l-0.01,0.05
                    l-0.01,0.08v0.01v0.02l-0.01,0.06v0.01l-0.02,0.11l-0.01,0.06l-0.01,0.06l-0.01,0.06v0.05v0.01l-0.01,0.06l-0.01,0.01l-0.01,0.11
                    l-0.01,0.01c-0.02,0.13-0.05,0.27-0.08,0.41l-0.01,0.06l-0.01,0.02l-0.01,0.04l-0.01,0.06c-0.13,0.52-0.28,1.04-0.47,1.55l0,0
                    c-0.45,1.22-1.05,2.36-1.8,3.4l-0.13,0.01c-3.83,0-7.95-0.96-11.58-2.13c-4.7-1.52-9.61-3.78-13.59-6.73
                    c-0.4-0.3-0.9-0.69-1.39-1.11c0.15-1.51,0.53-2.97,1.1-4.3l0,0l0.01-0.01c0.08-0.2,0.17-0.4,0.26-0.59l0.01-0.01v-0.01
                    c0.05-0.09,0.09-0.19,0.14-0.28v-0.01l0.01-0.01c0.05-0.08,0.09-0.18,0.14-0.27l0.02-0.02c0.04-0.08,0.08-0.17,0.13-0.25
                    l0.04-0.06c0.05-0.08,0.09-0.17,0.14-0.24l0.02-0.05c0.05-0.08,0.09-0.17,0.14-0.25l0.04-0.04c0.05-0.07,0.09-0.15,0.14-0.24
                    l0.04-0.05c0.05-0.07,0.09-0.14,0.15-0.21l0.04-0.07c0.05-0.07,0.11-0.13,0.15-0.2l0.04-0.06c0.06-0.07,0.11-0.14,0.17-0.21
                    l0.05-0.06c2.68-3.51,6.92-5.77,11.69-5.77C33.72,9.27,40.32,15.87,40.32,24.01z M45.64,10.68c-0.61,0-1.11-0.5-1.11-1.11
                    c0,0.61-0.5,1.11-1.11,1.11c0.61,0,1.11,0.5,1.11,1.11C44.53,11.18,45.02,10.68,45.64,10.68z M39.53,10.33
                    c-0.61,0-1.1-0.5-1.1-1.11c0,0.61-0.5,1.11-1.11,1.11c0.61,0,1.11,0.5,1.11,1.1C38.43,10.83,38.91,10.33,39.53,10.33z
                    M44.96,15.08c-0.83,0-1.5-0.67-1.5-1.5c0,0.83-0.67,1.5-1.5,1.5c0.83,0,1.5,0.66,1.5,1.49C43.46,15.74,44.14,15.08,44.96,15.08z
                    "
                  />
                  <path
                    fill-rule="evenodd"
                    fill="#1CDCC7"
                    d="M13.94,14.99c-6.32-0.53-10.57,0.93-10.57,4.19c0,4.86,9.45,11.85,21.1,15.63
                    c11.66,3.78,21.11,2.92,21.11-1.94c0-2.24-2.01-4.94-5.32-7.56c-0.12,1.31-0.41,2.59-0.85,3.78c0.9,0.83,1.83,1.78,2.35,2.63
                    c0.18,0.3,0.2,0.63,0.08,0.96c-0.13,0.32-0.38,0.54-0.72,0.64c-1.21,0.34-3,0.44-3.64,0.44c-3.92,0.01-8.25-0.98-11.97-2.19
                    c-4.8-1.56-9.88-3.9-13.94-6.91c-0.91-0.67-3.4-2.73-4.4-4.35C7,20.02,6.97,19.69,7.1,19.36c0.13-0.32,0.38-0.54,0.71-0.64
                    c1.22-0.33,3.01-0.44,3.64-0.44c0.19,0,0.37,0,0.56,0.01C12.51,17.11,13.16,16,13.94,14.99z"
                  />
                </g>
              </g>
            </g>
          </svg>
          Network</a
        >
        <div class="sub">
          <a href="https://spintop.network#what-is-spintop"
            ><span class="svg-icon icons-faq" /> What's Spintop?</a
          >
          <a href="https://spintop.network#roadmap"
            ><span class="svg-icon icons-target" /> Roadmap</a
          >
          <a href="https://spintop.network#team"
            ><span class="svg-icon icons-userss" /> Team</a
          >
          <a href="https://docs.spintop.network/" target="_blank"
            ><span class="svg-icon icons-book" /> Whitepaper</a
          >
        </div>
      </li>
      <li>
        <a href="https://spintop.network/gamepedia">
          <svg
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            overflow="visible"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  fill="none"
                  d="M43.41,7.68l-18.9,1.5l1.3,2.21l2.38-1.38c1.25-0.72,2.86-0.31,3.59,0.95l7.91,13.62
                  c0.72,1.25,0.3,2.86-0.95,3.59l-13.62,7.91c-2.7,1.57-1.1,3.33-1.82,2.09l-0.99-1.71l-2.31,1.34c-1.91,1.11-1.67,2.31-1.62,2.49
                  c0-0.01-0.01-0.03-0.02-0.06l0.19,2.34L46,40.38L43.41,7.68z M41.85,30.91l-15.89,9.22c-0.63,0.36-1.43,0.15-1.79-0.48
                  c-0.36-0.63-0.15-1.43,0.48-1.79l15.89-9.22c0.63-0.36,1.43-0.15,1.79,0.48C42.69,29.74,42.47,30.54,41.85,30.91z"
                />
                <path
                  fill="#FFFFFF"
                  d="M25.12,36.07l13.62-7.91c1.25-0.72,1.68-2.34,0.95-3.59l-7.91-13.62c-0.73-1.26-2.34-1.68-3.59-0.95
                  l-2.38,1.38l1.33,2.26l3.51-2.04l3.95,6.81l-3.83-0.05l-0.42,0.76l4.2,7.17c0.72,1.25,0.3,2.86-0.95,3.59l-11.3,6.56l0.99,1.71
                  C24.02,39.4,22.42,37.64,25.12,36.07z"
                />
                <polygon
                  class="filler"
                  fill="#1CDCC7"
                  points="34.6,18.43 30.65,11.62 27.14,13.65 30.35,19.13 30.77,18.37 		"
                />
                <path
                  fill="#FFFFFF"
                  d="M40.53,28.64l-15.89,9.22c-0.63,0.36-0.84,1.17-0.48,1.79c0.36,0.63,1.17,0.84,1.79,0.48l15.89-9.22
                  c0.63-0.36,0.84-1.17,0.48-1.79C41.96,28.49,41.16,28.27,40.53,28.64z"
                />
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M5.21,16.02c-1.26,0.73-1.68,2.33-0.95,3.59l9.67,16.65c0.72,1.25-0.88-0.52,1.82-2.09l13.62-7.91
                  c1.25-0.72,1.68-2.34,0.95-3.59L22.42,9.07c-0.73-1.26-2.34-1.68-3.59-0.95L5.21,16.02z"
                />
                <polygon
                  class="filler"
                  fill="#1CDCC7"
                  points="15.61,13.02 21.28,9.73 25.24,16.54 21.41,16.48 19.56,19.83 		"
                />
                <path
                  fill="#FFFFFF"
                  d="M15.28,35.97c-0.63,0.36-0.84,1.17-0.48,1.79s1.17,0.84,1.79,0.48l15.89-9.22
                  c0.63-0.36,0.84-1.17,0.48-1.79c-0.36-0.63-1.17-0.84-1.79-0.48L15.28,35.97z"
                />
              </g>
            </g>
          </svg>
          Gamepedia</a
        >
        <div class="sub">
        
          <a href="https://spintop.network/gamepedia"
          ><span class="svg-icon icons-homenav" /> Home</a
        >
          <a href="https://spintop.network/gamepedia/games"
            ><span class="svg-icon icons-browse" /> Browse</a
          >
          <a href="https://spintop.network/gamepedia/news"
            ><span class="svg-icon icons-news" /> News</a
          >
          <a href="https://spintop.network/gamepedia/academy"
            ><span class="svg-icon icons-academy" /> Academy</a
          >
          <a href="https://spintop.network/gamepedia?q=watchlist"
            ><span class="svg-icon icons-starr" /> Watchlist</a
          >
          <a href="https://spintop.network/gamepedia?q=search"
            ><span class="svg-icon icons-search" /> Search</a
          >
          <hr />
          <a href="https://spintop.network/gamepedia?q=profile" class=""
          ><span class="svg-icon icons-user" /> Profile</a
        >
        <a
          href="https://spintop.network/gamepedia?q=notifications"
          class="notifside"
          ><span class="svg-icon icons-bell" /> Notifications</a
        >
    
        </div>
      </li>
      <li>
        <a href="https://staking.spintop.network/">
          <svg
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            overflow="visible"
            xml:space="preserve"
          >
            <g>
              <g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M39.44,35.93c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07S15.2,42,23.82,42
                    C32.43,42,39.44,39.28,39.44,35.93z"
                  />
                  <rect
                    x="8.2"
                    y="32.56"
                    fill="#1CDCC7"
                    width="31.22"
                    height="3.37"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M39.44,32.9c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C32.43,38.97,39.44,36.24,39.44,32.9z"
                  />
                </g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M26.41,31.11l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                    c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                    c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C31.86,32.19,29.63,31.55,26.41,31.11z M24.08,31.53l1.85-2.27
                    c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.46,31.61,24.22,31.57,24.08,31.53z"
                  />
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M16.55,32.65c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                    c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62C24.02,34.61,23,34.4,23,34.4
                    c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                    C29.84,33.57,23.99,33.95,16.55,32.65z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M41.94,29.86c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C34.93,35.93,41.94,33.21,41.94,29.86z"
                  />
                  <rect
                    class="filler"
                    x="10.69"
                    y="26.49"
                    fill="#1CDCC7"
                    width="31.22"
                    height="3.37"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M41.94,26.83c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C34.93,32.9,41.94,30.17,41.94,26.83z"
                  />
                </g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M28.91,25.04l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                    c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                    c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C34.36,26.12,32.13,25.48,28.91,25.04z M26.58,25.46l1.85-2.27
                    c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C26.96,25.55,26.72,25.5,26.58,25.46z"
                  />
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M19.05,26.58c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                    c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                    c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                    C32.33,27.5,26.48,27.88,19.05,26.58z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M39.66,24.32c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C32.65,30.39,39.66,27.66,39.66,24.32z"
                  />
                  <rect
                    x="8.42"
                    y="20.95"
                    fill="#1CDCC7"
                    width="31.22"
                    height="3.37"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M39.66,21.28c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C32.65,27.35,39.66,24.63,39.66,21.28z"
                  />
                </g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M26.63,19.49l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                    c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                    c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C32.08,20.57,29.85,19.94,26.63,19.49z M24.3,19.92l1.85-2.27
                    c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.68,20,24.44,19.96,24.3,19.92z"
                  />
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M16.77,21.04c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                    c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                    c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                    C30.05,21.95,24.21,22.34,16.77,21.04z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M39.75,18.1c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C32.74,24.17,39.75,21.45,39.75,18.1z"
                  />
                  <rect
                    class="filler"
                    x="8.51"
                    y="14.73"
                    fill="#1CDCC7"
                    width="31.22"
                    height="3.37"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M39.75,15.07c0-3.35-7.01-6.07-15.62-6.07c-8.61,0-15.62,2.72-15.62,6.07s7.01,6.07,15.62,6.07
                    C32.74,21.14,39.75,18.41,39.75,15.07z"
                  />
                </g>
                <g>
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M26.72,13.28l0.94-1.7c0.13-0.22-0.2-0.44-0.74-0.49c-0.51-0.07-1.07,0.06-1.24,0.28l-1.35,1.65
                    c-1.11-0.1-2.19-0.16-3.11-0.18c0.58-0.18,1.42-0.33,2.16-0.33c-3.61-0.09-5.2,0.39-5.59,0.61c-0.07,0.03-0.14,0.08-0.17,0.13
                    c-0.33,0.5,2.59,1.26,6.54,1.69c3.94,0.43,7.4,0.38,7.73-0.13C32.17,14.36,29.94,13.72,26.72,13.28z M24.4,13.7l1.85-2.27
                    c0.06-0.08,0.27-0.14,0.47-0.12l-1.64,2.5C24.77,13.79,24.53,13.74,24.4,13.7z"
                  />
                  <path
                    class="filler"
                    fill="#1CDCC7"
                    d="M16.86,14.82c-0.25-0.04-0.45-0.08-0.66-0.12c0.63,0.3,1.61,0.6,2.59,0.85c1.07,0.57,2.42,1.3,3.33,1.83
                    c0.06,0.05,0.18,0.08,0.31,0.09c0.13,0.01,0.27,0.01,0.4-0.01c0.94-0.18,2.15-0.4,3.33-0.62c-1.83-0.07-2.85-0.27-2.85-0.27
                    c2.17,0.11,4.09,0,5.74-0.24c0.24-0.03,0.47-0.08,0.66-0.11c0.6-0.1,1.09-0.28,1.4-0.51c0.24-0.18,0.52-0.4,0.75-0.58
                    C30.15,15.74,24.3,16.12,16.86,14.82z"
                  />
                </g>
              </g>
            </g>
          </svg>
          Staking</a
        >
        <div class="sub">
          <a href="https://staking.spintop.network/"
            ><span class="svg-icon icons-homenav" /> Home</a
          >
          <a href="https://staking.spintop.network/#/farms"
            ><span class="svg-icon icons-tractor" /> Farms</a
          >
          <a href="https://staking.spintop.network/#/pools"
            ><span class="svg-icon icons-water-ladder" /> Pools</a
          >
          <a href="https://staking.spintop.network/#/vault"
            ><span class="svg-icon icons-rocket" /> Vault</a
          >

        </div>
      </li>
      <li class="active">
        <a href="/">
          <svg
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            overflow="visible"
            xml:space="preserve"
          >
            <path
              id="_x33__2_"
              fill="#FFFFFF"
              d="M31.86,34.68l-2.14-1.01c-0.44,0.73-0.93,1.56-1.5,2.49c-0.92-1.01-1.16-2.61-1.06-4.3
            l4.33-9.22c3.68-7.83-0.45-12.92-0.45-12.92l-0.36-0.17c0,0-6.55,0.07-10.23,7.9l-4.56,9.72c-1.12,0.94-2.34,1.58-3.54,1.53
            c0.35-1.03,0.69-1.94,0.97-2.75l-2.14-1.01c-1.36,2.1-2.72,4.77-3.49,7.23c0.14,0.06,2.35,1.1,2.35,1.1s1.24-1.79,1.35-2.37
            c1.2,0.39,4.05,1.24,5.61,1.78l-0.87,1.84l4.98,2.34l0.88-1.87c1.37,0.82,4.04,2.6,5.16,3.32c-0.38,0.45-0.97,2.55-0.97,2.55
            s2.22,1.04,2.35,1.1C29.92,39.81,31.11,37.06,31.86,34.68z M24.47,23.22c-1.86-0.88-2.67-3.1-1.79-4.97
            c0.88-1.87,3.1-2.67,4.97-1.79c1.87,0.88,2.67,3.1,1.8,4.97C28.56,23.3,26.34,24.1,24.47,23.22z"
            />
            <path
              class="filler"
              fill="#1CDCC7"
              d="M14.11,21.43c-0.62,0.43-1.44,1.35-2.29,2.56l1.86,0.87C14.13,23.45,14.34,22.38,14.11,21.43z"
            />
            <path
              class="filler"
              fill="#1CDCC7"
              d="M32.69,30.16c-0.88,0.43-1.57,1.28-2.36,2.52l1.86,0.87C32.57,32.12,32.75,30.91,32.69,30.16z"
            />
            <path
              class="filler"
              fill="#1CDCC7"
              d="M27.65,16.46c-1.87-0.88-4.1-0.07-4.97,1.79c-0.88,1.87-0.07,4.1,1.79,4.97c1.87,0.88,4.1,0.07,4.97-1.79
            C30.32,19.57,29.51,17.33,27.65,16.46z"
            />
            <rect
              x="15.47"
              y="37.67"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.4748 37.95)"
              fill="#FFFFFF"
              width="4.34"
              height="1.16"
            />
            <rect
              x="17.95"
              y="37.92"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -23.6132 39.8207)"
              fill="#FFFFFF"
              width="3.18"
              height="1.16"
            />
            <rect
              x="14.64"
              y="36.37"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.1091 35.9231)"
              fill="#FFFFFF"
              width="3.18"
              height="1.16"
            />
            <rect
              class="filler"
              x="15.47"
              y="37.67"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.4748 37.95)"
              fill="#1CDCC7"
              width="4.34"
              height="1.16"
            />
            <rect
              class="filler"
              x="17.95"
              y="37.92"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -23.6132 39.8207)"
              fill="#1CDCC7"
              width="3.18"
              height="1.16"
            />
            <rect
              class="filler"
              x="14.64"
              y="36.37"
              transform="matrix(0.4252 -0.9051 0.9051 0.4252 -24.1091 35.9231)"
              fill="#1CDCC7"
              width="3.18"
              height="1.16"
            />
          </svg>Launchpad</a
        >
        <!-- <div class="sub">
          <a href="#"><span class="svg-icon icons-home" /> Apply for IGO</a>
          <a href="#"><span class="svg-icon icons-home" /> FAQ</a>
        </div> -->
      </li>
     
    </ul>
  </div>
</header>

<style>
  .sub-outer {
    padding-bottom: 2rem;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  .open-menu {
    overflow: hidden !important;
  }

  .sub-bottom {
    padding: 0.5rem 2rem;
    width: 100%;
    margin-top: 0.5rem;
  }

  .sub-bottom h5 {
    font-size: 1.25rem;
    margin: 0;
    color: white;
  }
  .sub-bottom img{
    width:50px;
  }
  .sub-bottom p {
    font-size: 0.8rem;
    margin-top: 8px;
    color: var(--tertiary-primary);
  }
  @media (max-width: 567px) {
    header {
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 999;
    }
    .mobile-menu a img {
      width: 32px;
    }
    header .sub-flex {
      background: rgba(8, 1, 20, 0.48);
      border: 1px solid rgba(255, 255, 255, 0.08);
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      width: 100vw;
      overflow-x: scroll;
    }
    header .sub-flex a {
      font-size: 14px;
      width: max-content;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
      border-bottom: 2px solid transparent;
      padding: 0px 5px;
    }
    header .sub-flex a .svg-icon {
      width: 48px;
      height: 48px;
      -webkit-mask-size: 24px !important;
      -webkit-mask-position: center !important;
      background: rgba(255, 255, 255, 0.5);
    }
    header .sub-flex a.active {
      color: #f10088;
      border-bottom: 2px solid #f10088;
    }
    header .sub-flex a.active .svg-icon {
      background: #f10088;
    }
  }
  .mobile-menu-open ul li a svg {
    max-width: 30px;
    margin-right: 1rem;
    opacity: 0.6;
  }
  .mobile-menu-open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
    background: rgba(8, 1, 20, 0.48);
    border: 1px solid rgba(255, 255, 255, 0.08);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(40px);
    top: 0px;
  }

  .socials .svg-icon {
    width: 1.1rem;
    height: 1.1rem;
    -webkit-mask-position: center;
    mask-position: center;
  }

  .socials a {
    width: 48px;
    height: 48px;
    display: flex;
    margin: 0px !important;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #630bf1;
    box-sizing: border-box;
    border-radius: 12px;
    -webkit-mask-position: center;
  }
  .mobile-menu-open .close-icon {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 1rem;
    top: 1rem;
    background: #16092b;
    border-radius: 12px;
  }
  .mobile-menu-open .close-icon .svg-icon {
    background: #b8c5ec;
    width: 48px;
    height: 48px;
  }
  .mobile-menu-open ul {
    width: 100%;
    margin-top: 5rem;
    padding-left: 0px;
  }
  .mobile-menu-open ul li {
    border-top: 1px solid rgba(184, 197, 236, 0.1);
    display: flex;
    align-items: center;
    padding: 0.3rem 2rem;
    font-size: 1.15rem;
  }

  .mobile-menu-open ul li:nth-child(5) {
    border-bottom: 1px solid rgba(184, 197, 236, 0.1);
  }
  .mobile-menu-open ul li a {
    width: 100%;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.72);
    display: flex;
  }
  .mobile-menu-open ul li a span {
    background: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    width: 1.5rem;
    margin-right: 1rem;
    height: 1.5rem;
    color: rgba(255, 255, 255, 0.72);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu {
    width: 100vw;
    background: var(--background-level-3);
    padding: 0.5rem 0.75rem;
  }
  .mobile-menu .icons-menu {
    height: 2.5rem;
    width: 2.5rem;
  }
  .mobile-menu .logo {
    height: 2.25rem;
    width: 2.25rem;
    margin-left: 0.75rem;
  }

  /* .fixed-connect {
    position: fixed;
    z-index: 99;
    margin-top: 1rem;
  } */

  .blurry {
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid rgba(255, 255, 255, 0.24);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    padding: 8px;
  }

  @media (min-width: 768px) {
    .blurry {
      position: fixed;
      z-index: 99;
      margin-top: 1rem;
      right: 100px;
    }
  }
  @media (max-width: 768px) {
    .blurry {
      padding: 4px;
    }
  }
  .blurry .spn {
    color: white;
    font-size: 0.875rem;
    margin-right: 1rem;
    align-items: center;
  }
  .blurry .spn img {
    margin: 0px 12px;
    width: 1rem;
    height: 1rem;
  }
  .blurry .wlt {
    color: white;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }
  .blurry .wlt .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    margin-right: 0.5rem;
  }
  .blurry .log {
    background: rgba(0, 0, 0, 0.32);
    width: 2rem;
    height: 2rem;
    margin-left: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .blurry .log:hover {
    background: rgba(33, 13, 66, 0.6) !important;
  }
  .blurry .log .icons-login {
    background: white;
    width: 1rem;
    height: 1rem;
  }
  .blurry a {
    font-size: 14px;
    color: white;
    border-radius: 12px;
    cursor: pointer;
    padding: 10px 14px;
    background: #f10088;
  }
  @media (max-width: 768px) {
    .blurry a {
      font-size: 12px;
      padding: 8px 11px !important;
    }
    .blurry a.bspin {
      margin-right: 4px !important;
    }
  }
  .blurry a.bspin {
    background: rgba(33, 13, 66, 0.4);
    padding: 10px 14px;
    margin-right: 8px;
  }
  .blurry a.bspin:hover {
    background: rgba(33, 13, 66, 0.6) !important;
  }
  .blurry a:hover {
    background: #f2309e !important;
    color: white !important;
  }

  .sidebar {
    width: 5rem;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99;
    background: rgba(8, 1, 20, 0.48);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  .sidebar .logo {
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar ul {
    padding: 0px;
    margin: 0px;
  }
  .sidebar ul li {
    height: 4.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    position: relative;
    display: block;
  }
  .sidebar ul li:nth-child(4) svg {
    width: 26px;
    max-width: 40px;
    margin-left: 1px;
  }
  .sidebar ul li:nth-child(3) svg {
    width: 22px;
  }
  .sidebar ul li.active {
    background: var(--secondary-primary);
  }

  .sidebar ul li.active a{
    color:white !important;
  }
  
  .sidebar ul li.active svg {
    opacity: 1;
  }
  .sidebar ul li.active svg .filler {
    fill: #590ad9 !important;
  }
  .sidebar ul li a {
    display: flex;
    align-items: center;
    height: 4.5rem;
    justify-content: center;
    flex-direction: column;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.72);
  }
  .sidebar ul li svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
    opacity: 0.6;
  }
  :hover {
    opacity: 1;
  }
  .sidebar ul li a span {
    background: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 4px;
    color: rgba(255, 255, 255, 0.72);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar ul li .sub {
    background: rgba(8, 1, 20, 0.48);
    border: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(62px);
    -webkit-backdrop-filter: blur(62px);

    width: 175px;
    padding: 0.5rem;
    border-radius: 24px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 79px;
    min-width: 175px;
    background-position: center;
    background-size: cover;
    transition: all 400ms ease-out;
    display: none;
  }
  .sidebar ul li .sub .filter {
    display: none;
  }
  .sidebar ul li .sub a {
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.75rem;
    border-radius: 24px;
    color: rgba(255, 255, 255, 0.72);
    margin-top: 0.6rem;
    justify-content: start;
    padding: 0.5rem 0 0.5rem 0.75rem;
  }
  .sidebar ul li .sub a:first-child {
    margin-top: 0px;
  }
  .sidebar ul li .sub a span {
    width: 20px;
    height: 20px;
    border-radius: 0px !important;
    background: rgba(255, 255, 255, 0.72);

    margin-right: 0.75rem;
    margin-bottom: 0px;
  }
  .sidebar ul li .sub a:hover {
    background: var(--secondary-primary);
  }
  .sidebar ul li:hover {
    background: rgba(241, 0, 136, 0.3);
  }
  .sidebar ul li .sub a:hover {
    color: white;
  }
  .sidebar ul li .sub a:hover span {
    background: white;
  }
  .sidebar ul li:hover .sub {
    left: 78px;
    opacity: 1;
    display: block;
  }
  .sidebar ul li:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
</style>
