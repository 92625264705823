<script>
  import { onDestroy } from "svelte";
  import VestingIGO from "../IGOCard/VestingIGO.svelte";

  let IGOs = [];

  const fetchData = async () => {
    await fetch("https://apiv1.spintop.network/fetchIGOs")
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed!");
        }
        return res.json();
      })
      .then((data) => {
        IGOs = data.IGOs;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const interval = setInterval(() => {
    fetchData();
  }, 5000);
  onDestroy(() => {
    clearInterval(interval);
  });
  fetchData();
</script>

<main class="flex-row">
  {#each IGOs.reverse() as igo}
    <VestingIGO {igo} />
  {/each}
</main>

<style>
  main {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    row-gap: 32px;
    column-gap: 28px;
  }

  @media only screen and (max-width: 480px) {
    main {
      flex-direction: column !important;
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
