<script>
  import { getContext } from "svelte";
  import { TABS } from "./Tabs.svelte";

  const tab = {};
  const { registerTab, selectTab, selectedTab } = getContext(TABS);

  registerTab(tab);
</script>

<button class:selected={$selectedTab === tab} on:click={() => selectTab(tab)}>
  <slot />
</button>

<style>
  button {
    padding: 8px;
    background: #16092b;
    border: none;
    border-radius: 12px;
    margin: 0;
    color: #b8c5ec;
    width: 100%;
    height: 40px;
  }

  .selected {
    background-color: #630bf1;
    color: white;
    border-radius: 12px;
  }

  @media only screen and (max-width: 480px) {
    button {
      height: auto;
    }
  }
</style>
