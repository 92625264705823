<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  function closeClaimModal() {
    dispatch("close");
  }

  export let imageURL;
</script>

<div class="container">
  <div class="backdrop" on:click={closeClaimModal} />
  <main class="flex-row" style="color:white">
    <img class="image-responsive" src={imageURL} alt="overview" />
  </main>
</div>

<style>
  .container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 51;
    padding: 12px;
    box-sizing: border-box;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(3, 0, 9, 0.77);
    backdrop-filter: blur(16px);
    box-sizing: border-box;
  }

  img {
    border-radius: 12px;
  }

  main {
    background-color: transparent;
    border-radius: 16px;
    padding: 40px;
    z-index: inherit;
  }

  @media only screen and (max-width: 480px) {
    main {
      padding: 24px;
    }

    .image-responsive {
      width: 100%;
    }
  }
</style>
