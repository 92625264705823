<script>
  import Accordion from "../Components/UI/Accordion.svelte";
  import { faqDetails } from "../Stores/FAQ";

  let isOpen = true;

  window.scroll({
    top: 0,
    left: 0,
  });
</script>

<main>
  <header>Frequently Asked Questions</header>
  <div class="flex-row">
    <div style="width: 100%; padding: 24px 0 96px">
      <div class="faq-card">
        <div class="faq-h">FAQs</div>
        <div class="faq-i">
          All the community’s main questions are answered here.
        </div>
      </div>
      <div class="faq-box">
        {#each Object.entries(faqDetails).slice(0, 1) as entry}
          <Accordion {entry} bind:isOpen />
          <hr
            style="width: 94%; margin-bottom: 10px; border: 1px solid rgba(184, 197, 236, 0.2);"
          />
        {/each}
        {#each Object.entries(faqDetails).slice(1, 10) as entry}
          <Accordion {entry} />
          <hr
            style="width: 94%; margin-bottom: 10px; border: 1px solid rgba(184, 197, 236, 0.2);"
          />
        {/each}
        {#each Object.entries(faqDetails).slice(10, 11) as entry}
          <Accordion {entry} />
        {/each}
      </div>
    </div>
  </div>
</main>

<style>
  main {
    width: 1296px;
  }

  header {
    margin: 40px 0 0;
    font-size: 24px;
    color: white;
  }

  .faq-card {
    background-image: url(/images/UI/faq-banner.jpg);
    border-radius: 16px;
    height: 368px;
    background-size: cover;
  }

  .faq-i {
    color: white;
    position: relative;
    font-size: 20px;
    text-align: center;
  }

  .faq-h {
    color: white;
    position: relative;
    font-size: 104px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: -1px;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 48px 0 0;
  }

  .flex-row .card div {
    background-color: rgb(33, 13, 66);
    border-radius: 12px;
    margin: 15%;
  }

  .faq-box {
    text-align-last: left;
    width: 858px;
    display: grid;
    position: relative;
    background: #210d42;
    border-radius: 12px;
    margin: auto;
    margin-top: -8%;
    padding: 8px 0;
  }

  @media only screen and (max-width: 860px) {
    main {
      display: contents;
    }

    .flex-row {
      height: 100%;
    }

    .flex-row .card div {
      align-self: center;
      width: 95%;
      margin: 216px 0 0 0;
    }
  }

  @media only screen and (max-width: 480px) {
    .faq-box {
      width: 100%;
    }

    .faq-card {
      height: 328px;
    }
  }
</style>
