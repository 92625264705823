export let faqDetails = {
  "How can I join IGOs on Launchpad?": [
    "By staking your SPIN tokens in the Launchpad Vault on <a href='https://staking.spintop.network/' target='_blank'><u>Staking</u></a>. \n\n" +
      "If you already have SPIN tokens in the SPIN Single Stake Pool, simply transfer them to Launchpad Vault.",
  ],
  "Do I need to go through the KYC process (Know Your Customer)? If so, how?": [
    "Yes. Please <a href='https://verify-with.blockpass.org/?clientId=spinstarter_c6185&serviceName=Spinstarter&env=prod' target='_blank'><u>follow this KYC link</u></a> to get verified.\n",
  ],
  "How can I buy SPIN?": [
    "You can buy SPIN from Pancake Swap by exchanging BNB or BUSD <a href='https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67' target='_blank'><u>from this link</u></a>.",
  ],
  "How much SPIN do I need to join IGOs on Launchpad?": [
    "You can join the IGOCard with a minimum of 1,000 SPINs and maximum of 1,000,000.",
  ],
  "How long do I need to stake SPINs before the IGO?": [
    "You need to have SPINs staked in the Launchpad Vault before the Contribution Round begins.",
  ],
  "Can I join the IGOs with my LP stake in the SPIN/BNB farm count?": [
    "No. For now, only your stake in Launchpad Vault will count.",
  ],
  "Do I earn APY (SPIN rewards) by staking my SPINs in the Launchpad Vault?":
    [
      "Yes, you will keep on earning SPIN rewards from the Single-staking SPIN pool with extra auto-compounding rewards.",
    ],
  "What are the rounds in the Launchpad IGO process?": [
    "There are five rounds in the Launchpad process, which starts after you stake or transfer your SPINs to the Launchpad Vault.\n\n" +
      "-Pre Contribution\n" +
      "-Contribution\n" +
      "-Allocation\n" +
      "-First come, first served\n" +
      "-Vesting\n\n" +
      "For detailed information on stages, please <a href='https://spintopnetwork.medium.com/get-ready-for-spinstarters-liftoff-c03ef414d21e' target='_blank'><u>read our Medium article</u></a>.",
  ],
  "What's the allocation formula?": [
    "Your share of SPINs on the Launchpad Vault determines your allocation for the IGO. Launchpad Vault continuously rewards allocations to you over the Contribution Round.\n\n Even though your share of SPINs are dynamic because of Spintop’s no-lock policy, you can approximate your allocation by the formula below:\n\n" +
      "Your IGO allocation: X \n" +
      "Total IGO allocation: Y\n" +
      "Your Launchpad Vault Contribution: Z\n" +
      "Total Launchpad Vault Contribution: C\n" +
      "\n" +
      "X = Y * ( Z / C )\n" +
      "For the exact mathematical formula and notation please refer to the corresponding <a href='https://docs.spintop.network/products/spinstarter/spinstarter-vault' target='_blank'><u>whitepaper page</u></a>.",
  ],
  "Where should I keep my SPIN to join an IGO on Launchpad?": [
    "We recommend keeping your SPIN tokens in the SPIN Single Stake Pool to be able to easily transfer them to the Launchpad Vault.\n",
  ],
  "How can I join multiple IGO's at the same time?": [
    "As long as your SPINs are staked in the Launchpad Vault, you will automatically get allocation claims for all of the ongoing IGOCard. \n",
  ],
};
