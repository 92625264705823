<script>
  import { pop } from "svelte-spa-router";

  let onRoute;
  let displayHeader = "";

  onRoute = window.location.href;

  switch (true) {
    case onRoute.includes("partnership"):
      displayHeader = "Partnership";
      break;
    case onRoute.includes("details"):
      displayHeader = "IGO Details";
      break;
    case onRoute.includes("faq"):
      displayHeader = "FAQ Guide";
      break;
    case onRoute.includes("viewAll"):
      displayHeader = "View All";
      break;
    case onRoute.includes("bountie"):
      displayHeader = "IGO Details";
      break;
    case onRoute.includes("creo"):
      displayHeader = "IGO Details";
      break;
    case onRoute.includes("trivians"):
      displayHeader = "IGO Details";
      break;
    case onRoute.includes("aria"):
      displayHeader = "IGO Details";
      break;
    case onRoute.includes("ookeenga"):
      displayHeader = "IGO Details";
      break;
  }

  const go_back = () => {
    pop();
  };
</script>

<main>
  <div class="back">
    <div class="flex-row">
      <div style="cursor: pointer">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a on:click={go_back}><i class="fa-solid fa-arrow-left" /></a>
      </div>
      <div style="cursor: pointer">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a on:click={go_back}>Back</a>
      </div>
      <i class="fa-solid fa-pipe" />
      <div>
        <p>{displayHeader}</p>
      </div>
    </div>
  </div>
</main>

<style>
  .back {
    height: 64px;
    align-items: center;
    background-color: rgba(33, 13, 66, 0.75);
    padding: 8px 42px;
    color: white;
  }

  .back .flex-row {
    font-family: "Nexa-Bold", serif;
    align-items: center !important;
    font-size: 14px;
    margin-left:336px;
  }

  @media only screen and (max-width: 1800px) {
    .back .flex-row {
      margin-left: 10%;
    }
  }


  .back .flex-row a {
    margin: 0 12px 0 0;
  }

  .back .flex-row p {
    color: #b8c5ec;
  }

  .fa-pipe {
    margin: 0 16px 0 8px;
  }

  @media only screen and (max-width: 860px) {
    .back {
      padding: 8px 20px;
    }

    .back .flex-row {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .back {
      padding: 8px 20px;
    }

    .back .flex-row {
      width: 100%;
    }

    .fa-pipe {
      margin: 4px 16px 0 8px;
    }
  }
</style>
