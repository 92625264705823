<script>
  import { userAddress, hexProof, userAmount } from "../Stores/Network";
  import whitelistAddresses from "../Stores/trivians.json";
  import { ethers } from "ethers";

  import { MerkleTree } from "merkletreejs";
  import { solidityKeccak256 } from "ethers/lib/utils";

  const keccak256 = ethers.utils.keccak256;

  let merkleTree, leafNodes;

  $: {
    if ($userAddress) {
      const userIndex = whitelistAddresses.allocations.findIndex((item) => {
        return item[0] === $userAddress;
      });

      try {
        leafNodes = whitelistAddresses.allocations.map((item) => {
          return solidityKeccak256(["address", "uint256"], [item[0], item[1]]);
        });

        merkleTree = new MerkleTree(leafNodes, keccak256, {
          sortPairs: true,
        });

        hexProof.set(merkleTree.getHexProof(leafNodes[userIndex]));
        userAmount.set(whitelistAddresses.allocations[userIndex][1]);
      } catch (e) {
        if (e) {
          hexProof.set([]);
          userAmount.set(0);
        }
      }
    }
  }
</script>
