<script>
  import { networkProvider, networkSigner, userAddress, postIPP } from "../../../Stores/Network";
  import { createEventDispatcher } from "svelte";
  import Slider from "@bulatdashiev/svelte-slider";
  import Button from "../Button.svelte";
  import Divider from "../Divider.svelte";
  import { ethers } from "ethers";
  import { abiClaim } from "../../../Stores/ABI";
  import { notification } from "../../../Stores/Notification";

  // dispatchers
  const dispatch = createEventDispatcher();

  function closeClaimModal(paidAmounts, claimedTokens) {
    postIPP(paidAmounts,claimedTokens);
    dispatch("close");
  }

  // internal states
  let claimInProgress = false;
  let amount;
  let textActive = false;

  // multiple two-way binding with conversions -- this can be prettier
  $: sliderAmount = allowance.mul(stakeSlider[0]).div(100);
  $: textAmount = 0;
  $: textActive
    ? textAmount
      ? (amount = ethers.utils.parseUnits(textAmount))
      : ""
    : (textAmount = sliderAmount);
  $: textActive ? "" : (amount = sliderAmount);
  $: !textActive
    ? (textAmount = parseFloat(ethers.utils.formatEther(sliderAmount)).toFixed(
        6
      ))
    : "";

  // props
  export let type;
  export let allowance;
  export let balance;
  export let claimContractAddr;

  // declarations
  $: stakeSlider = [0, 0];

  // component functions
  const maxHandler = () => {
    stakeSlider[0] = 100;
  };
  // contract functions
  const payAllocation = async () => {
    if (amount > 0) {
      const claimContract = new ethers.Contract(
        claimContractAddr,
        abiClaim,
        $networkProvider
      );
      const claimWithSigner = claimContract.connect($networkSigner);
      claimInProgress = true;
      const paidA = ethers.utils.formatUnits(await claimContract.paidAmounts($userAddress));
      const claimedT = parseFloat(ethers.utils.formatUnits(await claimContract.claimedAmounts($userAddress))).toFixed(2);
      try {
        const payAllocation = await claimWithSigner.payForTokens(amount);
        const receipt = await payAllocation.wait();
        receipt.status === 1
          ? notification.set({
              visible: true, // simple trigger
              title: "Success: ",
              text: "Payment successful.",
              icon: "fa-regular fa-circle-check fa-lg",
            })
          : notification.set({
              visible: true, // simple trigger
              title: "Failed: ",
              text: "Payment failed.",
              icon: "fa-regular fa-hexagon-exclamation fa-lg",
            });
      } catch (err) {
        if (err.code === 4001) {
          claimInProgress = false;
          notification.set({
            visible: true, // simple trigger
            title: "Cancelled: ",
            text: "Staking cancelled by wallet.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
        }
        closeClaimModal();
      }
      claimInProgress = false;
      closeClaimModal(paidA,claimedT);
    }
  };

  const payPublic = async () => {
    if (amount > 0) {
      const claimContract = new ethers.Contract(
        claimContractAddr,
        abiClaim,
        $networkProvider
      );
      const claimWithSigner = claimContract.connect($networkSigner);
      claimInProgress = true;
      const paidA = ethers.utils.formatUnits(await claimContract.paidAmounts($userAddress));
      const claimedT = parseFloat(ethers.utils.formatUnits(await claimContract.claimedAmounts($userAddress))).toFixed(2);
      try {
        const payPublic = await claimWithSigner.payForTokensPublic(amount);
        const receipt = await payPublic.wait();
        receipt.status === 1
          ? notification.set({
              visible: true, // simple trigger
              title: "Success: ",
              text: "Payment successful.",
              icon: "fa-regular fa-circle-check fa-lg",
            })
          : notification.set({
              visible: true, // simple trigger
              title: "Failed: ",
              text: "Payment failed.",
              icon: "fa-regular fa-hexagon-exclamation fa-lg",
            });
      } catch (err) {
        if (err.code === 4001) {
          claimInProgress = false;
          notification.set({
            visible: true, // simple trigger
            title: "Cancelled: ",
            text: "Payment of tokens cancelled by wallet.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
        }
        closeClaimModal();
      }
      claimInProgress = false;
      closeClaimModal(paidA,claimedT);
    }
  };
</script>

<div class="container">
  <div class="backdrop" on:click={closeClaimModal} />
  <main class="flex-col">
    <div class="h16">
      Payable Allocation :
      {parseFloat(ethers.utils.formatUnits(allowance)).toLocaleString("en")} BUSD
    </div>
    <div class="input-wrapper">
      <input
        type="number"
        value={textAmount}
        on:input={(e) => {
          textAmount = e.target.value;
          textActive = true;
        }}
      />
      <div on:click={maxHandler} class="h12">Max</div>
      <Divider height="8px" />
      <Slider
        bind:value={stakeSlider}
        min="0"
        max="100"
        step="1"
        on:input={(e) => (textActive = false)}
      />
    </div>
    <div class="divider-line" />
    <div class="indicator flex-row">
      <div class="h12" style="color:var(--light-primary)">
        Your Balance: {parseFloat(
          ethers.utils.formatUnits(balance)
        ).toLocaleString("en")} BUSD
      </div>
    </div>
    <div
      on:click={type === "payAllocation" ? payAllocation : payPublic}
      style="width:100%"
    >
      <Button
        align="left"
        on:click={type === "payAllocation" ? payAllocation() : payPublic()}
        icon={claimInProgress ? "fa fa-circle-o-notch fa-spin" : ""}
      >
        Pay for tokens
      </Button>
    </div>
  </main>
</div>

<style>
  .container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 51;
    padding: 12px;
    box-sizing: border-box;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(3, 0, 9, 0.77);
    backdrop-filter: blur(16px);
    box-sizing: border-box;
  }

  main {
    background-color: var(--primary-opacity-30);
    gap: 24px;
    border-radius: 16px;
    align-items: flex-start;
    padding: 40px;
    z-index: inherit;
    max-width: 90%;
    width: 480px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(7, 9, 14, 0.48);
  }

  .h16 {
    color: var(--light-white);
  }

  input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    border: 1px solid var(--secondary-primary);
    box-sizing: border-box;
    border-radius: 12px;
    background-color: var(--background-level-3);
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: var(--light-white);
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-wrapper > .h12 {
    position: absolute;
    right: 16px;
    top: 16px;
    color: var(--secondary-primary);
    cursor: pointer;
  }

  .indicator {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  .divider-line {
    height: 1px;
    background-color: var(--light-background);
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    main {
      padding: 24px;
    }
  }
</style>
