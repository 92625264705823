<script>
  import { Navigation, Pagination, A11y, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from "swiper/svelte";
  let swiper;

  // window.dataLayer = window.dataLayer || [];

  // function gtag() {
  //   dataLayer.push(arguments);
  // }

  // gtag("js", new Date());

  // gtag("config", "G-0F0P8XWKM7");
  function fireEvent(i) {
    gtag("event", "Banner-" + i + " Event(SPINSTARTER)", {
      event_category: "Click",
      event_label: "Banner-" + i + " Event(SPINSTARTER)",
      value: "",
    });
  }
</script>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://unpkg.com/swiper@8.2.4/swiper-bundle.css"
  />

  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-0F0P8XWKM7"></script>
</svelte:head>

<main>
  <div class="container swiper-nav">
    <button
      class="swiper-button"
      type="button"
      on:click={() => swiper.slidePrev()}
      ><i class="fa-solid fa-angle-left" /></button
    >
    <button
      class="swiper-button"
      type="button"
      on:click={() => swiper.slideNext()}
      ><i class="fa-solid fa-angle-right" /></button
    >
  </div>

  <div class="swiper-container">
    <Swiper
      style={"--swiper-pagination-color: #F10088; --swiper-pagination-bullet-inactive-color: #000; --swiper-pagination-bullet-size: 8px;"}
      modules={[Autoplay, Navigation, Pagination, A11y]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={true}
      allowTouchMove={false}
      grabCursor={false}
      slidesPerView={1}
      spaceBetween={20}
      loop={true}
      on:swiper={(e) => (swiper = e.detail[0])}
    >
      <SwiperSlide
        ><div class="flex-row banner-bg-l">
          <div>
            <h1 class="ph">Buy SPIN</h1>
            <p class="p1">Buy SPIN, stake & earn allocations from IGOs!</p>
            <p class="p2">Tierless distrubition and no-lock up period.</p>
            <a
              on:click={() => fireEvent("1 Buy Spin Redirect")}
              href="https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67&chainId=56"
              target="_blank"
              role="button"
              id="buy-spin"
            >
              Buy on Pancakeswap
            </a>
          </div>
        </div></SwiperSlide
      >
      <SwiperSlide>
        <div class="flex-row banner-bg-m">
          <div>
            <h1 class="ph nonemb">Launchpad Investor Protection Program</h1>
            <p class="ph-m">Launchpad IPP</p>
            <p class="p1">
              Invest with peace of mind, thanks to our Investor Protection
              Program!
            </p>
            <p class="p2">Read our Medium article.</p>

            <a
              on:click={() => fireEvent("2 Medium Redirect")}
              href="https://spintopnetwork.medium.com/spinstarter-introduces-investor-protection-program-f7e5eb4919ec"
              target="_blank"
              role="button"
              id="spin-ipp"
            >
              Check for Details
            </a>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="flex-row banner-bg-r">
          <div>
            <h1 class="ph">Staking</h1>
            <p class="p1">
              The ultimate cross-chain liquidity solution for GameFi.
            </p>
            <p class="p2">High APR, no lock-up period!</p>
            <a
              on:click={() => fireEvent("3 Spindex Redirect")}
              href="https://staking.spintop.network/#/"
              target="_blank"
              role="button"
              id="spindex"
            >
              Go to Staking
            </a>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</main>

<style>
  @media (max-width: 768px) {
    .nonemb {
      display : none !important;
    }
  }
  main {
    display: flex;
    place-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 2rem;
  }

  .swiper-nav {
    position: absolute;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 0px !important;
  }

  .swiper-nav button:first-child {
    margin-left: -4.4rem;
  }
  .swiper-nav button:last-child {
    margin-right: -4.4rem;
  }

  .swiper-container {
    width: 100%;
  }

  @media (max-width: 1450px) {
    .swiper-nav button:first-child {
      margin-left: -1;
      font-family: "Nexa-Regular";
    }
    .swiper-nav button:last-child {
      margin-right: -1;
      font-family: "Nexa-Regular";
    }
  }

  .ph {
    font-size: 40px;
    font-family: "Nexa-Regular";
    font-weight: 700;
    line-height: 1;
    width: max-content;
  }

  .ph-t {
    font-size: 40px;
    font-family: "Nexa-Regular";
    font-weight: 700;
    line-height: 0;
    width: max-content;
  }

  .ph-m {
    display: none;
    font-size: 40px;
    font-family: "Nexa-Regular";
    font-weight: 700;
    line-height: 0;
    width: max-content;
  }

  @media (max-width: 1200px) {
    .ph {
      letter-spacing: -1.5px;
    }
    .ph-t {
      letter-spacing: -1.5px;
    }
  }

  .p1 {
    /* line-height: 2; */
    font-family: "Nexa-Regular";
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 24px;
    margin: 16px 0 0;
  }

  .p2 {
    /* line-height: 0.4; */
    font-family: "Nexa-Regular";
    font-size: 18px;
    letter-spacing: -0.6px;
    line-height: 24px;
    margin: 16px 0 0;
  }

  .banner-bg-l {
    background-image: url(/images/UI/buy-spin.jpg);
    background-size: cover;
    border-radius: 12px;
    height: 408px;
    justify-content: space-between;
    margin-inline: auto;
  }

  .banner-bg-l div {
    width: 50%;
    align-self: center;
    padding: 0 64px;
  }

  .banner-bg-l a {
    padding: 0 16px;
    margin: 24px 0 0;
    background: #f10088;
    align-items: center;
    border-radius: 0.75rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    height: 3rem;
    justify-content: center;
    letter-spacing: -0.6px;
    line-height: 24px;
    line-height: 1;
    min-width: 140px;
    padding-left: 1rem;
    padding-right: 1rem;
    transition-duration: 0.15s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
  }

  .banner-bg-m {
    background-image: url(/images/UI/protection-plan.jpg);
    background-size: cover;
    border-radius: 12px;
    height: 408px;
    justify-content: space-between;
    margin-inline: auto;
  }

  .banner-bg-m div {
    width: 50%;
    align-self: center;
    padding: 0 64px;
  }

  .banner-bg-m a {
    padding: 0 16px;
    margin: 24px 0 0;
    background: #f10088;
    align-items: center;
    border-radius: 0.75rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    height: 3rem;
    justify-content: center;
    letter-spacing: -0.6px;
    line-height: 24px;
    line-height: 1;
    min-width: 140px;
    padding-left: 1rem;
    padding-right: 1rem;
    transition-duration: 0.15s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
  }

  .banner-bg-r {
    background-image: url("/images/UI/spindex-banner.jpg");
    background-size: cover;
    border-radius: 12px;
    height: 408px;
    justify-content: space-between;
    margin-inline: auto;
  }

  .banner-bg-r div {
    width: 50%;
    align-self: center;
    padding: 0 64px;
  }

  .banner-bg-r a {
    padding: 0 16px;
    margin: 24px 0 0;
    background: #f10088;
    align-items: center;
    border-radius: 0.75rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    height: 3rem;
    justify-content: center;
    letter-spacing: -0.6px;
    line-height: 24px;
    line-height: 1;
    min-width: 140px;
    padding-left: 1rem;
    padding-right: 1rem;
    transition-duration: 0.15s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
  }

  .swiper-button {
    position: unset;
    z-index: 2;
    background: #630bf1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.75rem;
    color: white;
    margin-right: -4rem;
  }

  .swiper-button:hover {
    background-color: #6329f1;
  }

  @media (max-width: 768px) {
    .swiper-container {
      width: 100%;
    }

    .ph-t {
      display: none;
    }

    .ph-m {
      display: block;
    }

    .swiper-button {
      display: none;
    }

    .banner-bg-l {
      background-image: url("/images/UI/buy-spin-mobile.jpg");
    }

    .banner-bg-m {
      background-image: url("/images/UI/protection-plan-mobile.jpg");
    }

    .banner-bg-r {
      background-image: url("/images/UI/spindex-banner-mobile.jpg");
    }

    .banner-bg-l div {
      padding-left: 32px;
      width: 100%;
    }

    .banner-bg-r div {
      padding-left: 32px;
      width: 100%;
    }

    .banner-bg-m div {
      padding-left: 32px;
      width: 100%;
    }
  }
</style>
