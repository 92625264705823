<script>
  import {
    connectNode,
    connectWallet,
    chainID,
    networkProvider,
    networkSigner,
    userAddress,
    userConnected,
    userAmount,
    hexProof,
  } from "../../Stores/Network";
  import { ethers } from "ethers";
  import { addr } from "../../Stores/Address";
  import {
    abiClaim,
    abiIGO,
    abiLinearVesting,
    abiPaymentToken,
  } from "../../Stores/ABI";
  import { notification } from "../../Stores/Notification";
  import { onDestroy, onMount } from "svelte";
  import ClaimModal from "../UI/Modals/ClaimModal.svelte";

  export let igo;

  // Static Controls
  let roundTag = "";
  let statusTag = "";
  let progress;

  // Modal Controls
  let modalOpen = false;
  let modalType;

  // Fetch User Based Data
  let deservedAllocation;
  let deservedAllocationRaw;
  let paidAmount;
  let maxPublicBuy;
  let userBought;
  let userBoughtAlloc;
  let diffUserAlloc;
  let maxBuyPossible;
  let maxBuyPossibleAlloc;
  let paymentTokenBalance;
  let userApproved;
  let userAllowance;
  let userClaimableTokens;

  // Fetch Non-User Based Data
  let claimedAmounts;
  let totalPaidForClaim;
  let totalDollarsToPay;

  // Action Statement
  let approveInProgress = false;
  let claimInProgress = false;

  // State
  let round;
  let duration;

  // Counter
  let days;
  let hours;
  let minutes;
  let seconds;

  let linearVestingContractAddr = "0x0ca4d43fa7a5032A0cEE1F9cBdF717DbB614df05";
  let percentageDeserved;
  let linearClaimedTokens;
  let linearClaimableTokens;
  let linearTotalClaimableTokens;

  // Check Route not to display the cards. If it is viewAll route, then display all the cards.
  let style;

  if (window.location.href.includes("viewAll")) {
    style = document.createElement("style");
    style.innerHTML = `main:nth-child(0) {
        display: none;}`;
    document.head.appendChild(style);
  } else {
    style = document.createElement("style");
    style.innerHTML = `main:nth-child(n + 4) {
        display: none;}`;
    document.head.appendChild(style);
  }

  // fetchData Call per 0.5 seconds
  const interval = setInterval(() => {
    setTimeStates();
  }, 500);
  onDestroy(() => {
    clearInterval(interval);
  });

  // Set Time & States
  const setTimeStates = async () => {
    if (igo.dynamic.isIGOStarted === true) {
      $userConnected ? await fetchUserData(igo) : "";
    }

    if (
      igo.dynamic.contributionStartDate !== 0 &&
      igo.dynamic.contributionEndDate > Date.now() / 1000
    ) {
      duration = igo.dynamic.contributionEndDate - Date.now() / 1000;
      round = "contribution";
      if (duration < 0) {
        duration = 0;
      }
    } else if (igo.dynamic.igoState === 0) {
      duration = igo.dynamic.allocationEndDate - Date.now() / 1000;
      round = "allocation";
    } else if (igo.dynamic.igoState === 1 && igo.dynamic.totalUnallocated > 0) {
      duration = igo.dynamic.publicEndDate - Date.now() / 1000;
      round = "first-come-first-serve";
    } else if (
      igo.dynamic.igoState === 2 ||
      (igo.dynamic.igoState === 1 && igo.dynamic.totalUnallocated === 0)
    ) {
      if (igo.dynamic.claimPercentage < 100) {
        duration = 0;
        round = "vesting-progress";
      } else if (igo.static.igoTitle === "Creo Engine") {
        duration = 0;
        round = "completed";
      }  else if (
        igo.static.igoVestingType === "linear" &&
        igo.dynamic.claimPercentage === 100 && igo.static.igoTitle !== "Trivians"
      ) {
        duration = 0;
        round = "vesting-progress";
      } else if (percentageDeserved / 100 === 100){
        duration = 0;
        round = "completed"
      }
      else {
        duration = 0;
        round = "completed";
      }
    } else if (igo.dynamic.isIGOStarted === false) {
      duration = Math.floor(
        igo.static.lastPreContributionTime - Date.now() / 1000
      );
      round = "pre-contribution";
      if (duration < 0) {
        duration = 0;
      }
    }

    if(igo.static.igoTitle === "Ookeenga"){
      duration = 0;
      round = "completed";
    }

    days = Math.floor(duration / (60 * 60 * 24));
    hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));
    minutes = Math.floor((duration % (60 * 60)) / 60);
    seconds = Math.floor(duration % 60);

    switch (round) {
      case "pre-contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Pre Contribution Round";
        break;
      case "contribution":
        progress = 0;
        roundTag = "Public Sale";
        statusTag = "Contribution Round";
        break;
      case "allocation":
        progress =
          (igo.dynamic.totalPaidForClaim / igo.dynamic.totalDollarsToPay) * 100;
        roundTag = "Public Sale";
        statusTag = "Allocation Round";
        break;
      case "first-come-first-serve":
        progress =
          (igo.dynamic.totalPaidForClaim / igo.dynamic.totalDollarsToPay) * 100;
        roundTag = "Public Sale";
        statusTag = "FCFS Round";
        break;
      case "vesting-progress":
        progress = 100;
        roundTag = "Public Sale";
        statusTag = "Vesting in progress";
        break;
      case "completed":
        progress = 100;
        roundTag = "";
        statusTag = "Completed";
        break;
    }
  };

  onMount(async () => {
    await connectNode(); // Connect Node.
  });

  // Manage Close Modal
  const closeModal = (type) => {
    modalType = type;
    modalOpen = false;
  };

  // Manage Open Modal
  const openModal = (type) => {
    modalType = type;
    modalOpen = true;
  };

  // Fetch User Based Data
  const fetchUserData = async (igo) => {
    if ($userConnected && $chainID === igo.static.chainID) {
      const linearVestingContract = new ethers.Contract(
        linearVestingContractAddr,
        abiLinearVesting,
        $networkProvider
      );
      linearClaimedTokens = await linearVestingContract.claimedTokens(
        $userAddress
      );
      linearClaimableTokens = await linearVestingContract.deserved($userAmount);
      linearTotalClaimableTokens =
        (parseInt(linearClaimableTokens._hex, 16).toLocaleString("en", {
          minimumFractionDigits: 2,
        }) *
          parseInt(percentageDeserved)) /
          100 -
        parseInt(linearClaimedTokens._hex, 16).toLocaleString("en", {
          minimumFractionDigits: 2,
        });

      const claimContract = new ethers.Contract(
        igo.dynamic.claimAddr,
        abiClaim,
        $networkProvider
      );
      const paymentTokenContract = new ethers.Contract(
        igo.dynamic.paymentTokenAddr,
        abiPaymentToken,
        $networkProvider
      );

      deservedAllocationRaw = await claimContract.deservedAllocation(
        $userAddress
      );

      deservedAllocation = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.deservedAllocation($userAddress)
        )
      );

      paymentTokenBalance = await paymentTokenContract.balanceOf($userAddress);
      userAllowance = await paymentTokenContract.allowance(
        $userAddress,
        igo.dynamic.claimAddr
      );
      userApproved = ethers.utils.formatUnits(userAllowance) > 1000000000;

      totalDollarsToPay = await claimContract.totalDollars();
      totalPaidForClaim = await claimContract.totalPaid();

      maxPublicBuy = await claimContract.maxPublicBuy($userAddress);
      userBought = await claimContract.paidPublic($userAddress);

      let diffUser = maxPublicBuy.sub(userBought);
      let diff = totalDollarsToPay.sub(totalPaidForClaim);

      maxBuyPossible = diffUser.gt(diff) ? diff : diffUser;
      userBoughtAlloc = await claimContract.paidAmounts($userAddress);
      diffUserAlloc = deservedAllocationRaw.sub(userBoughtAlloc);
      maxBuyPossibleAlloc = diffUserAlloc;

      paidAmount = ethers.utils.formatUnits(
        await claimContract.paidAmounts($userAddress)
      );

      userClaimableTokens = parseFloat(
        ethers.utils.formatEther(
          await claimContract.claimableTokens($userAddress)
        )
      );

      const claimedTokens = parseFloat(
        ethers.utils.formatUnits(
          await claimContract.claimedAmounts($userAddress)
        )
      ).toFixed(2);

      claimedAmounts = parseInt(claimedTokens / igo.dynamic.tokenPrice);
    }
  };

  // Approve the Payment Token Contract
  const approve = async () => {
    if ($userConnected && $chainID === igo.static.chainID) {
      const paymentTokenContract = new ethers.Contract(
        addr.igo.paymentToken,
        abiPaymentToken,
        $networkProvider
      );
      const claimWithSigner = paymentTokenContract.connect($networkSigner);
      approveInProgress = true;
      try {
        const approve = await claimWithSigner.approve(
          igo.dynamic.claimAddr,
          ethers.constants.MaxUint256
        );
        let receipt = await approve.wait();
        receipt.status === 1
          ? notification.set({
              visible: true,
              title: "Success: ",
              text: "Approve succesful.",
              icon: "fa-regular fa-circle-check fa-lg",
            })
          : notification.set({
              visible: true, // simple trigger
              title: "Failed: ",
              text: "Approve failed.",
              icon: "fa-regular fa-hexagon-exclamation fa-lg",
            });
        await fetchUserData();
      } catch (err) {
        if (err.code === 4001) {
          approveInProgress = false;
          notification.set({
            visible: true,
            title: "Cancelled: ",
            text: "Approve cancelled by wallet.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
        }
      }
      approveInProgress = false;
    } else {
      await connectWallet();
    }
  };

  // Claim Unlocked Tokens
  const claimTokens = async () => {
    const claimContract = new ethers.Contract(
      igo.dynamic.claimAddr,
      abiClaim,
      $networkProvider
    );
    const claimWithSigner = claimContract.connect($networkSigner);
    claimInProgress = true;
    try {
      const claimAll = await claimWithSigner.claimTokens();
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
    }
    claimInProgress = false;
  };

  // Linear Claim Unlocked Tokens
  const claimLinearTokens = async () => {
    const linearVestingContract = new ethers.Contract(
      linearVestingContractAddr,
      abiLinearVesting,
      $networkProvider
    );
    const claimWithSigner = linearVestingContract.connect($networkSigner);
    claimInProgress = true;
    try {
      const claimAll = await claimWithSigner.claim($userAmount, $hexProof);
      const receipt = await claimAll.wait();
      receipt.status === 1
        ? notification.set({
            visible: true,
            title: "Success: ",
            text: "Claim successful.",
            icon: "fa-regular fa-circle-check fa-lg",
          })
        : notification.set({
            visible: true,
            title: "Failed: ",
            text: "Claim failed.",
            icon: "fa-regular fa-hexagon-exclamation fa-lg",
          });
    } catch (err) {
      if (err.code === 4001) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Cancelled: ",
          text: "Claim cancelled by wallet.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      } else if (err.code === -32603) {
        claimInProgress = false;
        notification.set({
          visible: true,
          title: "Error: ",
          text: "There is no claimable token available.",
          icon: "fa-regular fa-hexagon-exclamation fa-lg",
        });
      }
      claimInProgress = false;
    }
  };
</script>

{#if modalOpen}
  {#if parseInt(maxBuyPossibleAlloc) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={igo.dynamic.claimAddr}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossibleAlloc) >= parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={igo.dynamic.claimAddr}
      allowance={modalType === "payAllocation"
        ? maxBuyPossibleAlloc
        : maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {:else if parseInt(maxBuyPossible) < parseInt(paymentTokenBalance)}
    <ClaimModal
      on:close={closeModal}
      type={modalType}
      claimContractAddr={igo.dynamic.claimAddr}
      allowance={maxBuyPossible}
      balance={paymentTokenBalance}
    />
  {/if}
{/if}

{#if paidAmount > 0}
  <div class="igo-outer">
    {#if igo.dynamic.round === "pre-contribution" || igo.dynamic.round === "contribution" || igo.dynamic.round === "allocation" || igo.dynamic.round === "first-come-first-serve"}
      <div class="igo-card">
        <div class="flex-row card-count">
          <div class="count-title">
            {#if igo.dynamic.round === "pre-contribution"}
              Contribution round starts in
            {:else if igo.dynamic.round === "contribution"}
              Contribution round ends in
            {:else if igo.dynamic.round === "allocation"}
              Allocation round ends in
            {:else if igo.dynamic.round === "first-come-first-serve"}
              Public sale ends in
            {:else if igo.dynamic.round === "vesting-progress"}
              Vesting in progress
            {/if}
          </div>
          {#if days > 0}
            <div class="count-time">
              {days}<span>d</span>
            </div>
          {/if}
          <div class="count-time">
            {hours}<span>h</span>
          </div>
          <div class="count-time">
            {minutes}<span>m</span>
          </div>
          {#if days === 0}
            <div class="count-time">
              {seconds}<span>s</span>
            </div>
          {/if}
        </div>
        <img
          style="border-radius: 12px 12px 0 0;"
          src={igo.static.igoBannerCard}
          alt="IGOs"
        />
        <div class="igo-data">
          <p class="h24">{igo.static.igoTitle}</p>
          <div class="card-flex-row">
            <div>
              <img
                style="width: 24px;height:24px;"
                src={igo.static.igoTokenIcon}
                alt="Token"
              />
            </div>
            <div style="margin: 7px 0 0 2px">
              <p style="color:#B8C5EC">{igo.static.igoTokenSymbol}</p>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-row">
              <span
                class="status-bar"
                style={round === "pre-contribution"
                  ? "background: #1CDCC7; color:black"
                  : round === "contribution" ||
                    round === "allocation" ||
                    round === "first-come-first-serve"
                  ? "background: #630BF1; color:white;"
                  : round === "vesting-progress"
                  ? "background: rgba(241, 0, 136, 0.5); color:white;"
                  : round === "completed"
                  ? "background: #B8C5EC; color:black;"
                  : ""}
              >
                {statusTag}</span
              >
              <span
                class="round-bar"
                style={roundTag !== "" ? "display: flex" : "display: none"}
                >{roundTag}</span
              >
            </div>
          </div>
          {#if igo.dynamic.round === "contribution"}
            <div class="flex-row">
              <div class="outer">
                <div
                  class="inner"
                  style="width: {progress}%; max-width: 100%;text-align: center;"
                >
                  <p>
                    Allocation :{"   " +
                      parseInt(deservedAllocation).toLocaleString("en")} BUSD
                  </p>
                </div>
              </div>
            </div>
          {/if}
          <div class="flex-row table-flex">
            <table>
              <tr>
                <th><small>Total USD Raise</small></th>
                <th><small>Total Token Raise</small></th>
                <th><small>IGO Price</small></th>
              </tr>
              <tr>
                <th
                  >{parseInt(igo.static.igoTotalUSDRaise).toLocaleString()} BUSD</th
                >
                <th>{igo.static.igoTotalTokenRaised}</th>
                <th>{parseFloat(igo.static.igoPrice).toLocaleString()}</th>
              </tr>
            </table>
          </div>
          <div class="buttons">
            <button
              class="claim-button"
              style={userApproved
                ? parseInt(maxBuyPossibleAlloc) > 0
                  ? ""
                  : "opacity: 0.4; cursor:default"
                : ""}
              on:click={userApproved
                ? parseInt(maxBuyPossibleAlloc) > 0
                  ? openModal("payAllocation")
                  : ""
                : approve()}
              >{userApproved ? "Pay Allocation" : "Enable contract"}
            </button>
            <a class="view-details-button" href="/#/{igo.static.igoURL}/"
              ><p style="line-height: 1;">View details</p></a
            >
          </div>
        </div>
      </div>
    {/if}
    {#if (igo.dynamic.round === "vesting-progress" && igo.dynamic.claimPercentage !== 100) || igo.static.igoVestingType === "linear"}
      <div class="igo-card">
        <img
          style="border-radius: 12px 12px 0 0;"
          src={igo.static.igoBannerCard}
          alt="IGOs"
        />
        <div class="igo-data">
          <p class="h24">{igo.static.igoTitle}</p>
          <div class="card-flex-row">
            <div>
              <img
                style="height: 24px;width: 24px"
                src={igo.static.igoTokenIcon}
                alt="Token"
              />
            </div>
            <div style="margin: 7px 0 0 2px">
              <p style="color:#B8C5EC">{igo.static.igoTokenSymbol}</p>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-row">
              <span
                class="status-bar"
                style={round === "pre-contribution"
                  ? "background: #1CDCC7; color:black"
                  : round === "contribution" ||
                    round === "allocation" ||
                    round === "first-come-first-serve"
                  ? "background: #630BF1; color:white;"
                  : round === "vesting-progress"
                  ? "background: rgba(241, 0, 136, 0.5); color:white;"
                  : round === "completed"
                  ? "background: #B8C5EC; color:black;"
                  : ""}
              >
                {statusTag}</span
              >
              <span
                class="round-bar"
                style={roundTag !== "" ? "display: flex" : "display: none"}
                >{roundTag}</span
              >
            </div>
          </div>
          <div class="flex-row table-flex">
            <table>
              <tr>
                <th><small>Total USD Raise</small></th>
                <th><small>Total Token Raise</small></th>
                <th><small>IGO Price</small></th>
              </tr>
              <tr>
                <th
                  >{parseInt(igo.static.igoTotalUSDRaise).toLocaleString()} BUSD</th
                >
                <th>{igo.static.igoTotalTokenRaised}</th>
                <th>{parseFloat(igo.static.igoPrice).toLocaleString()}</th>
              </tr>
            </table>
          </div>
          <div class="buttons">
            {#if igo.static.igoVestingType === "cliff"}
              <button
                class="claim-button"
                style={claimedAmounts < userClaimableTokens
                  ? "opacity: 1"
                  : "opacity: 0.4;cursor:default"}
                on:click={claimTokens}
                ><i style="margin-right: 6px" class="fa-light fa-wallet" />Claim
              </button>
            {/if}
            {#if igo.static.igoVestingType === "linear" && igo.static.igoTitle !== "Creo Engine"}
              <button
                class="claim-button"
                style={parseInt(linearClaimableTokens - linearClaimedTokens) > 0
                  ? "opacity: 1"
                  : "opacity: 0.4;cursor:default"}
                on:click={claimLinearTokens}
                ><i style="margin-right: 6px" class="fa-light fa-wallet" />Claim
              </button>
            {/if}
            {#if igo.static.igoVestingType === "linear" && igo.static.igoTitle === "Creo Engine"}
              <a
                href="https://app.unicrypt.network/services/lock-tokens"
                class="claim-button"
              >
                <p style="line-height:1; margin: 8px">Redirect to Unicrypt</p>
              </a>
            {/if}
            <a class="view-details-button" href="/#/{igo.static.igoURL}/"
              ><p style="line-height: 1;">View Details</p></a
            >
          </div>
        </div>
      </div>
    {/if}
    {#if igo.dynamic.round === "vesting-progress" && igo.dynamic.claimPercentage === 100 && igo.static.igoVestingType === "cliff"}
      <div class="igo-card">
        <img
          style="border-radius: 12px 12px 0 0;"
          src={igo.static.igoBannerCard}
          alt="IGOs"
        />
        <div class="igo-data">
          <p class="h24">{igo.static.igoTitle}</p>
          <div class="card-flex-row">
            <div>
              <img
                style="width: 24px;height:24px;"
                src={igo.static.igoTokenIcon}
                alt="Token"
              />
            </div>
            <div style="margin: 7px 0 0 2px">
              <p style="color:#B8C5EC">{igo.static.igoTokenSymbol}</p>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-row">
              <span
                class="status-bar"
                style={round === "pre-contribution"
                  ? "background: #1CDCC7; color:black"
                  : round === "contribution" ||
                    round === "allocation" ||
                    round === "first-come-first-serve"
                  ? "background: #630BF1; color:white;"
                  : round === "vesting-progress"
                  ? "background: rgba(241, 0, 136, 0.5); color:white;"
                  : round === "completed"
                  ? "background: #B8C5EC; color:black;"
                  : ""}>{statusTag}</span
              >
              <span
                class="round-bar"
                style={roundTag !== "" ? "display: flex" : "display: none"}
                >{roundTag}</span
              >
            </div>
          </div>
          <div class="flex-row table-flex">
            <table>
              <tr>
                <th><small>Total USD Raise</small></th>
                <th><small>Total Token Raise</small></th>
                <th><small>IGO Price</small></th>
              </tr>
              <tr>
                <th
                  >{parseInt(igo.static.igoTotalUSDRaise).toLocaleString()} BUSD</th
                >
                <th>{igo.static.igoTotalTokenRaised}</th>
                <th>{parseFloat(igo.static.igoPrice).toLocaleString()}</th>
              </tr>
            </table>
          </div>
          <div class="buttons">
            <button
              class="claim-button"
              style="align-self: center; {claimedAmounts < userClaimableTokens
                ? 'opacity: 1'
                : 'opacity: 0.4;cursor:default'}"
              on:click={claimTokens}
              ><i style="margin-right: 6px" class="fa-light fa-wallet" />Claim
            </button>
            <a class="view-details-button" href="/#/{igo.static.igoURL}/"
              ><p style="line-height: 1;">View Details</p></a
            >
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style>
  .igo-outer {
    max-width: calc(33% - 15px);
    width: 100%;
  }
  .igo-card {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-level-3);
    border-radius: 12px;
  }

  .card-count {
    position: sticky;
    place-self: center;
    margin: -14px 49px;
    background: #210d42;
    border-radius: 8px;
    font-size: 14px;
  }

  .card-count .count-title {
    padding: 2px 8px;
    align-self: center;
  }

  .card-count .count-time {
    padding: 2px;
    margin: 4px;
    background: rgba(99, 11, 241, 0.5);
    border-radius: 4px;
  }

  .count-time span {
    font-size: 12px;
  }

  .igo-data {
    padding: 24px;
    color: white;
    background: rgba(33, 13, 66, 0.75);
    border-radius: 0 0 12px 12px;
  }

  .igo-data .h24 {
    display: inline;
    margin-block-start: auto;
  }

  .igo-data .buttons {
    margin: 20px 0 4px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .buttons .claim-button {
    width: 100%;
    height: 48px;
    font-size: 16px;
    margin-right: 6px;
    border-radius: 12px;
    background: #630bf1;
    color: white;
  }

  .claim-button:hover {
    background-color: #6329f1;
  }

  .buttons .view-details-button {
    width: 100%;
    height: 48px;
    font-size: 16px;
    border-radius: 12px;
    background: transparent;
    border: solid 1px #630bf1;
    color: white;
    align-self: center;
  }

  .buttons .view-details-button:hover {
    background-color: #630bf1;
  }

  .igo-data .view-button button {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: transparent;
    color: white;
    border: 1px solid #630bf1;
  }

  .igo-data .card-flex-row p {
    padding: 0 0 4px 4px;
  }

  .table-flex {
    justify-content: center;
    flex-direction: column-reverse;
    padding-top: 14px;
    text-align: left;
  }

  .table-flex small {
    color: #b8c5ec;
    font-size: 12px;
  }

  .igo-data .card-flex-row {
    display: flex;
    align-items: center;
    line-height: 0.4;
  }

  .status-bar {
    background: linear-gradient(270deg, #f10088 0%, #630bf1 100%);
    border-radius: 16px;
    color: white;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 8px 0 0;
    padding: 4px 8px;
  }

  .round-bar {
    background: #630bf14d;
    border-radius: 16px;
    color: #b8c5ec;
    font-size: 12px;
    height: fit-content;
    align-self: center;
    margin: 0 4px 0 0;
    padding: 4px 8px;
  }

  .outer {
    width: 100%;
    background: rgba(99, 11, 241, 0.5);
    border-radius: 12px;
    font-size: 14px;
    margin: 16px 0 0;
    height: 32px;
  }

  .inner {
    width: 0;
    height: 32px;
    background: rgba(99, 11, 241, 0.5);
    border-radius: 12px;
  }

  .inner p {
    line-height: 0;
    position: absolute;
    margin: 16px 0 0 36px;
  }

  .flex-row p {
    justify-content: space-between;
  }

  .flex-row table {
    justify-content: space-between;
    width: 100%;
  }

  @media only screen and (max-width: 860px) {
    .igo-card {
      height: 100%;
    }

    .igo-card img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    .igo-card {
      width: 100%;
      margin: 0;
    }
    .igo-outer {
      max-width: calc(100%);
      width: 100%;
      margin-top: 1.5rem;
    }
    .igo-card img {
      width: 100%;
    }
  }
</style>
