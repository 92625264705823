<script>
  import queryString from "query-string";
  import { createEventDispatcher } from "svelte";
  import {
    emailValidator,
    requiredValidator,
  } from "../../../Javascript/validators";
  import { createFieldValidator } from "../../../Javascript/validation";

  const dispatch = createEventDispatcher();

  function closeMessageModal() {
    dispatch("close");
  }

  const [validity, validate] = createFieldValidator(
    requiredValidator(),
    emailValidator()
  );
  let sent = false;
  let email = "";
    let subject = "";
    let message = "";

  const postMessage = async (email, subject, message) => {
    await fetch("https://spindex.network/storeMessage/", {
      method: "POST",
      mode: "cors",
      body: queryString.stringify({
        email: email,
        subject: subject,
        message: message,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((res) => {
      if (res.status == 200) {
        sent = true;
      }
    });
  };
</script>

<div class="w-100">
  <div class="backdrop" on:click={closeMessageModal} />
  <main class="flex-col relative">
    <div class="close" on:click={closeMessageModal}>
      <span class="svg-icon icons-close" />
    </div>
    <div class="content" style="color: white">
      {#if !sent}
      <p class="t-smid font-white">Let's talk</p>
        <div class="relative">
          <label>Email</label>
          <input
            class="input"
            type="text"
            bind:value={email}
            placeholder="Email address"
            class:field-danger={!$validity.valid && email}
            class:field-success={$validity.valid}
            use:validate={email}
          />
        </div>
        {#if $validity.dirty && !$validity.valid}
          <span class="validation-hint">
            {$validity.message}
          </span>
        {/if}
        <label>Subject</label>
        <div class="relative">
          <input
            class="input"
            type="text"
            bind:value={subject}
            class:field-danger={!$validity.valid && subject}
              class:field-success={$validity.valid}
            placeholder="Write your subject"
          />
        </div>
        <div class="relative">
          <textarea
            type="textarea"
            bind:value={message}
            placeholder="Write your message here"
          />
        </div>
        <a
          on:click={postMessage(email, subject, message)}
          class:disabled={!$validity.valid || (Object.keys(subject).length < 1) || (Object.keys(message).length < 1)}
          class="send-button d-block">Send Message</a
        >
      {:else}
        <div class="text-center">
          <!-- <span class="svg-icon icons-check" /> -->
          <h2 class="t-smid font-white" style="text-align: center;">Well done!</h2>
          <p>You’ve sent your message. We will update you soon.</p>
        </div>
        <a on:click={closeMessageModal} class="send-button d-block">Done</a>
      {/if}
    </div>
  </main>
</div>

<style>
  .svg-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--light-tertiary);
    -webkit-mask-size: contain;
  }
  .icons-check {
    width: 75px;
    height: 75px;
    background: var(--rating-positive);
    position: unset !important;
  }

  .disabled{
    pointer-events: none;
    background:#787878b8 !important;
  }

  .close {
    right: -24px;
    top: -24px;
    width: 3rem;
    height: 3rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #16092b;
    box-shadow: 0px 2px 8px rgba(7, 9, 14, 0.48);
    border-radius: 48px;
    cursor: pointer;
  }

  .close .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    -webkit-mask-position: center;
    mask-position: center;
    background: white;
  }

  .close:hover .svg-icon {
    background: rgba(255, 255, 255, 0.8);
  }

  .w-100 {
    position: fixed;
    max-width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 51;
    padding: 12px;
    box-sizing: border-box;
  }
  .relative {
    position: relative;
  }
  .relative span {
    position: absolute;
    top: calc(50% - 0.75rem);
    left: 0.75rem;
  }
  .validation-hint {
    position: unset !important;
    color: var(--special-danger);
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(3, 0, 9, 0.77);
    backdrop-filter: blur(16px);
    box-sizing: border-box;
  }

  main {
    background-color: #16092b;
    border-radius: 16px;
    padding: 2.5rem;
    z-index: inherit;
    width: 480px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgb(7 9 14 / 48%);
  }

  .input {
    margin: 12px 0;
  }

  input {
    width: 100%;
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid transparent;
    background-color: #210d42;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: white;
  }

  input:focus{
    outline: none !important;
  }

  textarea{
    width: 100%;
    height: 6rem;
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid transparent;
    background-color: #210d42;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    padding: 12px 16px;
    color: #b8c5ec;
  }
  
  textarea:focus{
    outline: none !important;
  }

  .field-danger {
    border-color: rgba(241, 0, 136, 1);
  }

  input::placeholder {
    color: var(--light-tertiary);
    opacity: 1;
  }

  textarea::placeholder {
    color: var(--light-tertiary);
    opacity: 1;
  }


  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--light-tertiary);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--light-tertiary);
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
    box-sizing: border-box;
    background: rgba(3, 0, 9, 0.77);
  }

  .send-button {
    width: 100%;
    cursor: pointer;
    margin-top: 2rem;
    border-radius: 12px;
    font-size: 16px;
    background: #630bf1;
    color: white;
    padding: 12px 16px;
    text-align: center;
    display: block;
    box-sizing: border-box;
  }
  p {
    color: var(--light-primary);
    font-size: 20px;
    letter-spacing: -.8px;
    line-height: 28px;
  }

  label{
    --tw-text-opacity: 1;
    color: rgba(184,197,236,var(--tw-text-opacity));
    display: block;
    font-size: 12px;
    letter-spacing: .3px;
    line-height: 16px;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  @media only screen and (max-width: 480px) {
    main {
      padding: 24px;
    }
    p {
      font-size: 14px;
    }
  }
</style>
