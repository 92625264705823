<script>
  export let height = "100%";
  export let width = "100%";
</script>

<main style="--height: {height}; --width: {width}" />

<style>
  main {
    width: var(--width);
    height: var(--height);
  }
</style>
