<script>
  import { onDestroy } from "svelte";
  import { spinPrice } from "../../../Stores/Network";
  import { location } from "svelte-spa-router";
  import { onMount } from 'svelte';
  import jQuery from 'jquery';
  import MessageModal from "../../UI/Modals/MessageModal.svelte"

  let modalOpen = false;

  const closeModal = () => {
    modalOpen = false;
  };
  const openModal = () => {
    modalOpen = true;
  };

    onMount(async () => {
       if(window.innerWidth < 767){
        runAccordions();
       }
    });
  const fetchPrice = async () => {
    if ($location != "Home") {
      let apiData;
      await fetch("https://api.spintop.network/getData")
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed!");
          }
          return res.json();
        })
        .then((data) => {
          apiData = data;
        })
        .catch((err) => {
          console.log(err);
        });
      spinPrice.set(apiData.spinPrice);
    }
  };
  function runAccordions(){
        jQuery(".subs h5").click(function () {
                    var $not = jQuery(this).parent();
                    console.log("accordion__wrapper");
                    jQuery(".actived").not(this).removeClass("actived").next().slideUp(300);
                    //  $(".accordion__wrapper:not(:has(.active))").css("background-color", "yellow");
                    jQuery(this).toggleClass("actived").next().slideToggle(300);
                });
        }
  const interval = setInterval(() => {
    fetchPrice();
  }, 20000);
  onDestroy(() => {
    clearInterval(interval);
  });
  fetchPrice();
  function fireEvent(){
    gtag("event", "Buy Spin Footer Event(DEX)", {
      event_category: "Click",
      event_label: "Buy Spin Footer Event(DEX)",
      value: "",
    });
  }
</script>

{#if modalOpen === true}
  <MessageModal on:close={closeModal} />
{/if}

<!-- <main>
  <Notification />
  <div class="footer">
    <div class="left">
      <div class="price">
        <img src="/icons/footer-spin-icon.svg" alt="Spin Icon" />
        <div class="h16">
          {$spinPrice ? parseFloat($spinPrice).toFixed(4) : "-"}
        </div>
      </div>
      <a
        href={$chainID == 56 || !$chainID
          ? "https://bscscan.com/address/0x6AA217312960A21aDbde1478DC8cBCf828110A67#code"
          : $chainID == 137
          ? "https://polygonscan.com/address/0x35f80a39eeFe33D0dfD2aD2daa6aD6A9D472CEbd/#code"
          : ""}
        target="_blank"
        class="link"
      >
        <div class="tag">
          <div class="h12">Spin Contract</div>
          <i class="far fa-external-link" />
        </div>
      </a>
      <a on:click={fireEvent}
        href={$chainID == 56 || !$chainID
          ? "https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67"
          : $chainID == 137
          ? "https://quickswap.exchange/#/swap?outputCurrency=0x35f80a39eeFe33D0dfD2aD2daa6aD6A9D472CEbd"
          : ""}
        target="_blank"
        role="button"
        id="buy-spin-footer"
      >
        <div class="tag">
          <div class="h12">Buy Spin</div>
          <i class="far fa-external-link" />
        </div>
      </a>
    </div>
    <div class="right">
      <a
        class="audit"
        href="https://www.certik.com/projects/spintop-network"
        target="_blank"
      >
        <div class="h12">
          Audited<i style="margin-left: 8px" class="far fa-external-link" />
        </div>
      </a>
      <img src="/images/certik.png" alt="Certik Logo" />
    </div>
  </div>
</main> -->

<footer>
  <div class="container">
      <div class="d-flex flex-column flex-md-row justify-content-between w-100">
          <div class="foot-card">
              <p><span class="svg-icon icons-feather"></span></p>
              <h5>Write to us!</h5>
              <p>Do you have anything to ask the team?</p>
              <button class="button" on:click={openModal}>Send a Message</button>
          </div>
          <div class="foot-card">
              <p><span class="svg-icon icons-hands"></span></p>
              <h5>Partner With Us!</h5>
              <p>Let's discuss what we can do together!</p>
              <a href="https://spintop.network/partner" target="_blank" class="button">Become a Partner</a>
          </div>
          <div class="foot-card">
              <div class="dsk">
                  <p><span class="svg-icon icons-users"></span></p>
                  <h5>Stay Updated!</h5>
                  <p>Visit our social media channels for latest announcements!</p>
              </div>
              <div class="mobile">
                      <h5>Join Our Community</h5>
                      <p>We will keep you posted!</p>
              </div>
              <div class="d-flex socials justify-content-start">
                <a href="https://t.me/SpintopNetwork" target="_blank"><span class="svg-icon icons-telegram"></span></a>
                <a href="https://spintopnetwork.medium.com/" target="_blank"><span class="svg-icon icons-medium"></span></a>
                <a href="https://discord.com/invite/E7Mt6m7hw5?utm_source=Discord&utm_medium=Join&utm_campaign=Spintop" target="_blank"><span class="svg-icon icons-discord"></span></a>
                <a href="https://twitter.com/SpintopNetwork" target="_blank"><span class="svg-icon icons-twitter"></span></a>
                <a href="https://www.youtube.com/c/SpintopNetwork" target="_blank"><span class="svg-icon icons-youtube"></span></a>
              </div>
          </div>
      </div>
  </div>
  <div class="container d-flex flex-column flex-md-row justify-content-center btm">
      <!-- <div class="subs d-none d-md-block">
          <a class="logo" href="/"><img src="/svg/logo.svg"></a>
      </div> -->
      <div class="subs">
        <h5>
          <a href="https://spintop.network">NETWORK<span class="svg-icon icons-chevron-down d-block d-md-none" /></a>
        </h5>
          <ul>
            <li><a href="https://docs.spintop.network/" target="_blank"><span class="svg-icon icons-chevron-right"></span>Whitepaper</a></li>
            <li><a href="https://spintop.network#products"><span class="svg-icon icons-chevron-right"></span>Products</a></li>
            <li><a href="https://spintop.network#roadmap"><span class="svg-icon icons-chevron-right"></span>Roadmap</a></li>
            <li><a href="https://spintop.network#team"><span class="svg-icon icons-chevron-right"></span>Team</a></li>
          </ul>
      </div>
      <div class="subs">
        <h5>
          <a href="https://spintop.network/gamepedia"> GAMEPEDIA<span class="svg-icon icons-chevron-down d-block d-md-none" /></a>
        </h5>
          <ul>
            <li><a href="https://spintop.network/gamepedia/games"><span class="svg-icon icons-chevron-right"></span>Browse</a></li>
            <li><a href="https://spintop.network/gamepedia/news"><span class="svg-icon icons-chevron-right"></span>News</a></li>
            <li><a href="https://spintop.network/gamepedia/academy"><span class="svg-icon icons-chevron-right"></span>Academy</a></li>
            <li><a href="https://spintop.network/gamepedia?q=watchlist"><span class="svg-icon icons-chevron-right"></span>Watchlist</a></li>
          </ul>
      </div>
      <div class="subs">
        <h5>
          <a href="https://staking.spintop.network">  STAKING<span class="svg-icon icons-chevron-down d-block d-md-none" /></a>
        </h5>
          <ul>
            <li><a href="https://staking.spintop.network/#/farms"><span class="svg-icon icons-chevron-right"></span>Farms</a></li>
            <li><a href="https://staking.spintop.network/#/pools"><span class="svg-icon icons-chevron-right"></span>Pools</a></li>
            <li><a href="https://staking.spintop.network/#/vault"><span class="svg-icon icons-chevron-right"></span>Vault</a></li>
            <!-- <li><a href="#"><span class="svg-icon icons-chevron-right"></span>Exchange</a></li>
            <li><a href="#"><span class="svg-icon icons-chevron-right"></span>Liquidity</a></li> -->
            <hr>
            <li><a href="https://pancakeswap.finance/swap?outputCurrency=0x6AA217312960A21aDbde1478DC8cBCf828110A67" target="_blank"><span class="svg-icon icons-chevron-right"></span>Buy Spin</a></li>
            <li><a href="https://www.certik.com/projects/spintop-network" target="_blank"><span class="svg-icon icons-chevron-right"></span>Audited by Certik</a></li>
          </ul>
      </div>
      <div class="subs">
        <h5>
          <a href="https://launchpad.spintop.network/">    LAUNCHPAD<span
            class="svg-icon icons-chevron-down d-block d-md-none"
          /></a>
        </h5>
          <ul>
            <li><a href="/"><span class="svg-icon icons-chevron-right"></span>IGO</a></li>
            <li><a href="/#/partnership" target="_blank"><span class="svg-icon icons-chevron-right"></span>Apply for IGO</a></li>
          </ul>
      </div>
    
      
  </div>
</footer>
<style>
  /* main {
    background-color: var(--background-level-2);
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
  }
  .footer {
    width: 1400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 52px;
    box-sizing: border-box;
  }
  .right,
  .left {
    display: flex;
    align-items: center;
    gap: 24px;
    color: var(--light-white);
  }
  .tag {
    display: flex;
    flex-direction: row;
    gap: 8px;
    background-color: var(--dark-primary);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 8px 16px;
    cursor: pointer;
    width: fit-content;
  }
  .price {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .audit {
    width: 100%;
    border-radius: 12px;
    font-size: 12px;
    background: transparent;
    border: 2px solid #630bf1;
    padding: 8px 16px;
  }
  i {
    color: var(--tertiary-primary);
  }
  @media only screen and (max-width: 860px) {
    main {
      padding-bottom: 84px;
    }
    .footer {
      justify-content: space-around;
      padding: 12px 32px;
    }
    .right {
      display: none;
    }
  }
  @media only screen and (max-width: 560px) {
    .link {
      display: none;
    }
  } */
  .d-flex {
	 display: flex;
}
 .solid {
	 font-size: 12px;
	 color: rgba(184, 197, 236, 0.35);
}
footer {
    background: #16092b;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 0px;
    position: relative;
  }
  /* footer::before{
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    background: linear-gradient(90deg, rgba(241,0,136,1) 0%, rgba(99,11,241,0) 100%);
    position: absolute;
    top: 0px;
    left: 0;
  } */
.container{
  margin: auto;
  max-width: 1150px !important;
}
 footer .btm {
	 margin-top: 4rem;
}
footer .container ul li a span{
  margin-top: -4px;
}
footer .container .d-flex{
  display: flex;
}
footer .container .justify-content-between{
  justify-content: space-between;
}
 footer .foot-card {
	 padding: 2rem;
	 width: calc(33% - 16px);
	 background: rgba(184, 197, 236, 0.1);
	 border: 1px solid rgba(184, 197, 236, 0.1);
	 border-radius: 1.5rem;
	 transition: 0.5s all;
}
 footer .foot-card .mobile {
	 display: none;
}
 footer .foot-card:hover {
	 background: rgba(241, 0, 136, 0.1);
	 border: 1px solid #f10088;
}
footer ul{
  padding-left: 0px;
}
footer ul li{
  list-style-type: none !important;
}
 footer .foot-card p {
	 font-size: 0.875rem;
   font-family: "Nexa-Regular", sans-serif;
	 color: rgba(255, 255, 255, 0.64);
}
 footer .foot-card p:first-child {
	 background: rgba(255, 255, 255, 0.16);
	 width: 3.5rem;
	 height: 3.5rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 border-radius: 50%;
}
 footer .foot-card p:first-child span {
	 width: 1.5rem;
	 height: 1.5rem;
	 color: white;
}
 footer .foot-card h5 {
  font-family: "Nexa-Bold", sans-serif;
	 margin-top: 1rem;
	 margin-bottom: 0.5rem;
	 color: white;
}
 footer .foot-card a.button {
  font-family: "Nexa-Bold", sans-serif;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 100%;
	 height: 3rem;
	 background: #630bf1;
	 margin-top: 1.5rem;
	 border-radius: 0.75rem;
	 color: white;
}
footer .foot-card button.button {
  font-family: "Nexa-Bold", sans-serif;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 100%;
	 height: 3rem;
   font-size:16px;
	 background: #630bf1;
	 margin-top: 1.5rem;
	 border-radius: 0.75rem;
	 color: white;
}
 footer .subs {
	 width: 100%;
	 max-width: 196px;
}
 footer .subs .logo {
	 width: 2.5rem;
}
 footer .subs h5 {
  font-family: "Nexa-Bold", sans-serif;
	 line-height: 24px;
	 font-size: 1rem;
   margin: 0px;
	 letter-spacing: 0.25rem;
	 line-height: 1.5rem;
	 color: rgba(184, 197, 236, 0.65);
   
}
footer .subs h5 .svg-icon{
  -webkit-mask-position: center;
}
 footer .subs ul hr {
	 border-color: rgba(184, 197, 236, 0.2);
}
 footer .subs ul li {
	 margin-top: 1rem;
}
 footer .subs ul li a {
  font-family: "Nexa-Regular", sans-serif;
	 font-size: 1rem;
	 display: flex;
	 align-items: center;
	 color: white;
}
 footer .subs ul li a span {
	 margin-right: 0.5rem;
	 transition: 0.5s ease all;
   -webkit-mask-position: center;
}
 footer .subs ul li a:hover span {
	 transform: translateX(5px);
	 background: #630bf1;
}
 .socials .svg-icon {
	 width: 1.1rem;
	 height: 1.1rem;
	 -webkit-mask-position: center;
	 mask-position: center;
}
 .socials a {
	 width: 48px;
	 height: 48px;
	 display: flex;
	 margin: 12px 10px 0 0;
	 justify-content: center;
	 align-items: center;
	 background: transparent;
	 border: 1px solid #630bf1;
	 box-sizing: border-box;
	 border-radius: 12px;
	 -webkit-mask-position: center;
}
 .socials a:hover {
	 background: #630bf1;
}
 @media only screen and (max-width: 860px) {
	 footer .foot-card {
		 width: 100%;
		 margin-top: 1.5rem;
		 text-align: center;
	}
	 footer .foot-card p:first-child {
		 display: none;
	}
	 footer .foot-card h5 {
		 font-size: 1.5rem;
     font-family: "Nexa-Bold", sans-serif;
	}
	 footer .foot-card .mobile {
		 display: block;
	}
	 footer .foot-card .socials {
		 flex-wrap: wrap;
		 padding: 0px 40px;
		 justify-content: center !important;
	}
	 footer .foot-card .dsk {
		 display: none;
	}
	 footer .btm {
		 margin-top: 0px;
	}
	 footer .subs {
		 max-width: 100% !important;
		 margin-top: 2rem;
	}
	 footer .subs h5 a{
		 width: 100%;
		 display: flex;
		 align-items: center;
		 justify-content: space-between;
     pointer-events: none;
	}
	 footer .subs h5 span {
		 transition: 0.2s ease;
	}
	 footer .subs .actived span {
		 transform: rotate(180deg);
	}
	 footer .subs ul {
		 display: none;
		 transition: 0.2s ease;
	}
}
 @media only screen and (max-width: 480px) {
	 .footer {
		 display: grid;
		 justify-items: center;
		 row-gap: 40px;
		 text-align: center;
		 padding: 24px 12px;
		 justify-content: center;
	}
	 .apply-button {
		 align-self: center;
		 width: 312px;
	}
	 .products {
		 flex-direction: column;
		 padding: 0;
	}
	 .product {
		 margin: 8px;
	}
	 .links {
		 gap: 12px;
		 margin: 2px 0;
	}
}
</style>
