<script>
  window.scroll({
    top: 0,
    left: 0,
  });

  let email;
  let projectName;
  let twitter;
  let telegram;
  let subject = { projectName: projectName };
  let message;
  let messageBody = {
    message: message,
    twitter: twitter,
    telegram: telegram,
  };
  let captchaCode;

  export const postEmail = async () => {
    await fetch("https://spintop.network/service/sendcontactrequest", {
      method: "POST",
      mode: "cors",
      body: `email=${email}&subject=${subject}&message=${
        message + " " + twitter + " " + telegram
      }&captcha=${captchaCode}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.text())
      .then((text) => console.log("Response: ", text));
  };
</script>

<svelte:head>
  <title>Launchpad - Partnership</title>
  <script
    src="https://www.google.com/recaptcha/enterprise.js?render=6LfexaMhAAAAAJpCp3d1bJ9mlnd60itZjg19zY_T"></script>
  <script>
grecaptcha.enterprise.ready(function() {
    grecaptcha.enterprise.execute('6LfexaMhAAAAAJpCp3d1bJ9mlnd60itZjg19zY_T', {action: 'login'}).then(function(token) {
       ...
    });
});
  </script>
</svelte:head>

<div class="flex-row partnership">
  <div class="partnership-image" />
  <div class="partnership-content">
    <form class="content flex-row">
      <div class="flex-col">
        <p style="font-size: 20px">Please fill in the required forms</p>
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="required">Project/Company Name</label>
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <input
          type="text"
          placeholder="Your project name"
          bind:value={projectName}
        />
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="required">Email Address</label>
        <input
          type="email"
          placeholder="Your email address"
          bind:value={email}
        />
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="required">Twitter</label>
        <input
          type="text"
          placeholder="Your Twitter page"
          bind:value={twitter}
        />
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="required">Telegram</label>
        <input
          type="text"
          placeholder="Your Telegram info"
          bind:value={telegram}
        />
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label>Your Message</label>
        <textarea
          id="message"
          name="message"
          rows="4"
          cols="50"
          placeholder="Write your message here."
          maxlength="250"
          bind:value={message}
        />
        <small>0/250</small>

        <button on:click={postEmail}>Apply for IGO</button>
      </div>
    </form>
    <p />
  </div>
</div>

<style>
  header {
    margin: 40px 0 0;
    font-size: 24px;
    color: white;
  }

  .partnership {
    margin: 48px 0 96px 0;
    height: 999px;
    background-color: #16092b;
    border-radius: 12px;
  }

  .partnership-content {
    background-color: #16092b;
    height: auto;
    width: 699px;
    border-radius: 12px;
  }

  .partnership-image {
    height: auto;
    width: 648px;
    background-image: url("/images/UI/partnership.png");
    border-radius: 12px 0 0 0;
  }

  .content {
    display: grid;
    grid-template-columns: 20% 80%;
    color: white;
    font-size: 14px;
  }

  form {
    height: 100%;
    width: 60%;
    align-content: center;
    margin: 0 0 0 134px;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 750px;
  }

  input {
    padding: 16px;
    width: 368px;
    height: 48px;
    background: #210d42;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    color: rgba(184, 197, 236, 0.35);
  }

  textarea {
    padding: 16px;
    width: 368px;
    height: 100%;
    background: #210d42;
    border-radius: 12px;
    border: none;
    font-size: 16px;
    resize: none;
    overflow: hidden;
    color: rgba(184, 197, 236, 0.35);
  }

  label {
    padding: 20px 0 10px 0;
    font-size: 12px;
  }

  .required:after {
    content: "  *";
    color: red;
  }

  small {
    padding: 12px 0;
    color: rgba(184, 197, 236, 0.35);
    font-size: 12px;
  }

  button {
    background: #f10088;
    border-radius: 12px;
    width: 140px;
    height: 48px;
    padding: 12px 17px;
    color: white;
  }

  .captcha {
    margin: 15px 0 !important;
  }

  @media only screen and (max-width: 860px) {
    .partnership-content {
      width: 100%;
    }

    .partnership-image {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    .partnership-image {
      display: none;
    }

    .content {
      margin: auto;
      display: contents;
      font-size: 14px;
    }

    .content .flex-col {
      width: 100%;
      height: 100%;
    }

    .content .flex-col p {
      margin: 32px 0 32px 32px;
    }

    input {
      width: 75%;
      align-self: center;
    }

    textarea {
      width: 75%;
      align-self: center;
    }

    label {
      margin: 0 0 0 32px;
    }

    small {
      margin: 8px 0 0 32px;
    }

    button {
      margin: 0 0 32px 32px;
    }

    .captcha {
      margin: 20px 30px !important;
      height: auto;
    }
  }
</style>
