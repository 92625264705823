<script>
  import MyIGOs from "../Components/IGOCards/MyIGOs.svelte";
  import UpcomingIGOs from "../Components/IGOCards/UpcomingIGOs.svelte";
  import VestingIGOs from "../Components/IGOCards/VestingIGOs.svelte";
  import CompletedIGOs from "../Components/IGOCards/CompletedIGOs.svelte";

  window.scroll({
    top: 0,
    left: 0,
  });

  export let params = {};

  let IGOType;

  switch (params.IGOType) {
    case "my-igos":
      IGOType = "My IGOs";
      break;
    case "upcoming-igos":
      IGOType = "Upcoming IGOs";
      break;
    case "vesting-progress-igos":
      IGOType = "Vesting in Progress";
      break;
    case "completed-igos":
      IGOType = "Completed IGOs";
      break;
  }
</script>

<main class="container" style="color:white;">
  <div class="flex-col view-all-cards">
    <header>{IGOType}</header>
    <div class="view-all-card">
      <div class="flex-row igo-cards">
        {#if params.IGOType === "my-igos"}
          <MyIGOs />
        {:else if params.IGOType === "upcoming-igos"}
          <UpcomingIGOs />
        {:else if params.IGOType === "vesting-progress-igos"}
          <VestingIGOs />
        {:else if params.IGOType === "completed-igos"}
          <CompletedIGOs />
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  main {
    display: flex;
  }

  .container {
    min-height: 800px;
  }

  .igo-cards {
    grid-template-columns: repeat(auto-fit, minmax(375px, 450px));
    max-width: calc(80% - 15px);
    grid-row-gap: 2rem;
  }

  .view-all-cards {
    margin-left: 65px;
  }

  .view-all-cards header {
    width: 1400px;
    height: 100%;
    margin: 64px 0 0;
    font-size: 24px;
  }

  .view-all-card {
    width: 100%;
    height: 100%;
    margin: 16px 0 64px;
  }

  @media only screen and (max-width: 860px) {
    main {
      width: 100%;
    }

    .flex-col {
      margin-left: 0;
    }

    .view-all-cards header {
      width: 100%;
    }

    .igo-cards {
      grid-template-columns: repeat(auto-fit, minmax(345px, 375px));
      grid-column-gap: 1rem;
    }
  }

  @media only screen and (max-width: 480px) {
    main {
      width: 100%;
      padding: 0;
    }

    .flex-col {
      margin-left: 0;
    }

    .view-all-cards header {
      width: 100%;
    }

    .igo-cards {
      grid-template-columns: repeat(auto-fit, minmax(345px, 450px));
      max-width: calc(100%);
    }
  }
</style>
